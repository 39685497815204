import * as Constants from "./constants.js";
import * as objectConfigs from "../api/objectApi.js";
import FieldConfig from "../core/FieldConfig.js";
import ObjectConfig from "../core/ObjectConfig.js";
import * as CustomLogic from "../api/customLogic/customLogic.js";
import * as Utilities from "../api/utilities";
// import testData from "../testData/testObjects.js";
import Vue from "vue";

/**
 * Mutators
 */
export const SET_OBJECT_CONFIGS = "setObjectConfigs";
export const SET_OBJECT_CONFIG = "setObjectConfig";
export const SET_FIELD_CONFIG = "setFieldConfig";
export const UPSERT_OBJECT_CONFIG = "upsertObjectConfig";
export const UPSERT_FIELD_CONFIG = "upsertFieldConfig";
export const SET_OBJECT_CONFIG_STATE = "setObjectConfigState";
export const SET_FIELDS = "setFields";
export const _SET_DATA_OBJECTS = "setDataObjects";

/**
 * Actions
 */
export const _FIND_DATA_OBJECTS_OF_OBJECT = "_findAllDataObjectsOfObject";

/**
 * objectConfig State
 */
export const OBJECT_CONF_STATE_INIT = 0;
export const OBJECT_CONF_STATE_START = 1;
export const OBJECT_CONF_STATE_FIELDS_TABLE = 2;
export const OBJECT_CONF_STATE_OBJECTS_TABLE = 3;
export const OBJECT_CONF_STATE_TEST_FIELDS = 4;
export const OBJECT_CONF_STATE_TEST_OBJECTS = 5;
export const OBJECT_CONF_STATE_FIELDS = 6;
export const OBJECT_CONF_STATE_OBJECTS = 7;

export default {
  state: {
    objectConfigs: [],
    fieldConfig: {},
    objectConfig: {},
    objectConfigState: OBJECT_CONF_STATE_INIT,
    dataObjects: {},
    dataObjectsVersions: {},
    fields: null,
  },
  mutations: {
    /** initObjectConfigs */
    setObjectConfig(state, objectConfig) {
      state.objectConfig = objectConfig;
    },
    setFieldConfig(state, fieldConfig) {
      state.fieldConfig = fieldConfig;
    },
    /***********************************************
     * Upserts
     **********************************************/
    /**
     * @param {*} payload: object + objectConfig
     */
    setObject(state, { payload, getters }) {
      //If Array does not exist it will be the first object in the store.
      if (state.dataObjects[payload.objectConfig.objectType] === undefined) {
        Vue.set(state.dataObjects, payload.objectConfig.objectType, [
          payload.object,
        ]);
        return;
      }

      //Get the old object in the store to replace.
      var oldObjectInfo = getters.getOldObjectForObject(payload);
      var index = 0;
      if (oldObjectInfo.index >= 0) {
        index = oldObjectInfo.index;
      } else {
        index = state.dataObjects[payload.objectConfig.objectType].length;
      }

      //Replace of add the object in the store.
      var objects = getters.getDataObjectsForObjectType(
        payload.objectConfig.objectType,
      );
      Vue.set(
        state.dataObjects[payload.objectConfig.objectType],
        index,
        payload.object,
      );

      if (
        !Utilities.isTrue(payload.noCache) &&
        getters.getIsCacheActive(payload.objectConfig.objectType)
      ) {
        this.commit("setObjectInCache", { payload, getters });
      }
    },
    /**
     * Upsert Special Case of ObjectConfig
     * This is a build object.
     * @param {*} state
     * @param {*} objectConfig
     */
    upsertObjectConfig(state, payload) {
      var oldObjectInfo = payload.objectConfig.getOldObjectAndIndexInList(
        state.objectConfigs,
        payload.object,
      );
      var index = 0;
      if (oldObjectInfo.index >= 0) {
        index = oldObjectInfo.index;
      } else {
        index = state.objectConfigs.length;
      }

      Vue.set(state.objectConfigs, index, payload.object);
    },
    /**
     * Upsert Special Case of fields
     * @param {*} state
     * @param {*} fieldConfig
     */
    upsertFieldConfig(state, payload) {
      var oldObjectInfo = payload.objectConfig.getOldObjectAndIndexInList(
        state.fields,
        payload.object,
      );
      var index = 0;
      if (oldObjectInfo.index >= 0) {
        index = oldObjectInfo.index;
      } else {
        index = state.fields.length;
      }
      Vue.set(state.fields, index, payload.object);
    },
    /**
     * Set the objects replace everything.
     * @param {*} state
     * @param {*} objectConfigs
     */
    setObjectConfigs(state, objectConfigs) {
      state.objectConfigs = objectConfigs;
    },

    setFields(state, fields) {
      state.fields = fields;
    },
    setDataObjects(state, payload) {
      var objects = payload.objects;
      objects = CustomLogic.filterOrSortDataObjects(payload);
      var objectConfig = payload.objectConfig;
      Vue.set(state.dataObjects, objectConfig.objectType, objects);
    },
    clearDataObjects(state, objectType) {
      if (objectType) {
        Vue.set(state.dataObjects, objectType, []);
      }
    },
    /**
     * Set the state
     * @param {*} state
     * @param {*} newState
     */
    setObjectConfigState(state, newState) {
      state.objectConfigState = newState;
    },
  },
  getters: {
    getObjectConfigs(state) {
      return state.objectConfigs;
    },
    getObjectConfig(state) {
      return state.objectConfig;
    },
    getFieldConfig(state) {
      return state.fieldConfig;
    },
    getFields(state) {
      return state.fields;
    },
    getObjectConfigForType: (state, getters, rootState, arg) => (type) => {
      if (type == Constants.OBJECT_CONFIG) {
        return getters.getObjectConfig;
      }
      if (type == Constants.FIELD_CONFIG) {
        return getters.getFieldConfig;
      }
      return state.objectConfigs.find(function (o) {
        return o.objectType === type;
      });
    },
    getValueForIdAndObjectTypeAndField:
      (state, getters) => (objectType, Guid, field) => {
        if (Guid && objectType && field) {
          const data = getters.getDataObjectsForObjectType(objectType);
          const object = data.find((item) => item.Guid === Guid);
          if (object) {
            return object[field];
          }
        }
        return null;
      },
    getDataObjectForIdAndObjectType: (state, getters) => (payload) => {
      var objectType = payload.objectType;
      var id = payload.objectId;
      var objectConfig = getters.getObjectConfigForType(objectType);
      var list = getters.getDataObjectsForObjectType(objectType);
      var object = {};
      if (!Utilities.isEmpty(objectConfig)) {
        object = objectConfig.getObjectForIdInList(list, id);
      }
      return object;
    },
    getAllDataObjectsForObjectType: (state, getters) => (objectType) => {
      if (state.dataObjects[objectType] === undefined) {
        return [];
      }
      return state.dataObjects[objectType];
    },

    getDataObjectsForObjectType: (state, getters) => (objectType) => {
      if (state.dataObjects[objectType] === undefined) {
        return [];
      }
      return state.dataObjects[objectType].filter(function (o) {
        return o.isDeleted !== 1;
      });
    },
    getObjectsForFilter: (state, getters) => (objectType, filter) => {
      const data = getters.getDataObjectsForObjectType(objectType);
      const filterData = data.filter(filter);
      return filterData;
    },
    getOldObjectForObject: (state, getters, rootState, arg) => (payload) => {
      var objects = state.dataObjects[payload.objectConfig.objectType];
      // var objects = getters.getDataObjectsForObjectType(
      //   payload.objectConfig.objectType
      // );
      return payload.objectConfig.getOldObjectAndIndexInList(
        objects,
        payload.object,
      );
      //Then we need to find the old object with the key.
    },
    getReadableObjects: (state, getters) => (objectType, objects, layer) => {
      var readableObjects = [];
      const filter = (item) => {
        return item.objectType === objectType;
      };
      var fields = getters.getObjectsForFilter(Constants.FIELD_CONFIG, filter);

      var lists = getters.buildListsForFields(fields);
      if (
        typeof objects === "object" &&
        !Array.isArray(objects) &&
        !Utilities.isEmpty(objects)
      ) {
        return getters.getReadableObjectForConfig(
          fields,
          objects,
          lists,
          layer,
        );
      }
      for (var i = 0; i < objects.length; i++) {
        var object = objects[i];
        var readableObject = getters.getReadableObjectForConfig(
          fields,
          object,
          lists,
          layer,
        );
        readableObjects.push(readableObject);
      }
      return readableObjects;
    },
    getReadableObjectForConfig:
      (state, getters) => (fields, object, lists, layer) => {
        var readableObject = {};
        for (var i = 0; i < fields.length; i++) {
          var field = fields[i];
          if (
            field.backendField === "isDeleted" ||
            field.backendField === "Guid" ||
            field.backendField === "_id" ||
            field.backendField === "ParentGuid" ||
            field.backendField === "ParentType"
          ) {
            continue;
          }
          if (!Utilities.isEmpty(field.dropdown)) {
            var value = getters.getValueForList(
              object[field.backendField],
              field.dropdown,
              lists,
            );
            if (!Utilities.isEmpty(value)) {
              readableObject[field.backendField] = value;
              continue;
            }
          }
          if (!Utilities.isEmpty(field.relatedObjectType)) {
            const value = getters.getRelatedObjectValue(object, field, layer);
            readableObject[field.backendField] = Utilities.isEmpty(value)
              ? object[field.backendField]
              : value;
            continue;
          }
          if (!Utilities.isEmpty(object[field.backendField])) {
            readableObject[field.backendField] = object[field.backendField];
          }
        }
        return readableObject;
      },
    buildListsForFields: (state, getters) => (fields) => {
      var valueListFields = fields.filter((o) => {
        return !Utilities.isEmpty(o.dropdown);
      });
      var lists = {};
      for (var i = 0; i < valueListFields.length; i++) {
        var valueListField = valueListFields[i];
        if (Utilities.isEmpty(lists[valueListField.dropdown])) {
          lists[valueListField.dropdown] = getters.getValueListForDropdown(
            valueListField.dropdown,
          );
        }
      }
      return lists;
    },
    getValueListForDropdown: (state, getters) => (dropdown) => {
      const filter = (item) => item.valueList === dropdown;
      return getters.getObjectsForFilter(Constants.VALUE_LIST_CONFIG, filter);
    },
    getRelatedObjectValue: (state, getters) => (object, field, layer) => {
      if (
        !Utilities.isEmpty(object[field.backendField]) &&
        !Utilities.isEmpty(field.relatedObjectType)
      ) {
        var relatedObject = getters.getDataObjectForIdAndObjectType({
          objectId: object[field.backendField],
          objectType: field.relatedObjectType,
        });

        if (!Utilities.isEmpty(relatedObject)) {
          if (Utilities.isEmpty(layer)) {
            return getters.getReadableObjects(
              field.relatedObjectType,
              relatedObject,
              true,
            );
          } else if (!Utilities.isEmpty(relatedObject.FullName)) {
            return relatedObject.FullName;
          } else if (!Utilities.isEmpty(relatedObject.Subject)) {
            return relatedObject.Subject;
          } else return relatedObject;
        }
      }
      return object[field.backendField];
    },
    getRelatedObjectForViewField: (state, getters) => (payload) => {
      const { value, viewfield } = payload;
      if (value && viewfield) {
        if (viewfield.relatedObjectType) {
          const filteredObjects = getters.getDataObjectsForObjectType(
            viewfield.relatedObjectType,
          );
          if (filteredObjects) {
            const object = filteredObjects.find(
              (item) => item[viewfield.valueRelatedObject || "Guid"] === value,
            );
            if (object) {
              return object;
            }
          }
        }
      }
      return null;
    },
    getRelatedDisplayForViewField: (state, getters) => (payload) => {
      const { viewfield } = payload;
      if (viewfield && viewfield.displayRelatedObject) {
        const item = getters.getRelatedObjectForViewField(payload);
        if (item) {
          return item[viewfield.displayRelatedObject];
        }
      }
      return null;
    },
    getValueForList: (state, getters) => (value, valueList, lists) => {
      var list = lists[valueList];
      if (!Utilities.isEmpty(list)) {
        var readableValue = list.find((o) => {
          return o.value === value;
        });
        if (!Utilities.isEmpty(readableValue)) {
          return readableValue.descr;
        }
      }
      return null;
    },
    getValueListValue: (state, getters) => (valueList, value) => {
      if (valueList && value) {
        const filter = (item) => {
          return item.valueList === valueList && item.value === value;
        };
        const items = getters.getObjectsForFilter(
          Constants.VALUE_LIST_CONFIG,
          filter,
        );
        if (items && items[0]) {
          return items[0].descr;
        }
      }
      return value;
    },
    isSubscribed(state, getters, rootState, arg) {
      return getters.getObjectConfigs.length > 8;
    },
  },
  actions: {
    /**
     * Main method to init the application for the database fields
     * @param {} param0
     */
    async initObjectContext({ dispatch, commit, getters }) {
      await dispatch(Constants.initObjectConfigs);
      await dispatch(Constants.CREATE_CORE_TABLES);
      commit("setAppLoginStatus", 3);
      await dispatch(Constants.INIT_CORE); // Should come from the server.
      commit("setAppLoginStatus", 4);
      // Object tables are created.
      await dispatch(Constants.FIND_ALL_FIELDS);
      commit("setAppLoginStatus", 5);
      await dispatch(Constants.FIND_ALL_OBJECTS);
      commit("setAppLoginStatus", 6);
      await dispatch(Constants.CREATE_DATA_TABLES);
      commit("setAppLoginStatus", 7);
      await dispatch(Constants.FIND_ALL_DATA_OBJECTS);
      commit("setAppLoginStatus", 8);
      await dispatch("setAllObjectsInFastCache");
      commit("setAppLoginStatus", 9);

      var isSubscribed = getters.isSubscribed;
      if (!isSubscribed) {
        console.log("Is not subscribed - Start synchronize!");
        await dispatch("synchronizeAllDataInitial");
      } else {
        console.log("Is subscribed!");
        //Here we should load the data.
      }

      commit("setAppLoginStatus", 90);
    },

    async deleteObjectContext({ dispatch, commit, getters }) {
      await dispatch(Constants.initObjectConfigs);
      await dispatch(Constants.FIND_ALL_FIELDS);
      await dispatch(Constants.FIND_ALL_OBJECTS);
      await dispatch(Constants.DELETE_DATA_TABLES);
      await dispatch(Constants.DELETE_CORE_TABLES);
    },

    /**
     * This action will retrieve the core object configs:
     * FieldObjectConfig and ObjectConfig
     * @param {} param0 { dispatch, commit, getters }
     */
    initObjectConfigs({ dispatch, commit, getters }) {
      commit(SET_OBJECT_CONFIG_STATE, OBJECT_CONF_STATE_START);
      commit(SET_OBJECT_CONFIG, new ObjectConfig());
      commit(SET_FIELD_CONFIG, new FieldConfig());
    },

    /**
     * Find with sync ID
     * @param {F} param0
     */
    async findHistoryWithSyncID({ dispatch, commit, getters }, payload) {
      var objectConfig = payload.objectConfig;
      var syncId = payload.syncId;
      if (objectConfig === null || objectConfig === undefined) {
        return [];
      }
      await objectConfig
        .findObjectHistoryForSyncId(getters.getDatabase, syncId)
        .then(
          (results) => {
            if (results.length > 0) {
              payload.result = results[0];
            }
            return;
          },
          (error) => {
            console.log("Error: ", error);
            return error;
          },
        );
    },
    /**
     * Find all objects
     * At the beginning we will load everything from the store. Afterwards the synchronisation maintains everything.
     */

    /**
     * Find all the fields and load and store them temporary to build the objects
     * @param {} param0
     */
    async findAllFields({ dispatch, commit, getters }) {
      await getters.getFieldConfig.findAll(getters.getDatabase).then(
        (results) => {
          results = results.filter(function (o) {
            return o.isDeleted !== 1;
          });
          commit(SET_FIELDS, results);
          commit(SET_OBJECT_CONFIG_STATE, OBJECT_CONF_STATE_FIELDS);
        },
        (error) => {
          commit(SET_OBJECT_CONFIG_STATE, OBJECT_CONF_STATE_FIELDS * -1);
          console.log("Error: ", error);
        },
      );
    },

    async getOnlineObjectsForFilter({ dispatch, commit, getters }, payload) {},
    /**
     * FindAll objects from database and build them with the fields
     * @param {} param0
     */
    async findAllObjects({ dispatch, commit, getters }) {
      var fields = getters.getFields;
      await getters.getObjectConfig.findAll(getters.getDatabase).then(
        (results) => {
          results = results.filter(function (o) {
            return o.isDeleted !== 1;
          });
          var objects = objectConfigs.buildObjects(results, fields);
          commit(SET_OBJECT_CONFIGS, objects);
          commit(SET_OBJECT_CONFIG_STATE, OBJECT_CONF_STATE_OBJECTS * -1);
        },
        (error) => {
          commit(SET_OBJECT_CONFIG_STATE, OBJECT_CONF_STATE_OBJECTS * -1);
          console.log("Error: ", error);
        },
      );
    },
    /**
     * FindAll objects from database and build them with the fields
     * @param {} param0
     */
    async findAllDataObjects({ dispatch, commit, getters }) {
      var allLoadPromises = [];
      var allLoadPromisesAsync = [];
      const start = Date.now();
      var objectConfigs = getters.getObjectConfigs;
      var restObjectConfigs = objectConfigs.filter((o) => {
        return o.objectConfig.syncGroup === Constants.SYNC_GROUP_CORE_REST;
      });
      var othersConfigs = objectConfigs.filter((o) => {
        return o.objectConfig.syncGroup !== Constants.SYNC_GROUP_CORE_REST;
      });

      for (var i = 0; i < restObjectConfigs.length; i++) {
        var objectConfig = restObjectConfigs[i];
        allLoadPromises.push(
          dispatch(_FIND_DATA_OBJECTS_OF_OBJECT, objectConfig),
        );
      }

      let promise = await Promise.all(allLoadPromises);

      for (var i = 0; i < othersConfigs.length; i++) {
        var objectConfigOther = othersConfigs[i];

        allLoadPromisesAsync.push(
          dispatch(_FIND_DATA_OBJECTS_OF_OBJECT, objectConfigOther),
        );
      }
      //let promiseAsync = Promise.all(allLoadPromisesAsync);
    },
    /**
     * FindAll objects for the given object from database and build them with the fields
     * @param {} param0
     */
    async _findAllDataObjectsOfObject(
      { dispatch, commit, getters },
      objectConfig,
    ) {
      await objectConfig.findAll(getters.getDatabase).then(
        (results) => {
          // var objects = objectConfig.buildObjects(results, fields);
          // console.log("Objects found", objects);
          var payload = {
            objects: results,
            objectConfig: objectConfig,
          };
          commit(_SET_DATA_OBJECTS, payload);
        },
        (error) => {
          console.log("Error: ", error);
        },
      );
    },

    renewObjectConfigs({ dispatch, commit, getters }) {
      var objects = getters.getDataObjectsForObjectType(
        Constants.OBJECT_CONFIG,
      );
      var objectConfig = getters.getObjectConfigForType(
        Constants.OBJECT_CONFIG,
      );
      dispatch("_updateObjectStore", {
        objects: objects,
        objectConfig: objectConfig,
      });
    },
    /**
     * Update the objects in the store.
     * With delta we must upsert or delete.
     * Can be action.
     */
    updateDataStore({ dispatch, commit, getters }, payload) {
      var objects = payload.objects;
      var objectConfig = payload.objectConfig;

      // Special case for FieldConfigs and object Configs
      if (objectConfig.objectType == Constants.OBJECT_CONFIG) {
        dispatch("_updateObjectStore", payload);
      }
      if (objectConfig.objectType == Constants.FIELD_CONFIG) {
        dispatch("_updateFieldStore", payload);
      }
      // Still put in the rest of the object Configs.
      objects.forEach((object) => {
        var payloadSet = {
          object: object,
          objectConfig: objectConfig,
          noCache: payload.noCache || false,
        };
        var payloadGetters = {
          getters: getters,
          payload: payloadSet,
        };
        commit(Constants.SET_OBJECT, payloadGetters);
      });
    },
    _updateFieldStore({ dispatch, commit, getters }, payload) {
      var objects = payload.objects;
      var objectConfig = payload.objectConfig;
      objects.forEach((object) => {
        var payloadSet = {
          object: object,
          objectConfig: objectConfig,
        };
        commit(UPSERT_FIELD_CONFIG, payloadSet);
      });
    },
    _updateObjectStore({ dispatch, commit, getters }, payload) {
      console.log("Update _updateObjectStore");
      var fields = getters.getFields;
      var objects = objectConfigs.buildObjects(payload.objects, fields);
      var objectConfig = payload.objectConfig;
      objects.forEach((object) => {
        var payloadSet = {
          object: object,
          objectConfig: objectConfig,
        };
        commit(UPSERT_OBJECT_CONFIG, payloadSet);
      });
    },
  },
};
