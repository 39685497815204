<template>
    <v-card
        flat
        class="mx-0 kingSize full-height"
        color="transparent"
    >
        <div v-if="header.type === 'image'">
            <v-card
                :class="getImageClass"
                flat
                color="transparent"
            >
                <v-avatar
                    class="elevation-2"
                    style="width: 100%; height:100%; aspect-ratio:1; border-radius:10px;"
                    @click="openImagePreviewModel"
                >

                    <img
                        v-if="!getImageType.includes('image/')"
                        :src="getTypeIcon(getImageType)"
                        alt="pdf-icon"
                        class="pa-2"
                    />
                    <img
                        v-else-if="getImage(header, object)"
                        :src="getFullImage(header, object)"
                        crossorigin="anonymous"
                        class="avatarImg"
                        style="align:center; object-fit: cover;"
                        alt="Avatar"
                    >
                    <!-- <img
            v-else
            :src="JpgIcon"
            class="pa-2"
            alt="jpg icon"
          > -->
                </v-avatar>
            </v-card>
            <v-dialog
                v-model="imagePreviewModal"
                content-class="rounded-lg"
            >
                <v-card
                    v-if="getImageType === 'application/pdf'"
                    style="height:90vh;"
                >
                    <div class="iframe-loader">
                        <v-progress-circular
                            indeterminate
                            color="primary"
                        />
                    </div>
                    <iframe
                        v-if="pdfData"
                        :src="`${pdfData}`"
                        height="100%"
                        width="100%"
                        style="border:none; position: relative; z-index: 1;"
                    />
                </v-card>
                <v-card
                    v-else
                    flat
                    color="black"
                    class="d-flex"
                    style="height:90vh;"
                >
                    <img
                        :src="getFullImage(header, object) ? getFullImage(header, object) : getImage(header, object)"
                        crossorigin="anonymous"
                        style="max-height:90vh; width: 100%; object-fit: contain;"
                    >

                </v-card>
            </v-dialog>
        </div>
        <div v-if="header.type === 'slider'">
            <v-card
                color="card"
                rounded="lg"
                flat
            >
                <v-row
                    v-if="isNotList"
                    class="pa-0 rounded-lg slider"
                    no-gutters
                >
                    <v-col
                        class="py-2 px-3"
                        cols="12"
                    >
                        <v-label class="text-caption">
                            {{ getLabel(header) }}
                        </v-label>
                    </v-col>
                    <v-col
                        class="py-0 px-3"
                        cols="12"
                    >
                        <div class="text-h6 font-weight-bold text-field-transparent">
                            <v-text-field
                                v-if="!isReadOnly"
                                v-model="currentValue"
                                :label="getLabel(header)"
                                :class="getClass(header)"
                                :clearable="false"
                                :suffix="appendText"
                                dense
                                solo
                                hide-details
                                @change="change"
                            />
                            <div v-if="isReadOnly">
                                <span>{{ prependText }}</span>{{ currentValue }} <span class="text-caption">{{
            appendText
        }}</span>
                            </div>
                        </div>
                    </v-col>
                    <v-col
                        class="py-2 px-0"
                        cols="12"
                    >
                        <v-slider
                            v-if="isReadOnly"
                            v-model="currentValue"
                            :min="currentValue < header.min ? currentValue : header.min"
                            :max="currentValue > header.max ? currentValue : header.max"
                            :step="step || getStep(header.min, header.max)"
                            color="primary"
                            track-color="blue-grey lighten-5"
                            @change="change"
                        />
                    </v-col>
                </v-row>
            </v-card>
            <div v-if="!isNotList">
                <v-text-field
                    v-model="currentValue"
                    :label="getLabel(header)"
                    :readonly="isReadOnly"
                    :class="getClass(header)"
                    :dense="dense"
                    :filled="filled"
                    :solo="solo"
                    :hide-details="hidedetails"
                    :min="currentObject[header.min]"
                    :max="currentObject[header.max]"
                    :clearable="false"
                    @keyup.enter="onFocus(header, currentObject)"
                    @keyup.tab="onFocus(header, currentObject)"
                    @click.native="clickField(header, currentObject)"
                    @change="change"
                />
            </div>
        </div>
        <div v-if="header.type === 'multipleInput'">
            <v-text-field
                :value="currentValue"
                :label="getLabel(header)"
                :readonly="true"
                :dense="dense"
                :solo="solo"
                :class="getClass(header)"
                :hide-details="hidedetails"
                filled
                @change="change"
            />
            <v-btn
                icon
                @click.native="openDialog(header, currentObject)"
            >
                <v-icon>add</v-icon>
            </v-btn>
        </div>
        <div v-if="header.type === 'phone'">
            <v-text-field
                ref="inputRef"
                v-model="currentValue"
                :label="getLabel(header)"
                :readonly="isReadOnly"
                :class="`${getClass(header)} ${isPhone(currentValue) && 'pointer'}`"
                :dense="dense"
                :filled="filled"
                :solo="solo"
                :hide-details="hidedetails"
                :clearable="false"
                :autofocus="isAutofocus"
                class="allow-append"
                :prepend-inner-icon="isReadOnly ? 'hi-phone-outline' : null"
                @keyup.enter="onFocus(header, currentObject)"
                @keyup.tab="onFocus(header, currentObject)"
                @click.native="isReadOnly && isPhone(currentValue) ? call(header, currentObject) : clickField(header, currentObject)"
                @change="change"
                @dblclick="onDoubleClick"
            />

        </div>
        <div v-if="header.type === 'email'">
            <v-text-field
                ref="inputRef"
                v-model="currentValue"
                :label="getLabel(header)"
                :readonly="isReadOnly"
                :class="`${getClass(header)} ${isEmail(currentValue) && 'pointer'}`"
                :dense="dense"
                :filled="filled"
                :solo="solo"
                :hide-details="hidedetails"
                :clearable="false"
                :autofocus="isAutofocus"
                class="allow-append"
                :prepend-inner-icon="isReadOnly ? 'hi-envelope-outline' : null"
                @keyup.enter="onFocus(header, currentObject)"
                @keyup.tab="onFocus(header, currentObject)"
                @click.native="isReadOnly && isEmail(currentValue) ? email(header, currentObject) : clickField(header, currentObject)"
                @change="change"
                @dblclick="onDoubleClick"
            />

        </div>
        <div v-if="header.type === 'location'">
            <v-text-field
                ref="inputRef"
                v-model="currentValue"
                :label="getLabel(header)"
                :readonly="isReadOnly"
                :class="`${getClass(header)} ${!isEmpty(currentValue) && 'pointer'}`"
                :dense="dense"
                :filled="filled"
                :solo="solo"
                :hide-details="hidedetails"
                :clearable="false"
                :autofocus="isAutofocus"
                class="allow-append"
                prepend-inner-icon="hi-map-outline"
                @keyup.enter="onFocus(header, currentObject)"
                @keyup.tab="onFocus(header, currentObject)"
                @click.native="isReadOnly && !isEmpty(currentValue) ? goToMap(header, currentObject) : clickField(header, currentObject)"
                :change="change"
                @dblclick="onDoubleClick"
            />

        </div>
        <div v-if="header.type === 'url'">
            <v-text-field
                ref="inputRef"
                v-model="currentValue"
                :label="getLabel(header)"
                :readonly="isReadOnly"
                :class="`${getClass(header)} ${!isEmpty(currentValue) && 'pointer'}`"
                :dense="dense"
                :filled="filled"
                :solo="solo"
                :hide-details="hidedetails"
                :clearable="false"
                :autofocus="isAutofocus"
                class="allow-append"
                prepend-inner-icon="hi-link-outline"
                @keyup.enter="onFocus(header, currentObject)"
                @keyup.tab="onFocus(header, currentObject)"
                @click.native="isReadOnly && !isEmpty(currentValue) ? goToUrl() : clickField(header, currentObject)"
                @change="change"
                @dblclick="onDoubleClick"
            />

        </div>


        <div
            v-if="header.type === 'amount'"
            no-gutters
            align="center"
            justify="center"
            class="py-0 px-0 d-flex flex-row align-center full-height"
            style="flex: 0; position: relative; width: 100%; min-width: 120px;"
        >
            <v-btn
                v-if="!isReadOnly"
                icon
                class="iconButton mx-1"
                style="position: relative; z-index: 1;top: 2px;"
                @click.native="add(header.value, -1)"
            >
                <v-icon small>hi-minus</v-icon>
            </v-btn>
            <v-spacer />
            <div :class="isReadOnly ? 'amountWrap-readonly' : 'amountWrap'">
                <v-text-field
                    ref="amountRef"
                    v-model="currentValue"
                    :label="getLabel(header)"
                    :readonly="isReadOnly"
                    :class="getClass(header)"
                    :dense="dense"
                    :filled="filled"
                    :solo="solo"
                    :hide-details="hidedetails"
                    :clearable="false"
                    type="number"
                    @input="change"
                />
            </div>
            <v-slider
                v-show="false"
                v-model="currentValue"
                :min="getMin()"
                :max="getMax()"
                :step="1"
                color="primary"
                track-color="blue-grey lighten-5"
            />
            <v-btn
                v-if="!isReadOnly"
                icon
                class="iconButton mx-1"
                style="position: relative; z-index: 1;top: 2px;"
                @click="add(header.value, 1)"
            >
                <v-icon small>hi-plus</v-icon>
            </v-btn>


        </div>
        <div v-show="header.type === 'chip'">
            <v-container class="pa-0">
                <v-text-field
                    v-if="!isReadOnly"
                    ref="inputRef"
                    v-model="currentValue"
                    :label="getLabel(header)"
                    :readonly="isReadOnly"
                    :class="getClass(header)"
                    :dense="dense"
                    :filled="filled"
                    :solo="solo"
                    :hide-details="hidedetails"
                    :clearable="false"
                    :autofocus="isAutofocus"
                    @keyup.enter="onFocus(header, currentObject)"
                    @keyup.tab="onFocus(header, currentObject)"
                    @click.native="clickField(header, currentObject)"
                    @change="change"
                />
                <v-chip
                    v-if="isReadOnly"
                    :class="getChipClass(header, object)"
                    :small="!isNotList"
                    style="float:left;"
                    @dblclick="onDoubleClick"
                >
                    {{ currentValue }}
                </v-chip>
            </v-container>
        </div>
        <div v-show="header.type === 'chipList'">
            <v-container class="pa-0">
                <v-select
                    v-if="isNotList || !isReadOnly"
                    ref="inputRef"
                    :items="getValueListItems(header.dropdown)"
                    v-model="currentValue"
                    :label="getLabel(header)"
                    :readonly="isReadOnly"
                    :class="getClass(header)"
                    :dense="dense"
                    :filled="filled"
                    :solo="solo"
                    :hide-details="hidedetails"
                    :clearable="false"
                    :menu-props="{ bottom: true, offsetY: true }"
                    item-text="descr"
                    item-value="value"
                    @keyup.enter="onFocus(header, currentObject)"
                    @ended="onEnded(header, currentObject)"
                    @change="change"
                    @dblclick.native="onDoubleClick"
                >
                    <template v-slot:selection="{ item }">
                        <v-chip
                            :class="getChipClass(header, object, getValueListItems(header.dropdown))"
                            small
                            style="float:left;"
                            @dblclick="onDoubleClick"
                        >
                            {{ item.descr }}
                        </v-chip>
                    </template>
                </v-select>
                <v-chip
                    v-if="(!isNotList && isReadOnly)"
                    :class="getChipClass(header, object, getValueListItems(header.dropdown))"
                    :small="!isNotList"
                    style="float:left;"
                    @dblclick="onDoubleClick"
                >
                    {{ getValueFromValueList(header.dropdown, currentValue) }}
                </v-chip>
            </v-container>
        </div>
        <div v-show="header.type === 'input'">
            <v-text-field
                ref="inputRef"
                v-model="currentValue"
                :label="getLabel(header)"
                :readonly="isReadOnly"
                :class="getClass(header)"
                :dense="dense"
                :filled="filled"
                :solo="solo"
                :hide-details="hidedetails"
                :clearable="false"
                :autofocus="isAutofocus"
                @keyup.enter="onFocus(header, currentObject)"
                @keyup.tab="onFocus(header, currentObject)"
                @click.native="clickField(header, currentObject)"
                @change="change"
                @dblclick="onDoubleClick"
            />
        </div>
        <div v-show="header.type === 'currency'">
            <v-text-field
                v-show="isReadOnly"
                ref="inputRef"
                :value="formatNumber(currentValue)"
                :label="getLabel(header)"
                :readonly="isReadOnly"
                :class="getClass(header)"
                :dense="dense"
                :filled="filled"
                :solo="solo"
                :hide-details="hidedetails"
                :clearable="false"
                @keyup.enter="onFocus(header, currentObject)"
                @keyup.tab="onFocus(header, currentObject)"
                @click.native="clickField(header, currentObject)"
                @change="change"
                @dblclick="onDoubleClick"
            />
            <v-text-field
                v-show="!isReadOnly"
                ref="inputRef"
                v-model="currentValue"
                :label="getLabel(header)"
                :readonly="isReadOnly"
                :class="getClass(header)"
                :dense="dense"
                :filled="filled"
                :solo="solo"
                :hide-details="hidedetails"
                :clearable="false"
                @keyup.enter="onFocus(header, currentObject)"
                @keyup.tab="onFocus(header, currentObject)"
                @click.native="clickField(header, currentObject)"
                @change="change"
            />
        </div>
        <div
            v-if="getTypeOfField(header) === 'idlLink'"
            style="min-width: 140px;"
        >
            <v-text-field
                ref="inputRef"
                v-model="currentValue"
                :label="getLabel(header)"
                :readonly="isReadOnly"
                :class="getClass(header)"
                :dense="dense"
                :filled="filled"
                :solo="solo"
                :hide-details="hidedetails"
                :clearable="false"
                @keyup.enter="onFocus(header, currentObject)"
                @keyup.tab="onFocus(header, currentObject)"
                @click.native="clickField(header, currentObject)"
                @change="change"
                @dblclick="onDoubleClick"
            />
        </div>
        <div v-if="getTypeOfField(header) === 'jsonEditor'">
            <v-text-field
                v-if="isReadOnly"
                ref="inputRef"
                v-model="currentValue"
                :label="getLabel(header)"
                :readonly="isReadOnly"
                :class="getClass(header)"
                :dense="dense"
                :filled="filled"
                :solo="solo"
                :hide-details="hidedetails"
                :clearable="false"
                @change="change"
                @dblclick="onDoubleClick"
            />
            <json-editor
                v-else
                :object="currentObject"
                :header="header"
                :label="getLabel(header)"
                :viewconfig="viewconfig"
                :readonly="isReadOnly"
                :height="getHeightOfField(header)"
                :class="getClass(header)"
                :dense="dense"
                :filled="filled"
                :solo="solo"
                :hide-details="hidedetails"
                @change="change"
                @dblclick="onDoubleClick"
            />
        </div>

        <div v-if="getTypeOfField(header) === 'display'">
            <v-text-field
                ref="inputRef"
                v-model="currentValue"
                :label="header.text"
                :class="getClass(header)"
                :dense="dense"
                :filled="filled"
                :solo="solo"
                :hide-details="hidedetails"
                readonly
                @change="change"
                @dblclick="onDoubleClick"
            />
        </div>
        <div v-if="getTypeOfField(header) === 'title'">
            <h3>{{ getTitleValue(header) }}</h3>
        </div>
        <div v-if="header.type === 'switch'">
            <v-switch
                v-model="currentValue"
                :label="getLabel(header)"
                :disabled="false"
                :readonly="isReadOnly"
                :dense="dense"
                :filled="filled"
                :solo="solo"
                :hide-details="hidedetails"
                inset
                class="v-input--reverse"
                @change="change"
            />
        </div>
        <div v-if="header.type === 'checkbox'">
            <v-checkbox
                v-model="currentValue"
                :label="getLabel(header)"
                :disabled="isReadOnly"
                :dense="dense"
                :hide-details="hidedetails"
                @change="change"
            />
        </div>
        <div v-if="header.type === 'progressBar'">
            <v-progress-linear
                :value="currentValue"
                :readonly="true"
                background-color="tileBackgroundColor"
                rounded
                height="4"
                color="primary"
                @change="change"
            />
        </div>
        <div v-if="header.type === 'percentageBar'">
            <v-row
                align="center"
                no-gutters
            >
                <span class="text-subtitle">{{ roundNumber(isEmpty(currentValue) ? 0 : currentValue * 100) }}%</span>
                <v-progress-linear
                    :value="isEmpty(currentValue) ? 0 : currentValue * 100"
                    :readonly="true"
                    :color="currentValue >= 1 ? 'success' : 'quinary'"
                    background-color="tileBackgroundColor"
                    rounded
                    height="4"
                    @change="change"
                />
            </v-row>
        </div>
        <textEditor
            v-if="header.type === 'textEdit'"
            :object="currentObject"
            :header="header"
            :label="getLabel(header)"
            :viewconfig="viewconfig"
            :readonly="isReadOnly"
            :height="getHeightOfField(header)"
            :class="getClass(header)"
            :dense="dense"
            :filled="filled"
            :solo="solo"
            :hide-details="hidedetails"
            @change="change"
            @dblclick="onDoubleClick"
        />
        <HtmlEditorInput
            v-if="header.type === 'HtmlEditor'"
            :object="currentObject"
            :header="header"
            :label="getLabel(header)"
            :viewconfig="viewconfig"
            :readonly="isReadOnly"
            :height="getHeightOfField(header)"
            :className="getClass(header)"
            :filled="filled"
            :solo="solo"
            :hide-details="hidedetails"
            :change="change"
        />


        <v-container
            v-if="getTypeOfField(header) === 'link'"
            style="min-width: 140px;"
            class="my-0 pa-0"
        >

            <v-autocomplete
                ref="inputRef"
                :items="getItems"
                v-model="currentValue"
                :label="getLabel(header)"
                :readonly="isReadOnly"
                :item-text="getDisplayRelatedObject(header)"
                :class="getClass(header)"
                :item-value="getValueRelatedObject(header)"
                :dense="dense"
                :filled="filled"
                :solo="solo"
                :hide-details="hidedetails"
                clearable
                @click.native="clickField(header, currentObject)"
                @change="change"
                @dblclick.native="onDoubleClick"
            >
                <template
                    v-slot:prepend-inner
                    v-if="isReadOnly"
                >
                    <v-avatar
                        class="mr-1"
                        style="min-width:0px!important; width: auto; height: auto; border-radius:6px;"
                    >
                        <img
                            v-if="getImage(header, getImageRelatedField(header))"
                            :src="getImage(header, getImageRelatedField(header))"
                            style="width:24px!important; height: 24px;border-radius:50%;overflow: hidden;"
                            alt="Avatar"
                        >
                        <v-icon
                            v-else
                            size="24px"
                            color="primary"
                        >
                            {{ getIcon(header) }}
                        </v-icon>
                    </v-avatar>
                </template>
            </v-autocomplete>

        </v-container>

        <v-container
            v-if="getTypeOfField(header) === 'idLink'"
            style="min-width: 140px;"
            class="my-0 pa-0"
        >

            <v-autocomplete
                v-if="isValueList(header, currentObject)"
                ref="inputRef"
                :items="getItems"
                v-model="currentValue"
                :label="getLabel(header)"
                :readonly="isReadOnly"
                :item-text="getDisplayRelatedObject(header)"
                :class="getClass(header)"
                :item-value="getValueRelatedObject(header)"
                :dense="dense"
                :filled="filled"
                :solo="solo"
                :hide-details="hidedetails"
                :clearable="false"
                :append-icon="getIconForHeader(header, currentObject)"
                @click:append="toggleValueSelect()"
                @click.native="clickField(header, currentObject)"
                @change="change"
                @dblclick.native="onDoubleClick"
            >
                <template
                    v-slot:prepend-inner
                    v-if="isReadOnly"
                >
                    <v-avatar style="min-width:0px!important; width: auto; height: auto;border-radius:6px;">
                        <img
                            v-if="getImage(header, getImageRelatedField(header))"
                            :src="getImage(header, getImageRelatedField(header))"
                            style="width:24px!important; height: 24px;border-radius:50%;overflow: hidden;"
                            alt="Avatar"
                        >
                        <v-icon
                            v-else
                            size="24px"
                            color="primary"
                        >
                            {{ getIcon(header) }}
                        </v-icon>
                    </v-avatar>
                </template>
            </v-autocomplete>
            <v-text-field
                v-show="!isValueList(header, currentObject)"
                ref="inputRef"
                v-model="currentValue"
                :label="getLabel(header)"
                :readonly="isReadOnly"
                :class="getClass(header)"
                :dense="dense"
                :filled="filled"
                :solo="solo"
                :hide-details="hidedetails"
                :append-icon="getIconForHeader(header, currentObject)"
                :clearable="false"
                @click:append="toggleValueSelect()"
                @keyup.enter="onFocus(header, currentObject)"
                @keyup.tab="onFocus(header, currentObject)"
                @click.native="clickField(header, currentObject)"
                @change="change"
                @dblclick.native="onDoubleClick"
            >
                <template
                    v-slot:prepend-inner
                    v-if="isReadOnly"
                >
                    <v-avatar
                        class="pa-0"
                        style="min-width:0px!important; width: auto; height: auto;border-radius:6px;"
                    >
                        <img
                            v-if="getImage(header, getImageRelatedField(header))"
                            :src="getImage(header, getImageRelatedField(header))"
                            style="width:24px!important; height: 24px;border-radius:50%;overflow: hidden;"
                            alt="Avatar"
                        >
                        <v-icon
                            v-else
                            size="24px"
                            color="primary"
                        >
                            {{ getIcon(header) }}
                        </v-icon>
                    </v-avatar>
                </template>
            </v-text-field>

        </v-container>

        <div v-if="getTypeOfField(header) === 'valueList'">
            <v-select
                ref="inputRef"
                :items="getValueListItems(header.dropdown)"
                v-model="currentValue"
                :label="getLabel(header)"
                :readonly="isReadOnly"
                :class="getClass(header)"
                :dense="dense"
                :filled="filled"
                :solo="solo"
                :hide-details="hidedetails"
                :clearable="false"
                :menu-props="{ bottom: true, offsetY: true }"
                item-text="descr"
                item-value="value"
                @keyup.enter="onFocus(header, currentObject)"
                @ended="onEnded(header, currentObject)"
                @change="change"
                @dblclick.native="onDoubleClick"
            />
        </div>

        <div v-if="getTypeOfField(header) === 'actions'">
            <actionlistcell
                :header="header"
                :object="currentObject"
                :viewconfig="viewconfig"
                :is-not-list="isNotList"
            />
        </div>
        <div v-if="header.type === 'relativeDate'">

            <v-text-field
                :value="getCalendar(currentValue)"
                :label="getLabel(header)"
                :class="getClass(header)"
                :dense="dense"
                :filled="filled"
                :solo="solo"
                :hide-details="hidedetails"
                style="width: 100%;"
                readonly
                prepend-inner-icon="hi-calendar-days-outline"
                @dblclick.native="onDoubleClick"
            />

            <!-- <v-row
                v-if="currentValue"
                no-gutters
                align="center"
                justify="start"
                @dblclick="onDoubleClick"
            >

                <v-row
                    align="center"
                    class="text-caption ma-0"
                >
                    <v-icon
                        class="mr-2"
                        color="gray"
                    >hi-calendar-days-outline</v-icon>
                    {{ getCalendar(currentValue) }}</v-row>
            </v-row> -->
        </div>
        <div v-if="header.type === 'relativeDateChip'">
            <div
                class="iconButton rounded-lg pl-3 pr-2 pt-1 pb-2"
                v-if="readonly"
            >
                <label
                    class="gray--text"
                    style="font-size: .6rem;"
                >{{ getLabel(header) }}</label>
                <v-row
                    v-if="currentValue"
                    no-gutters
                    align="center"
                    justify="start"
                    @dblclick="onDoubleClick"
                >
                    <v-chip
                        :color="getUrgency(currentValue)"
                        small
                    >
                        <v-icon
                            small
                            class="mr-1"
                        >hi-calendar-days-outline
                        </v-icon>
                        {{ getUrgency(currentValue) === 'warning' ? 'Today' : getRelative(currentValue)
                        }}
                    </v-chip>

                </v-row>

            </div>
            <v-menu
                v-else
                v-model="menu2"
                :close-on-content-click="false"
                :rounded="'lg'"
                transition="scale-transition"
                offset-y
                readonly="true"
                min-width="290px"
            >
                <template v-slot:activator="{ on }">
                    <v-text-field
                        :value="formatValue(currentValue)"
                        :label="getLabel(header)"
                        :class="getClass(header)"
                        :dense="dense"
                        :filled="filled"
                        :solo="solo"
                        :hide-details="hidedetails"
                        readonly
                        prepend-inner-icon="hi-calendar-days-outline"
                        v-on="!readonly && on"
                        @click.native="clickField(header, currentObject)"
                        @dblclick.native="onDoubleClick"
                    />
                </template>
                <v-date-picker
                    v-model="currentValue"
                    :min="getMinDate"
                    @input="menu2 = false"
                    @change="changeDate"
                />

            </v-menu>
        </div>
        <div v-if="header.type === 'dateSelect'">
            <v-menu
                v-model="menu2"
                :close-on-content-click="false"
                :rounded="'lg'"
                transition="scale-transition"
                offset-y
                readonly="true"
                min-width="290px"
            >
                <template v-slot:activator="{ on }">
                    <v-text-field
                        :value="formatValue(currentValue)"
                        :label="getLabel(header)"
                        :class="getClass(header)"
                        :dense="dense"
                        :filled="filled"
                        :solo="solo"
                        :hide-details="hidedetails"
                        readonly
                        prepend-inner-icon="hi-calendar-days-outline"
                        v-on="!readonly && on"
                        @click.native="clickField(header, currentObject)"
                        @dblclick.native="onDoubleClick"
                    />
                </template>
                <v-date-picker
                    v-model="currentValue"
                    :min="getMinDate"
                    @input="menu2 = false"
                    @change="changeDate"
                />

            </v-menu>
        </div>
        <div v-if="header.type === 'timeSelect'">
            <v-select
                :items="getTimesOptions(fromTime)"
                :value="currentValue"
                :label="getLabel(header)"
                :readonly="isReadOnly"
                :class="getClass(header)"
                :dense="dense"
                :filled="filled"
                :solo="solo"
                :hide-details="hidedetails"
                :clearable="false"
                :menu-props="{ bottom: true, offsetY: true, auto: true }"
                item-text="value"
                item-value="value"
                prepend-inner-icon="hi-clock-outline"
                @keyup.enter="onFocus(header, currentObject)"
                @ended="onEnded(header, currentObject)"
                @input="changeTime"
                @change="change"
                @dblclick.native="onDoubleClick"
            >
                <template v-slot:item="{ item }">
                    <div class="text-body-2">{{ item.value }}{{ item.duration ? ` (${item.duration.label})` : '' }}
                    </div>
                </template>
            </v-select>
            <!-- <v-menu
        v-model="menu2"
        :close-on-content-click="false"
        :rounded="'lg'"
        transition="scale-transition"
        offset-y
        min-width="290px"
      >
        <template v-slot:activator="{ on }">
          <v-text-field
            v-model="currentValue"
            :label="getLabel(header)"
            :class="getClass(header)"
            :dense="dense"
            :filled="filled"
:solo="solo"
            :hide-details="hidedetails"
            :readonly="isReadOnly"
            prepend-inner-icon="hi-clock-outline"
            v-on="!readonly && on"
            @click.native="clickField(header, currentObject)"
            @change="change"
          />
        </template>
        <v-time-picker
          v-model="currentValue"
          :allowed-minutes="allowedStep"
          :filled="filled"
:solo="solo"
          :hide-details="hidedetails"
          format="24H"
          @click:minute="menu2 = false"
        />
      </v-menu> -->
        </div>
    </v-card>
</template>

<script>
import demo from "../../demo/Images/demoImages.js";
import * as Constants from "../../store/constants.js";
import listSelectionView from "../splitCard/ListView";
import textEditor from "./textEditor";
import { isEmpty, formatNumber, getTimesOptions, copyObject, roundNumber, isValidHttpUrl, isBase64, isBlob, isEmail, isPhone, getGuid, isNumeric, isEmptyArray } from "../../api/utilities.js";
import multipleInput from "./MultipleInput";
import pdfIcon from "../../assets/Icons/types/pdf.svg"
import zipIcon from "../../assets/Icons/types/zip.svg"
import JpgIcon from "../../assets/Icons/types/jpg.svg"
import excelIcon from "../../assets/Icons/types/excel.svg"
import wordIcon from "../../assets/Icons/types/word.svg"
import txtIcon from "../../assets/Icons/types/text.svg"
import xlsIcon from "../../assets/Icons/types/xls.svg"
import Vue from "vue";
import actionlistcell from "./ListCells/ActionListCell"
import moment from "moment";
import Axios from "axios";
import { mapGetters } from 'vuex';
import JsonEditor from './JsonEditor.vue';
import HtmlEditorInput from "../HtmlEditorInput.vue";
import { prepareFilter } from "../../api/dynamicFilter.js";

export default {
    components: { listSelectionView, textEditor, multipleInput, actionlistcell, JsonEditor, HtmlEditorInput },
    props: {
        viewconfig: {
            type: String,
            default: ""
        },
        header: {
            type: Object,
            default: () => ({})
        },
        object: {
            type: Object,
            default: () => ({})
        },
        keyobject: {
            type: Object,
            default: () => ({})
        },
        operationid: {
            type: String,
            default: ""
        },
        readonly: {
            type: Boolean,
            default: true
        },
        filterfunction: {
            type: Function,
            default: null
        },
        height: {
            type: Number,
            default: null
        },
        showlabel: {
            type: Boolean,
            default: true
        },
        filled: {
            type: Boolean,
            default: true
        },
        solo: {
            type: Boolean,
            default: false
        },
        dense: {
            type: Boolean,
            default: false
        },
        hidedetails: {
            type: String,
            default: "false"
        },
        navigateidlink: {
            type: Boolean,
            default: false
        },
        align: {
            type: String,
            default: "left"
        },
        isNotList: {
            type: Boolean,
            default: false
        },
        noBorder: {
            type: Boolean,
            default: false
        },
        appendText: {
            type: String,
            default: ""
        },
        prependText: {
            type: String,
            default: ""
        },
        step: {
            type: Number,
            default: null
        },
    },
    data() {
        return {
            refreshKey: 0,
            menu2: false,
            menu3: false,
            dialog: false,
            menu: false,
            viewConfigOfSelection: null,
            selectionFunction: null,
            selectedField: null,
            demo,
            pdfIcon,
            JpgIcon,
            zipIcon,
            excelIcon,
            wordIcon,
            txtIcon,
            xlsIcon,
            valueSelect: false,
            imagePreviewModal: false,
            isAutofocus: false,
            ALERT_TYPE_WARNING: Constants.ALERT_TYPE_WARNING,
            ALERT_TYPE_ERROR: Constants.ALERT_TYPE_ERROR,
            pdfData: null
            // currentObject: {},
            // firstTime: true
        };
    },
    computed: {
        ...mapGetters(['isMobileDevice']),
        isReadOnly() {
            return this.readonly;
        },
        getImageClass() {
            if (this.align === "center") {
                return "pa-0 centerImage";
            }
            return " pa-0 ";
        },

        getViewConfigOfSelection() {
            return this.viewConfigOfSelection;
        },
        getViewConfig() {
            return this.$store.getters.getViewConfigForID(this.viewconfig);
        },
        hasSelectionView() {
            return (
                this.viewConfigOfSelection !== undefined &&
                this.viewConfigOfSelection !== null
            );
        },
        currentValue: {
            get() {
                return this.currentObject[this.header.value];
            },
            set(value) {
                this.currentObject[this.header.value] = value;
                if (this.operationid) {
                    var payload = { operationId: this.operationid, field: this.header.value, value: this.currentObject }
                    this.$store.dispatch("updateValueForFieldForOperationId", payload)
                }
            }
        },
        currentObject() {
            this.refreshKey;
            if (!isEmpty(this.operationid)) {
                var operationContainer = this.$store.getters.getOperationContainerForId(
                    this.operationid
                );
                if (operationContainer && operationContainer.newObject) {
                    if (operationContainer.newObject[this.header.value] === undefined) {
                        operationContainer.newObject[this.header.value] = null;
                        if (this.header.type === 'amount') {
                            operationContainer.newObject[this.header.value] = 0;
                        }
                    }
                    return this.$store.getters.getOperationContainerForId(this.operationid).newObject
                }
            }
            return this.object;
        },
        getItems() {
            this.refreshKey;
            var filteredObjects = this.$store.getters.getDataObjectsForObjectType(
                this.header.relatedObjectType
            );
            if (this.filterfunction !== undefined && this.filterfunction !== null) {
                filteredObjects = this.filterfunction(this.header, this.currentObject, filteredObjects);
            }
            // filter fix
            if (this.header.selectionView && !isEmpty(this.header.selectionView)) {
                console.log(this.currentObject)
                var selectionView = this.currentObject[this.header.selectionView]
                if (selectionView) {
                    filteredObjects = filteredObjects.filter(item => item[this.header.selectionView] === selectionView)
                } else {
                    const payload = {
                        objects: filteredObjects,
                        object: this.currentObject,
                        filterId: this.header.selectionView
                    }
                    filteredObjects = this.$store.getters.getFilteredObjectsForFilterConfigId(payload)
                }
            }
            filteredObjects.unshift({
                [this.getValueRelatedObject(this.header)]: null,
                [this.getDisplayRelatedObject(this.header)]: ''
            });
            return filteredObjects;
        },
        getObjectType() {
            return this.getViewConfig.objectType;
        },
        getObjectConfig() {
            return this.$store.getters.getObjectConfigForType(this.getObjectType);
        },
        isCheckBox() {
            return this.currentObject && this.currentObject.Min === 0 && this.currentObject.Max === 1
        },
        getImageType() {
            if (this.currentObject) {
                if (this.currentObject.attachmentType) return this.currentObject.attachmentType
                if (this.currentObject.type) return this.currentObject.type
            }
            return null
        },
        getMinDate() {
            if (this.header.value === 'EndDate' || this.header.value === 'endDate') {
                const startDateHeader = this.currentObject.StartDate ? 'StartDate' : this.currentObject.startDate ? 'startDate' : null
                if (startDateHeader) return this.currentObject[startDateHeader]
            }
            return null
        },

        fromTime() {
            if (this.header.value === 'EndTime' || this.header.value === 'endTime') {
                const startTimeHeader = this.currentObject.StartTime ? 'StartTime' : this.currentObject.startTime ? 'startTime' : null
                if (startTimeHeader) {
                    const endDateHeader = this.currentObject.EndDate ? 'EndDate' : this.currentObject.endDate ? 'endDate' : null
                    const startDateHeader = this.currentObject.StartDate ? 'StartDate' : this.currentObject.startDate ? 'startDate' : null
                    if (endDateHeader && startDateHeader) {
                        if (this.currentObject[startDateHeader] !== this.currentObject[endDateHeader]) return null
                    }
                    return this.currentObject[startTimeHeader]
                }
            }
            return null
        }
    },
    methods: {
        formatNumber,
        getTimesOptions,
        roundNumber,
        isEmpty,
        isEmail,
        isPhone,
        getTypeIcon(type) {
            console.log(type)
            if (type.includes('pdf')) {
                return pdfIcon
            }
            if (type === 'text/csv') {
                return excelIcon
            }
            if (type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
                return xlsIcon
            }
            if (type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') {
                return wordIcon
            }
            if (type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') {
                return wordIcon
            }
            if (type === 'text/plain') {
                return txtIcon
            }
            if (type.includes('image')) {
                return JpgIcon
            }
            return JpgIcon
        },
        goToMap(field, currentObject) {
            if (this.isMobileDevice && this.currentObject.lat && this.currentObject.lng && this.isMo) {
                window.open(`geo:${this.currentObject.lat}, ${this.currentObject.lng}`);
            } else {
                window.open(`http://maps.google.com/?q=${this.currentValue}`)
            }
        },
        goToUrl() {
            if (isEmpty(this.currentValue)) {
                return
            }
            window.open(`${this.currentValue}`)
        },
        call(field, currentObject) {

            // if (this.$store.getters.getDeviceSize === 1) {
            window.open(`tel:${this.currentValue}`);
            // } else this.clickField(field, currentObject)

        },
        email(field, currentObject) {
            window.open(`mailTo:${this.currentValue}`);
            // this.clickField(field, currentObject)

        },
        onDoubleClick() {
            if (!this.readonly) {
                return
            }
            this.$emit('onDoubleClick')
        },
        disableAutofocus() {
            this.isAutofocus = false
        },
        getObjects() {
            var items = this.$store.getters.getDataObjectsForObjectType(
                this.getObjectType
            );
            return items;
        },
        getFilteredObjects() {
            var objects = this.getObjects();
            var payload = {
                objects: objects,
                viewConfig: this.getViewConfig,
                object: this.currentObject,
                isEdit: !this.readonly
            };
            var filteredObject = this.$store.getters.getFilteredObjectsForViewConfig(
                payload
            );
            //Todo filter
            return filteredObject;
        },

        changeTime(value) {
            if (this.header.value === 'StartTime' || this.header.value === 'startTime') {
                const endTimeHeader = this.currentObject.EndTime ? 'EndTime' : this.currentObject.endTime ? 'endTime' : null
                if (endTimeHeader) {
                    const startTime = this.currentObject.StartTime || this.currentObject.startTime
                    const endTime = this.currentObject[endTimeHeader]


                    const durationIndex = getTimesOptions(startTime).map(x => x.value).indexOf(endTime)
                    if (durationIndex > -1) {
                        const duration = getTimesOptions(startTime)[durationIndex].duration
                        const newEndTime = this.$moment(value, 'HH:mm').add(duration.amount, duration.scale).format('HH:mm')
                        Vue.set(this.currentObject, endTimeHeader, newEndTime)
                    }
                }
            }
            Vue.set(this.currentObject, this.header.value, value)
            this.change(value)
        },
        changeDate(value) {
            if (this.header.value === 'StartDate' || this.header.value === 'startDate') {
                const endDateHeader = this.currentObject.EndDate ? 'EndDate' : this.currentObject.endDate ? 'endDate' : null
                if (endDateHeader) {
                    const startDate = this.$moment(value, 'YYYY-MM-DD')
                    const endDate = this.$moment(this.currentObject[endDateHeader], 'YYYY-MM-DD')
                    if (startDate && endDate && startDate.isAfter(endDate)) {
                        Vue.set(this.currentObject, endDateHeader, value)
                    }
                }
            }
            this.currentValue = value
            this.change(value)
        },
        getChipClass(header, object, dropdown) {
            if (header && object && dropdown) {
                const value = object[header.value]
                const listItem = dropdown.find(item => item.value === value)
                if (listItem) {
                    return `${listItem.color} white--text`
                }
            }
            return ''

        },
        change(value) {
            // if (this.header.type === 'link' && value === '') {
            //     this.object[this.header.value] = null
            // }
            this.$emit('change');
            if (this.getViewConfig && this.getViewConfig.objectType) {
                var objectConfig = this.$store.getters.getObjectConfigForType(this.getViewConfig.objectType);
                if (objectConfig) {
                    objectConfig.afterModify(this.currentObject, null, this.$store);
                }
            }

        },
        getMin() {
            return (this.currentObject.Min !== null && this.currentObject.Min !== undefined && !isEmpty(this.currentObject.Min)) ? this.currentObject.Min : 0
        },
        getMax() {
            return (this.currentObject.Max !== null && this.currentObject.Max !== undefined && !isEmpty(this.currentObject.Max)) ? this.currentObject.Max : 999999
        },
        onInputMinMax(value) {
            if (this.currentObject.Min && !isEmpty(this.currentObject.Min)) {
                if (value < this.currentObject.Min) return this.currentObject.Min
            }
            if (this.currentObject.Max && !isEmpty(this.currentObject.Max)) {
                if (value > this.currentObject.Max) return this.currentObject.Max
            }
            return value
        },
        add(field, amount) {
            const value = this.onInputMinMax(Number(this.currentObject[field]) + Number(amount))
            this.currentValue = value
            this.refreshKey++;
            this.change(amount)
        },
        formatValue(value) {
            if (!isEmpty(value)) {
                return this.$moment(value).format('DD/MM/YYYY');
            }
            return ''
        },
        getClass(header) {
            var cssClass = "text-field-transparent";
            if (this.align === "right") {
                cssClass = cssClass + " alignRight";
            }
            if (this.isLink(header)) {
                cssClass = cssClass + " link primary--input";
            }
            if (header.type === 'amount') {
                cssClass += " amount";
            }
            if (header.type === 'dateSelect' || header.type === 'relativeDateChip') {
                if (!this.readonly) cssClass += " date-select";

            }
            if (this.noBorder) {
                cssClass += " no-border";
            }
            return cssClass;
        },
        getLabel(header) {
            if (this.showlabel === true) {
                return header.text;
            }
            return "";
        },
        isLink(field) {
            if (!isEmpty(field.navigationConfigId) && this.readonly === true) {
                return true;
            }
            if (field.type === "link") {
                return true;
            }
            if (field.type === "idLink" && this.navigateidlink === true) {
                return true;
            }
            return false;
        },
        getHeightOfField(field) {
            if (field.type === "image" || field.type === "textEdit") {
                if (isEmpty(this.height)) {
                    return "300px";
                }
            }
            var height = this.height - 20;
            return "".concat(height).concat("px");
        },
        getTypeOfField(field) {
            if (field.type === "link" && !isEmpty(field.dropdown)) {
                return "valueList";
            }
            return field.type;
        },
        getStep(min, max) {
            if (min !== null && max !== null) {
                const result = Math.abs(Math.round(min - max) / 100)
                return result
            } else return 1
        },
        getIcon(field) {
            if (field && !isEmpty(field.icon)) return field.icon
            return null;
        },
        async getPdf(field, object) {
            const pdfUrl = this.getFullImage(field, object)
            if (pdfUrl) {
                if (isBlob(pdfUrl)) {
                    this.pdfData = pdfUrl
                }
                if (isValidHttpUrl(object[field.image])) {
                    const response = await Axios.get(pdfUrl, {
                        responseType: 'blob',
                    })
                    if (response && response.data) {
                        this.pdfData = URL.createObjectURL(response.data)
                    }
                }

            }
        },
        hasImage(field, object) {
            return !isEmpty(this.getImage(field, object))
        },
        getImage(field, object) {
            if (
                (object && (field.image !== undefined && field.image !== null) ||
                    field.type === "image")
            ) {
                if (object.thumbnail) {
                    // if (isBase64(object.thumbnail)) {
                    return object.thumbnail
                    // }
                } else if (object[field.image]) {
                    if (isValidHttpUrl(object[field.image])) {
                        return object[field.image]
                    }
                } else if (object.image) {
                    if (isValidHttpUrl(object.image)) {
                        return object.image
                    }
                }
                else if (object.url) {
                    if (isValidHttpUrl(object.url)) {
                        return object.url
                    }
                }
            }
            return null;
        },
        getFullImage(field, object) {

            if (field && field.image && field.type === "image" && object[field.image]) {
                return object[field.image]
            }
            if (object.file && object.file !== 'file') {
                if (object.attachmentType === 'application/pdf') {
                    var fileURL = this.convertBase64ToBlob(object.file);
                    return fileURL
                }
                return object.file
            }
            return null;
        },
        convertBase64ToBlob(dataURI) {
            var byteString = atob(dataURI.split(',')[1]);

            // separate out the mime component
            var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]

            // write the bytes of the string to an ArrayBuffer
            var ab = new ArrayBuffer(byteString.length);

            // create a view into the buffer
            var ia = new Uint8Array(ab);

            // set the bytes of the buffer to the correct values
            for (var i = 0; i < byteString.length; i++) {
                ia[i] = byteString.charCodeAt(i);
            }

            // write the ArrayBuffer to a blob, and you're done
            var blob = new Blob([ab], { type: mimeString });
            var URL = window.URL || window.webkitURL;
            return URL.createObjectURL(blob);
        },
        getImageRelatedField(field) {
            const currentItem = this.getItems.find(o => o[this.getValueRelatedObject(field)] === this.currentValue)
            return currentItem;
        },
        getDisplayRelatedObject(field) {
            if (
                field.displayRelatedObject !== undefined &&
                field.displayRelatedObject !== null
            ) {
                return field.displayRelatedObject;
            }
            return "text";
        },
        getValueRelatedObject(field) {
            if (
                field.valueRelatedObject !== undefined &&
                field.valueRelatedObject !== null
            ) {
                return field.valueRelatedObject;
            }
            return "Guid";
        },
        getUrgency(date) {
            if (isEmpty(this.header.selectionView)) {
                return null
            } else {
                try {
                    const filter = JSON.parse(this.header.selectionView)
                    const preparedFilter = prepareFilter(filter, this.$store.getters)
                    const f = new Function("item", " return " + preparedFilter + ";");
                    const filtered = [this.object].filter(f)
                    if (isEmptyArray(filtered)) {
                        return null
                    }
                } catch (error) {
                    console.log(error)
                }
            }
            const dueDate = this.getDueDate(date)
            const today = moment().endOf('day').format(Constants.DATE_FORMAT)
            if (moment(dueDate, Constants.DATE_FORMAT).isAfter(today, 'day')) {
                return null
            } else if (moment(dueDate, Constants.DATE_FORMAT).isSame(today, 'day')) {
                return Constants.ALERT_TYPE_WARNING
            }
            return Constants.ALERT_TYPE_ERROR
        },
        getDueDate(date) {
            return moment(date).endOf('day')
        },
        getRelative(date) {
            return this.getDueDate(date).fromNow()
        },
        getCalendar(date) {
            const twoWeeks = moment().subtract(1, 'weeks')
            let newDate = date
            if (isNumeric(date)) {
                newDate = Number(date)
            }
            if (moment(newDate).isBefore(twoWeeks)) {
                return moment(newDate).fromNow()
            }
            return moment(newDate).calendar()
        },
        getValue(field, object) {
            if (
                field.displayRelatedObject !== undefined &&
                field.displayRelatedObject !== "" &&
                field.displayRelatedObject !== null
            ) {
                var payload = {
                    objectType: field.relatedObjectType,
                    objectId: object[field.value]
                };
                var relatedObject = this.$store.getters.getDataObjectForIdAndObjectType(
                    payload
                );
                if (relatedObject !== undefined) {
                    //Split
                    return splitFields(relatedObject, field.displayRelatedObject);
                } else {
                    return "";
                }
            }
            if (field.type === "date") {
                var date = object[field.value];
                return jsonDateToString(date);
            }
            return object[field.value];
        },
        getTitleValue(field) {
            if (isEmpty(field.text) && !isEmpty(field.value)) {
                return this.getValue(field, this.currentObject)
            }
            return field.text
        },
        getColor(field) {
            if (field.type === Constants.LINK && this.isReadOnly === true) {
                return "primary";
            }
            return "normal";
        },
        getValueListItems(listCode) {
            let list = structuredClone(this.$store.getters.getValueListForId(listCode))
            if (!this.header.isMandatory && Array.isArray(list)) {
                list.unshift({ value: null, descr: '' })
            }
            return list;
        },
        getValueFromValueList(listCode, value) {
            let list = this.$store.getters.getValueListForId(listCode)
            if (value && Array.isArray(list)) {
                const item = list.find(item => item.value === value)
                if (item) {
                    return item.descr
                }
            }
            return value;
        },
        clickField: function (field, object) {
            this.refreshKey++;
            if (!this.isLink(field)) {
                return;
            }
            if (!this.isReadOnly) {
                return;
            }
            if (isEmpty(object[field.value])) {
                return;
            }

            if (
                this.selectionfunction !== null &&
                this.selectionfunction !== undefined
            ) {
                this.selectionfunction(object);
                return;
            }
            var navigationObject = object;
            if (
                field.relatedObjectType !== undefined &&
                field.navigationId !== null
            ) {
                var navigationId = object[field.value];
                if (field.navigationId !== undefined && field.navigationId !== null) {
                    navigationId = object[field.navigationId];
                }
                var payload = {
                    objectType: field.relatedObjectType,
                    objectId: navigationId
                };
                var navigationObject = this.$store.getters.getDataObjectForIdAndObjectType(
                    payload
                );
            }
            var payload = {
                splitCardId: field.navigationConfigId,
                object: navigationObject
            };
            this.$store.dispatch(Constants.SELECT_OBJECT, payload);
        },
        openImagePreviewModel() {
            if (this.getImageType.includes('image/') || this.getImageType.includes('pfd')) {
                this.imagePreviewModal = true
                this.getPdf(this.header, this.currentObject)
            } else {
                window.open(this.object.url, "_blank");
                return
            }
        },
        toggleValueSelect() {
            this.valueSelect = !this.valueSelect;
        },
        isValueList(header, currentObject) {
            if (!this.valueIsFound(header, currentObject) && this.isReadOnly) {
                return false;
            }
            return this.valueSelect;
        },
        valueIsFound(header, currentObject) {
            if (isEmpty(header.valueRelatedObject)) {
                return false;
            }
            var items = this.getItems;
            var item = items.find(
                element =>
                    element[header.valueRelatedObject] === this.currentValue
            );
            if (item === null || item === undefined) {
                return false;
            }
            return true;
        },
        getIconForHeader(header, currentObject) {

            return ""
        },

        onFocus(field, paramObject) {
            var viewConfig = this.$store.getters.getViewConfigForID(this.viewconfig);
            var _objectConfig = this.$store.getters.getObjectConfigForType(
                viewConfig.objectType
            );
            var _store = this.$store;
            var newObject = copyObject(this.currentObject);
            var newObject2 = _objectConfig.afterModify(newObject, null, _store, null);

            if (!isEmpty(this.operationid)) {
                var payload = {
                    operationId: this.operationid,
                    field: "field",
                    value: newObject2
                };

                this.$store.dispatch("updateValueForFieldForOperationId", payload);
            }
            this.keyobject.key++;
        },
        onSubmit() {
            console.log("onSubmit");
        },
        onEnded() {
            console.log("onEnded");
        },

        /*
          Dialogs
        */

        openDialog(field, object) {
            this.viewConfigOfSelection = field.selectionView;
            this.selectedField = field;
            this.selectionFunction = this.objectSelected;
            this.dialog = true;
        },
        objectSelected(selectedObject) {
            this.currentObject[this.selectedField.value] = selectedObject.objectType;
            this.closeDialog();
        },
        cancelSelection() {
            this.closeDialog();
        },
        closeDialog() {
            this.selectedField = null;
            this.viewConfigOfSelection = null;
            this.selectionFunction = null;
            this.dialog = false;
        },
        allowedStep: m => m % 5 === 0
    }
};
</script>

<style scoped>
.fixrow {
    margin-top: -4px;
    margin: 0px;
}

.centerImage {
    text-align: center;
}
</style>
<style>
.amountWrap {
    position: absolute;
    width: 100%;
    top: 0;
}

.amountWrap-readonly {
    width: 100%;
}

.amountWrap .v-text-field .v-label {
    text-align: center;
    width: 130%;
}

.amountWrap-readonly .v-text-field input {
    text-align: left !important;
}
</style>
