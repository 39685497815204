import GenericObjectConfig from "../core/GenericObjectConfig.js";
import { getGuid, isEmpty } from "./utilities";
import {
  BOOLEAN,
  INTEGER,
  LONGSTRING,
  NUMBER,
  STRING
} from "../store/constants";

/*******************************************************************
  Build Objects
*******************************************************************/
export const buildObjects = (objects, fields) => {
  var dbObjects = [];
  objects.forEach(object => {
    dbObjects.push(buildObject(object, getFieldsForObjectType(object, fields)));
  });
  return dbObjects;
};

export const buildObject = (object, fields) => {
  return new GenericObjectConfig(object.objectType, fields, object);
};

export const getFieldsForObjectType = (object, fields) => {
  return fields.filter(function(o) {
    return o.objectType === object.objectType;
  });
};
/*******************************************************************
  New
*******************************************************************/

export const newObject = () => {
  let newObject = {}; // objCopy will store a copy of the mainObj
  newObject.syncId = getGuid();
  return newObject;
};

export const newObjectWithGuid = () => {
  let newObject = {}; // objCopy will store a copy of the mainObj
  newObject.syncId = getGuid();
  newObject.Guid = getGuid();
  return newObject;
};

export const fillGuidFieldsForObject = object => {
  object.syncId = getGuid();
  object.Guid = getGuid();
};

/*******************************************************************
  Copy
*******************************************************************/
export const createCopyObject = mainObj => {
  let objCopy = {}; // objCopy will store a copy of the mainObj
  let key;
  for (key in mainObj) {
    if (mainObj[key] !== undefined && mainObj[key] !== null) {
      objCopy[key] = mainObj[key];
    } // copies each property to the objCopy object
  }
  objCopy.Guid = getGuid();
  objCopy.syncId = getGuid();
  if (!isEmpty(objCopy._id)) {
    delete objCopy._id;
  }
  if (!isEmpty(objCopy.createdAt)) {
    delete objCopy.createdAt;
  }
  if (!isEmpty(objCopy.updatedAt)) {
    delete objCopy.updatedAt;
  }
  return objCopy;
};

export const copyObject = (mainObj, disableNewGuid) => {
  let objCopy = {}; // objCopy will store a copy of the mainObj
  let key;
  for (key in mainObj) {
    if (mainObj[key] !== undefined && mainObj[key] !== null) {
      objCopy[key] = mainObj[key];
    } // copies each property to the objCopy object
  }
  if (disableNewGuid !== true) {
    objCopy.syncId = getGuid();
  }
  return objCopy;
};

export const copyFieldsOfTo = (fromObject, toObject) => {
  let key;
  for (key in fromObject) {
    if (
      key !== "syncId" &&
      fromObject[key] !== null &&
      fromObject[key] !== undefined
    ) {
      toObject[key] = fromObject[key];
    }
  }
};

/************************************************************
 * CheckField is correct
 ***********************************************************/

export const fieldTypeIsCorrect = (object, fieldConfig) => {
  var value = object[fieldConfig.field];
  if (!isEmpty(value)) {
    switch (fieldConfig.type) {
      case NUMBER:
        return !isNaN(value);
      case INTEGER:
        return !isNaN(value);
      case BOOLEAN:
        return true; //"typeof value === "boolean"
      case STRING:
        return true;
      default:
        return true;
    }
    return false;
  }
  return true;
};

//Check if used
export const getTypeOfProperty = type => {
  if (type === LONGSTRING) {
    return "string";
  }
  return type;
};
