<template>
    <v-dialog
        max-width="600"
        v-model="getaddInboxDialog"
        content-class="rounded-lg"
        persistent
    >
        <v-card
            class="pa-4"
            rounded="lg"
        >
            <v-card-title class="text-h5">
                <div v-if="!isImap">
                    Add inbox
                </div>
                <div
                    v-if="isImap"
                    class="d-flex gap-1 align-center"
                >
                    <v-btn
                        elevation="0"
                        icon
                        class="iconButton"
                        @click="isImap = false"
                    > <v-icon
                            class="configLight"
                            icon
                        >hi-arrow-left-outline</v-icon> </v-btn>
                    <div class="text-h6 ">
                        Add Imap Mail
                    </div>
                </div>
                <v-spacer />
                <v-btn
                    icon
                    class="iconButton"
                    @click="close"
                >
                    <v-icon
                        class="configLight"
                        icon
                    >hi-x-mark-outline</v-icon>
                </v-btn>
            </v-card-title>
            <v-container fluid>
                <div>
                    <v-container v-if="!isImap">
                        <v-row class="gap-1">

                            <v-btn
                                elevation="0"
                                class="pa-4 iconButton"
                                style="max-height: none;height: auto;"
                                @click="authGoogle"
                            > <img src="../../assets/Icons/gmail.svg"></v-btn>

                            <v-btn
                                elevation="0"
                                class="pa-4 iconButton"
                                style="max-height: none;height: auto;"
                                @click="isImap = true"
                            > Other (IMAP)</v-btn>

                        </v-row>
                    </v-container>
                    <v-card
                        elevation="0"
                        class="pa-2"
                        v-if="isImap"
                    >
                        <v-container>

                            <v-row class="gap-1">
                                <v-text-field
                                    v-model="email"
                                    :rules="[rules.email]"
                                    name="email"
                                    label="Email"
                                    class="text-field-transparent no-border"
                                    solo
                                />
                                <v-text-field
                                    v-model="password"
                                    :append-icon="showPassword ? 'hi-eye-slash' : 'hi-eye'"
                                    :rules="[rules.required]"
                                    :type="showPassword ? 'text' : 'password'"
                                    name="password"
                                    label="Password"
                                    hint="At least 8 characters"
                                    class="text-field-transparent no-border "
                                    solo
                                />

                            </v-row>

                        </v-container>
                        <v-card
                            rounded="lg"
                            elevation="0"
                            class=""
                        >
                            <v-container>
                                <v-row class="pb-4">
                                    <div class="caption px-2">Advanced</div>
                                </v-row>
                                <v-row class="gap-1 pb-2">

                                    <v-text-field
                                        v-model="host"
                                        name="imap-host"
                                        label="IMAP host"
                                        class="text-field-transparent no-border "
                                        solo
                                        hide-details
                                    />
                                    <v-text-field
                                        v-model="port"
                                        number
                                        name="imap-port"
                                        label="port"
                                        class="text-field-transparent no-border flex-1"
                                        solo
                                        hide-details
                                    />

                                </v-row>
                                <v-row class="gap-1 pb-2">
                                    <v-text-field
                                        v-model="hostOut"
                                        name="smtp-host"
                                        label="SMTP host"
                                        class="text-field-transparent no-border "
                                        solo
                                        hide-details
                                    />
                                    <v-text-field
                                        v-model="portOut"
                                        number
                                        name="smtp-port"
                                        label="port"
                                        class="text-field-transparent no-border flex-1"
                                        solo
                                        hide-details
                                    />

                                </v-row>
                            </v-container>
                        </v-card>
                        <v-card-actions class="">
                            <v-spacer />


                            <v-btn
                                color="primary"
                                class="px-10"
                                elevation="0"
                                @click="add"
                            >
                                Add
                            </v-btn>
                        </v-card-actions>
                    </v-card>

                </div>

            </v-container>



        </v-card>

    </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";

export default {
    components: {},
    data() {
        return {
            email: "",
            password: "",
            isImap: false,
            host: "",
            port: 993,
            hostOut: "",
            portOut: 465,
            showPassword: false,
            rules: {
                required: value => !!value || "Required.",
                min: v => v.length >= 8 || "Min 8 characters"
            },
        }
    },
    computed: {
        ...mapGetters(["getaddInboxDialog"]),


    },

    methods: {
        authGoogle() {
            this.$store.dispatch('signInGoogle')
        },
        close() {
            this.$store.commit('hidAddInboxDialog')
        }
    }
};
</script>

<style lang="css" scoped></style>