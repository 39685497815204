import {
  ACTION_CONFIG,
  ALERT_TYPE_ERROR,
  ALERT_TYPE_INFO,
  ALERT_TYPE_SUCCESS,
  AUTHORISATION,
  CARD_CONFIG,
  FIELD_CONFIG,
  FILTER_CONFIG,
  FILTER_FIELD_CONFIG,
  initObjectConfigs,
  MENU_ITEM,
  OBJECT_CONFIG,
  SPLIT_CARD,
  SYNC_GROUP_CRM,
  TAB_CONFIG,
  VIEW_CONFIG,
  VIEW_FIELD,
} from "../../store/constants";
import { copyObject, getGuid, isEmpty, isTrue } from "../../api/utilities";
import * as ObjectApi from "../../api/objectApi";
import Vue from "vue";
import moment from "moment";

export const beforeSave = (objectConfig, object, context, options) => {
  if (object.objectType === "AppointmentAttendee") {
    beforeCreate(objectConfig, object, context, options);
  }
  // We will fill the name of the employee responsible. We need the object Context.

  return true;
};

export const afterCreate = (
  objectConfig,
  object,
  relatedObject,
  context,
  options,
) => {
  // When theo bject must be created.
  return object;
};

export const afterModify = (
  objectConfig,
  object,
  relatedObject,
  context,
  options,
) => {
  // When theo bject must be created.
  return object;
};

export const beforeCreate = (objectConfig, object, context, options) => {
  createCoreFields(objectConfig, object, context, options);
};

export const getTile = (objectConfig, object, context, options) => {
  return [];
};

export const getRelatedObjects = (objectConfig, object, context, options) => {};

/***********************************************************************
 *   Utilities
 ************************************************************************/

function isTableCorrect(objectConfig, tableInfo) {
  var fields = objectConfig.fieldsConfig;
  var dbFields = getTableFieldsForTable(tableInfo, objectConfig.objectType);
  for (var i = 0; i < fields.length; i++) {
    var field = fields[i];
    if (!isEmpty(field.field)) {
      var foundDbField = dbFields.find(function (o) {
        return o.field === field.field;
      });
      if (isEmpty(foundDbField) || !sameTypes(field, foundDbField)) {
        console.log("Table not correct for field", field, foundDbField);
        return false;
      }
    } else {
      console.log("Table not correct for field", field, foundDbField);
      return false;
    }
  }

  for (var j = 0; j < dbFields.length; j++) {
    var dbField = dbFields[j];
    if (!isEmpty(dbField.field)) {
      var foundField = fields.find(function (o) {
        return o.field === dbField.field;
      });
      if (isEmpty(foundField) || !sameTypes(foundField, dbField)) {
        console.log("Table not correct for field", dbField, foundField);
        return false;
      }
    } else {
      console.log("Table not correct for field", field, foundDbField);
      return false;
    }
  }
  return true;
}

function getTableFieldsForTable(tableInfos, table) {
  var tableFields = [];
  var tableInfo = tableInfos.find(function (o) {
    return o.name === table;
  });
  if (!isEmpty(tableInfo)) {
    var tableFields = getTableFieldsForTableString(tableInfo);
  }
  return tableFields;
}

function getTableFieldsForTableString(tableString) {
  var tableFields = [];
  const regExp = /\(([^)]+)\)/g;
  var tableFieldString = tableString.sql.match(regExp);
  var fieldStrings = tableFieldString[0].split(",");
  for (var i = 0; i < fieldStrings.length; i++) {
    var fieldString = fieldStrings[i];
    var isId = false;
    if (fieldString.includes("PRIMARY KEY")) {
      isId = true;
      fieldString = fieldString.replace("PRIMARY KEY", "");
    }
    fieldString = fieldString.replace("( ", "");
    fieldString = fieldString.replace("(", "");
    fieldString = fieldString.replace(") ", "");
    fieldString = fieldString.replace(")", "");
    var field = convertStringToField(fieldString, isId);
    if (isId === true) {
      updateFieldWithId(tableFields, field);
    } else {
      tableFields.push(field);
    }
  }
  return tableFields;
}

function sameTypes(field, dbField) {
  return (
    field.type === dbField.type &&
    (field.isId === dbField.isId ||
      (!isTrue(field.isId) && !isTrue(dbField.isId)))
  );
}

function convertStringToField(fieldString, isId, fields) {
  var fieldStringTrimmed = fieldString.trim();
  var fieldType = fieldStringTrimmed.split(" ");
  var field = fieldType[0];
  var type = fieldType[1];
  var field = { field, type, isId };
  return field;
}

function updateFieldWithId(fields, field) {
  var field = fields.find(function (o) {
    return o.field === field.field;
  });
  field.isId = true;
}

/***********************************************************************
 *   Actions
 ************************************************************************/

/**---------------------------------------------------------------
* Actions
---------------------------------------------------------------*/
export function canLaunchActionForObjectAndConfig(
  action,
  objectConfig,
  object,
  viewConfig,
  context,
  options,
) {
  if (isEmpty(object)) {
    return false;
  }
  if (action === "createCoreFields") {
    var fields = context.getters.getDataObjectsForObjectType(FIELD_CONFIG);
    var field = fields.find(function (o) {
      return o.objectType === object.objectType && o.field === "Guid";
    });
    if (!isEmpty(field)) {
      if (!isEmpty(options) && options.showErrorMessage === true) {
        context.commit("showAlert", {
          type: ALERT_TYPE_ERROR,
          message: "Fields already exist",
        });
      }
      return;
    }
  }
  if (action === "actionCreateSplitCardConfigs") {
    var splitCards = context.getters.getDataObjectsForObjectType(SPLIT_CARD);
    var splitCard = splitCards.find(function (o) {
      return o.objectType === object.objectType;
    });
    if (!isEmpty(splitCard)) {
      if (!isEmpty(options) && options.showErrorMessage === true) {
        context.commit("showAlert", {
          type: ALERT_TYPE_ERROR,
          message: "split already exist",
        });
      }
      return;
    }
  }
  if (action === "createAuthorisationForCRMObject") {
    if (object.syncGroup !== SYNC_GROUP_CRM) {
      if (!isEmpty(options) && options.showErrorMessage === true) {
        context.commit("showAlert", {
          type: ALERT_TYPE_ERROR,
          message: "Authorsiation already exist",
        });
      }
      return;
    }
    var authorisations =
      context.getters.getDataObjectsForObjectType(AUTHORISATION);
    var authorisation = authorisations.find(function (o) {
      return o.objectType === object.objectType;
    });
    if (!isEmpty(authorisation)) {
      if (!isEmpty(options) && options.showErrorMessage === true) {
        context.commit("showAlert", {
          type: ALERT_TYPE_ERROR,
          message: "Authorsiation already exist",
        });
      }
      return;
    }
  }
  return true;
}

export const createCoreFields = (
  objectConfig,
  object,
  viewconfig,
  context,
  options,
) => {
  // Check if the fields already exists.

  //Create Sync Id field
  var syncIdField = ObjectApi.newObjectWithGuid();
  syncIdField.objectType = object.objectType;
  syncIdField.field = "syncId";
  syncIdField.description = "syncId";
  syncIdField.isId = false;
  syncIdField.type = "string";
  syncIdField.edit = false;
  syncIdField.local = true;

  var payload = {
    objectType: FIELD_CONFIG,
    object: syncIdField,
  };
  context.dispatch("saveObjectForObjectType", payload);

  // Create isDeleted Field
  var isDeletedField = ObjectApi.newObjectWithGuid();
  isDeletedField.objectType = object.objectType;
  isDeletedField.backendField = "isDeleted";
  isDeletedField.field = "isDeleted";
  isDeletedField.description = "isDeleted";
  isDeletedField.isId = false;
  isDeletedField.type = "integer";
  isDeletedField.edit = true;
  isDeletedField.local = false;

  payload.object = isDeletedField;
  context.dispatch("saveObjectForObjectType", payload);

  // Create Guid Field
  var guidField = ObjectApi.newObjectWithGuid();
  guidField.objectType = object.objectType;
  guidField.backendField = "Guid";
  guidField.field = "Guid";
  guidField.description = "Guid";
  guidField.isId = true;
  guidField.type = "string";
  guidField.edit = true;
  guidField.local = false;

  payload.object = guidField;
  context.dispatch("saveObjectForObjectType", payload);

  // Create _id Field
  var idField = ObjectApi.newObjectWithGuid();
  idField.objectType = object.objectType;
  idField.backendField = "_id";
  idField.field = "_id";
  idField.description = "_id";
  idField.isId = false;
  idField.type = "string";
  idField.edit = true;
  idField.local = false;

  payload.object = idField;
  context.dispatch("saveObjectForObjectType", payload);

  // Create updatedAt Field
  var updateAtField = ObjectApi.newObjectWithGuid();
  updateAtField.objectType = object.objectType;
  updateAtField.backendField = "updatedAt";
  updateAtField.field = "updatedAt";
  updateAtField.description = "updatedAt";
  updateAtField.isId = false;
  updateAtField.type = "string";
  updateAtField.edit = false;
  updateAtField.local = false;

  payload.object = updateAtField;
  context.dispatch("saveObjectForObjectType", payload);
};

/**
 * Create all objects needed for the splitcard.
 */
export async function actionCreateSplitCardConfigs(
  objectConfig,
  object,
  viewConfig,
  context,
  options,
) {
  // Check if the basic config already exist.
  context.commit("showAlert", {
    type: ALERT_TYPE_INFO,
    message: "Generating Views...",
  });

  var generatedConfigs = context.getters.getGeneratedSplitCard(
    object.objectType,
  ); //todo replace
  var objectTypes = [
    SPLIT_CARD,
    VIEW_CONFIG,
    VIEW_FIELD,
    CARD_CONFIG,
    TAB_CONFIG,
    ACTION_CONFIG,
    MENU_ITEM,
    FILTER_CONFIG,
    FILTER_FIELD_CONFIG,
  ];
  var objectConfig;
  var objectType = "";
  for (var i = 0; i < objectTypes.length; i++) {
    objectType = objectTypes[i];
    objectConfig = context.getters.getObjectConfigForType(objectType);
    //Operation maken.
    var objects = generatedConfigs[objectConfig.objectType];
    for (var j = 0; j < objects.length; j++) {
      var object = objects[j];
      var payload = {
        object: object,
        objectType: objectConfig.objectType,
      };
      await context.dispatch("saveObjectForObjectType", payload);
    }
    await context.dispatch("loadLeftMenu");
  }
  context.commit("showAlert", {
    type: ALERT_TYPE_SUCCESS,
    message: "Views Generated: Saving...",
  });
}

export async function actionCreateBackendScript(
  objectConfig,
  object,
  viewConfig,
  context,
  options,
) {
  var objectType = object.objectType;
  var objectModel = objectType;
  var varName = objectType;
  var pathName = objectType.concat("s");
  var backendScript = "let mongoose = require('mongoose'), \
 Schema = mongoose.Schema; \
let "
    .concat(objectType)
    .concat(" = new Schema({ ");
  var myObjectConfig = context.getters.getObjectConfigForType(objectType);
  var fields = myObjectConfig.fieldsConfig;

  for (var i = 0; i < fields.length; i++) {
    var field = fields[i];
    var fieldString = "";
    if (!isEmpty(field.backendField)) {
      var fieldString = field.backendField
        .concat(": {type: ")
        .concat(field.type)
        .concat("},  ");
    }
    backendScript = backendScript.concat(fieldString);
  }

  backendScript = backendScript.concat(
    "}, {timestamps: true}); module.exports= mongoose.model('",
  );

  backendScript = backendScript
    .concat(objectModel)
    .concat("', ")
    .concat(varName)
    .concat(", '")
    .concat(pathName)
    .concat("');");
}

/**
 * delete And RecreateTable ForObject
 */
export async function deleteAndRecreateTableForObject(
  objectConfig,
  object,
  viewconfig,
  context,
  options,
) {
  console.log("Recreating the tables");
  await context.dispatch(initObjectConfigs);
  var objectConfigToRefresh = context.getters.getObjectConfigForType(
    object.objectType,
  );
  await context.dispatch("_deleteDataTable", objectConfigToRefresh);
  await context.dispatch("_deleteDataTableHistory", objectConfigToRefresh);
  await context.dispatch("_createDataTable", objectConfigToRefresh);
  await context.dispatch("_createDataTableHistory", objectConfigToRefresh);
  if (isEmpty(options) || options.disableMessages !== true) {
    context.commit("showAlert", {
      type: ALERT_TYPE_INFO,
      message: "Table deleted and recreated",
    });
  }
}

/***
 * Force full Synchronisation for all objectConfigs
 */
export async function forceSynchronizeAllObjectObjects(
  objectConfig,
  object,
  viewconfig,
  context,
  options,
) {
  var objectConfigs =
    context.getters.getDataObjectsForObjectType(OBJECT_CONFIG);
  for (var i = 0; i < objectConfigs.length; i++) {
    var objectConfigToSync = objectConfigs[i];
    await forceSynchronizeAllObject(
      objectConfig,
      objectConfigToSync,
      viewconfig,
      context,
      { disableMessages: true },
    );
  }
  context.commit("showAlert", {
    type: ALERT_TYPE_INFO,
    message:
      "Synchroninisation Reset: Please resync and wait for full synchronisation. Do not quite application until fully synchronized",
  });
}

/***
 * Force full Synchronisation for object
 */
export async function forceSynchronizeAllObject(
  objectConfig,
  object,
  viewconfig,
  context,
  options,
) {
  console.log("Force fullSync synchronisation for object: ", object);
  if (!isEmpty(object)) {
    var latestSync = context.getters.getLastSynchronisationForObjectType(
      object.objectType,
    );
    if (!isEmpty(latestSync)) {
      Vue.set(latestSync, "mode", "reset");
      Vue.set(latestSync, "createTime", 0);
      console.log("Force fullSync synchronisation for ", object.objectType);
      //We also have to reset the sync group.
      await context.dispatch("clearSyncDateForSyncGroup", object.syncGroup);
      if (isEmpty(options) || options.disableMessages !== true) {
        context.commit("showAlert", {
          type: ALERT_TYPE_INFO,
          message:
            "Sync Reset for object. Launch Synchronisation to receive data",
        });
      }
    }
  }
}

export async function checkDataBaseCorrect(
  objectConfig,
  object,
  viewconfig,
  context,
  options,
) {
  var database = context.getters.getDatabase;
  await context.dispatch("renewObjectConfigs");
  var tableInfos = await objectConfig.getDataBaseInfo(database);
  var objectConfigs =
    context.getters.getDataObjectsForObjectType(OBJECT_CONFIG);
  for (var i = 0; i < objectConfigs.length; i++) {
    var objectConfigObject = objectConfigs[i];
    var newObjectConfig = context.getters.getObjectConfigForType(
      objectConfigObject.objectType,
    );
    if (!isTableCorrect(newObjectConfig, tableInfos)) {
      console.log(
        "Table not correct: ",
        objectConfig.objectType,
        " updating...",
      );
      //copy the objects
      var backedUppedObjects = await newObjectConfig.findAll(database);
      var backedUppedHistoryObjects =
        await newObjectConfig.findAllHistory(database);
      await deleteAndRecreateTableForObject(
        newObjectConfig,
        objectConfigObject,
        null,
        context,
        { disableMessages: true },
      );
      await newObjectConfig.saveAll(database, backedUppedObjects);
      await newObjectConfig.saveAllHistory(database, backedUppedHistoryObjects);
      await forceSynchronizeAllObject(
        newObjectConfig,
        objectConfigObject,
        null,
        context,
        options,
        { disableMessages: true },
      );
    } else {
      console.log("Table is correct: ", newObjectConfig.objectType);
    }
  }
}

export async function createAuthorisationForCRM(
  objectConfig,
  object,
  viewconfig,
  context,
  options,
) {
  // Check if the fields already exists.
  console.log("Launch Action: createAuthorisationForCRMObject");
  var authorisations =
    context.getters.getDataObjectsForObjectType(AUTHORISATION);
  var authorisation = authorisations.find(function (o) {
    return o.objectType === object.objectType;
  });
  if (!isEmpty(authorisation)) {
    context.commit("showAlert", {
      type: ALERT_TYPE_ERROR,
      message: "Authorisation already exist",
    });
    return;
  }
  var authorisationConfig =
    context.getters.getObjectConfigForType(AUTHORISATION);

  //Create Sync Id field
  var authorisationAll = ObjectApi.newObjectWithGuid();
  authorisationConfig.afterCreate(authorisationAll, object, context, {
    objectType: OBJECT_CONFIG,
  });
  Vue.set(authorisationAll, "objectType", object.objectType);
  Vue.set(authorisationAll, "object", "All");
  Vue.set(authorisationAll, "domain", "Sales");

  var payloadAll = {
    objectType: AUTHORISATION,
    object: authorisationAll,
  };
  await context.dispatch("saveObjectForObjectType", payloadAll);

  var authorisationMy = ObjectApi.newObjectWithGuid();
  authorisationConfig.afterCreate(authorisationMy, object, context, {
    objectType: OBJECT_CONFIG,
  });
  Vue.set(authorisationMy, "objectType", object.objectType);
  Vue.set(authorisationMy, "object", "My");
  Vue.set(authorisationMy, "domain", "Sales");

  var payloadMy = {
    objectType: AUTHORISATION,
    object: authorisationMy,
  };
  await context.dispatch("saveObjectForObjectType", payloadMy);

  var authorisationMyAccount = ObjectApi.newObjectWithGuid();
  authorisationConfig.afterCreate(authorisationMyAccount, object, context, {
    objectType: OBJECT_CONFIG,
  });
  Vue.set(authorisationMyAccount, "objectType", object.objectType);
  Vue.set(authorisationMyAccount, "object", "MyAccount");
  Vue.set(authorisationMyAccount, "domain", "Sales");

  var payloadMy = {
    objectType: AUTHORISATION,
    object: authorisationMy,
  };
  await context.dispatch("saveObjectForObjectType", payloadMy);
  context.commit("showAlert", {
    type: ALERT_TYPE_INFO,
    message: "Authorisation created",
  });
}

/***
 * Export to CSV
 */
export const exportCSV = (
  objectConfig,
  object,
  viewconfig,
  context,
  options,
) => {
  console.log("exportCSV: ", object);
  var allObjects = context.getters.getDataObjectsForObjectType(
    object.objectType,
  );
  var selectedObjectConfig = context.getters.getObjectConfigForType(
    object.objectType,
  );
  var fields = selectedObjectConfig.fieldsConfig;
  var rows = [];

  var headers = fields.reduce((arr, field) => {
    if (!isEmpty(field.field)) arr.push(field.field);
    return arr;
  }, []);
  console.log(headers);
  headers = headers.sort();
  console.log(headers);
  rows.push(headers);

  for (let index = 0; index < allObjects.length; index++) {
    const element = allObjects[index];
    var row = [];
    for (let i = 0; i < headers.length; i++) {
      const header = headers[i];
      row.push(!isEmpty(element[header]) ? element[header] : "");
    }
    rows.push(row);
  }
  console.log(rows);

  let csvContent =
    "data:text/csv;charset=utf-8," + rows.map((e) => e.join(";")).join("\n");

  var encodedUri = encodeURI(csvContent);
  var link = document.createElement("a");
  link.setAttribute("href", encodedUri);
  link.setAttribute(
    "download",
    `${object.objectType}-${moment().format("YYYY-MM-DD_HH-mm")}.csv`,
  );
  document.body.appendChild(link); // Required for FF

  link.click();
};

/***
 * Copy object and Fields
 */
export const copyObjectConfig = (
  objectConfig,
  object,
  viewconfig,
  context,
  options,
) => {
  var newObject = structuredClone(object);
  var newObjectType = newObject.objectType + "Copy";
  newObjectType = "AgileLink";
  newObject.Guid = getGuid();
  newObject.objectType = newObjectType;
  newObject.name = newObjectType;
  delete newObject._id;

  context.dispatch("saveObjectForObjectType", {
    object: newObject,
    objectType: OBJECT_CONFIG,
  });
  // Create the operation object
  var selectedObjectConfig = context.getters.getObjectConfigForType(
    object.objectType,
  );
  var fields = selectedObjectConfig.fieldsConfig;
  for (var i = 0; i < fields.length; i++) {
    var field = fields[i];
    var newField = structuredClone(field);
    newField.Guid = getGuid();
    delete newField._id;
    newField.objectType = newObjectType;
    context.dispatch("saveObjectForObjectType", {
      object: newField,
      objectType: FIELD_CONFIG,
    });
  }
};
