import * as Constants from "./constants.js";
import * as syncApi from "../api/synchronizationApi.js";
import * as Utilities from "../api/utilities";
import * as objectApi from "../api/objectApi";
import { API_BASE_URL } from "../api/constants.js";
import axios from "axios";
import { getAccessToken } from "./authorizationContext.js";
import { mustSyncSyncGroup } from "./synchronisationUtlities.js";
import { SAVE_TYPE_LOCAL } from "./operationContext.js";

import Vue from "vue";
import moment from "moment";

/**
 * Constants
 * */
export const CREATE_TIME = "Created";
export const SEND_TIME = "Sent";
export const RESPONSE_TIME = "Responded";
export const PARSING_TIME = "Parsed";
export const COMPLETION_TIME = "Completed";
export const FAILED_TIME = "Failed";

/**
 * Sync Mutations
 */
export const SET_SYNC_STATE = "setSyncState";
export const SYNC_TYPE_DELTA = "delta";
export const SYNC_TYPE_FULL = "full";

/**
 * Sync Group
 */
export const SYNC_GROUP_DEFAULT = "SYNC_GROUP_DEFAULT";
export const SYNC_GROUP = "SyncGroup";

/**
 * Actions
 */
export const _SYNCHRONIZE_FIELDS = "_synchronizeFields";
export const _SYNCHRONIZE_OBJECTS = "_synchronizeObjects";
export const _UPSERT_SYNCHRONISATION = "_upsertSynchronisation";
export const _UPDATE_SYNCHRONISATION_TIMING = "_updateSynchronisationTiming";
/**
 * App State
 */
export const SYNC_STATE_INIT = 0;
export const SYNC_STATE_STARTING = 1;
export const SYNC_STATE_SYNCING = 2;
export const SYNC_STATE_PARSING = 3;
export const SYNC_STATE_COMPLETED = 4;

export const AUTO_SYNC = "sw_as";
export const BULK_UPDATE = "sw_bulk";

export default {
  state: {
    synchronisationState: SYNC_STATE_INIT,
    syncProgress: 0,
    nbSaving: 0,
    autoSyncTimer: null,
    autoSync:
      window && window.localStorage.getItem(AUTO_SYNC)
        ? JSON.parse(window.localStorage.getItem(AUTO_SYNC))
        : true,
    isUpdating: false,
    bulkUpdate:
      window && window.localStorage.getItem(BULK_UPDATE)
        ? JSON.parse(window.localStorage.getItem(BULK_UPDATE))
        : true,
  },
  mutations: {
    setSynchronisationState(state, syncState) {
      state.synchronisationState = syncState;
    },
    setSyncProgress(state, syncProgress) {
      state.syncProgress = syncProgress;
    },
    setNBSaving(state, nbSaving) {
      state.nbSaving = nbSaving;
    },
    setAutoSyncTimer(state, timer) {
      clearTimeout(state.autoSyncTimer);
      state.autoSyncTimer = timer;
    },
    setAutoSync(state, value) {
      window.localStorage.setItem(AUTO_SYNC, value);
      state.autoSync = value;
    },
    setBulkUpdate(state, value) {
      window.localStorage.setItem(BULK_UPDATE, value);
      state.bulkUpdate = value;
    },
    setIsUpdating(state, value) {
      state.isUpdating = value;
    },
  },

  getters: {
    getSyncProgress(state) {
      return state.syncProgress;
    },
    getIsUpdating(state) {
      return state.isUpdating;
    },
    isSynchronizing(state) {
      return (
        state.synchronisationState !== SYNC_STATE_INIT &&
        state.synchronisationState !== SYNC_STATE_COMPLETED
      );
    },
    getSynchronisationState(state) {
      return state.synchronisationState;
    },
    getNbSaving(state) {
      return state.nbSaving;
    },
    getAutoSyncTimer(state) {
      return state.autoSyncTimer;
    },
    getAutoSync(state) {
      return state.autoSync;
    },
    getBulkUpdate(state) {
      return state.bulkUpdate;
    },
    /**
     * Retrieves all synchronisation
     */
    getSynchronisations(state, getters) {
      return getters.getDataObjectsForObjectType(Constants.SYNCHRONISATION);
    },
    /**
     * Retrieves sychronisation for objectType.
     */
    getSynchronisationsForObjectType: (state, getters) => (objectType) => {
      var synchronisations = getters.getSynchronisations;
      if (synchronisations === undefined) {
        return [];
      }
      return synchronisations.filter(function (o) {
        return o.objectType === objectType && o.status === COMPLETION_TIME;
      });
    },
    /**
     * Retrieves the last synchronisation for objectType
     */
    getLastSynchronisationForObjectType: (state, getters) => (objectType) => {
      return Utilities.last(
        getters.getSynchronisationsForObjectType(objectType),
      );
    },

    getSyncGroupForGroup: (state, getters) => (syncGroup) => {
      var syncGroups = getters.getSyncGroups;
      return syncGroups.find(function (o) {
        return o.syncGroup === syncGroup;
      });
    },

    getSyncGroups: (state, getters) => {
      var syncGroups = getters.getDataObjectsForObjectType(SYNC_GROUP);
      return syncGroups.sort(function (a, b) {
        return a.position - b.position;
      });
    },
    /**
     * Returns a new synchronisation object for the
     * payload.objectType: string
     * payload.parent:  {}
     * }
     */
    getNewSynchronisationObject: (state, getters) => (payload) => {
      var synchronisation = objectApi.newObject();
      synchronisation.Guid = Utilities.getGuid();
      synchronisation.createTime = Date.now();

      if (payload.parent !== null) {
        synchronisation.parent = payload.parent.syncId;
        if (payload.parent.mode === SYNC_TYPE_DELTA) {
          // here check if the object can be synchronised with delta.
          lastSynchro = getters.getLastSynchronisationForObjectType(
            payload.objectType,
          );
          if (lastSynchro !== undefined && lastSynchro !== null) {
            synchronisation.lastSyncTime = lastSynchro.createTime;
          }
        } else {
        }
        synchronisation.mode = SYNC_TYPE_DELTA;
        synchronisation.objectType = payload.objectType;
      }

      return synchronisation;
    },
  },
  actions: {
    /**
     * This action will synchronize all Data. Normally 2 possibilities supported. Full and delta.
     * Still to be implemented.
     * Fields must be synchronised before the objects.
     * @param {*} param0
     */
    async synchronizeAllDataInitial({ dispatch, commit, getters }, syncType) {
      //TODO. Get the following Synchronisations based on the next synchronisation.
      commit("setSynchronisationState", SYNC_STATE_STARTING);
      var parent = getters.getNewSynchronisationObject({
        objectType: SYNC_GROUP_DEFAULT,
        parent: null,
      });
      var synchronisationGroup = {
        syncGroup: Constants.SYNC_GROUP_CORE_FIELDS,
        await: true,
      };
      await dispatch("_synchronizeGroup", { parent, synchronisationGroup });
      await dispatch("_afterSynchronizeGroup", {
        parent,
        synchronisationGroup,
        isInitial: true,
      });

      var synchronisationGroup = {
        syncGroup: Constants.SYNC_GROUP_CORE_OBJECTS,
        await: true,
      };
      await dispatch("_synchronizeGroup", { parent, synchronisationGroup });
      await dispatch("_afterSynchronizeGroup", {
        parent,
        synchronisationGroup,
        isInitial: true,
      });

      var synchronisationGroup = {
        syncGroup: Constants.SYNC_GROUP_CORE_SYNC_GROUP,
        await: true,
      };
      await dispatch("_synchronizeGroup", { parent, synchronisationGroup });
      await dispatch("_afterSynchronizeGroup", {
        parent,
        synchronisationGroup,
        isInitial: true,
      });

      await dispatch("synchronizeAllData", parent);
    },
    async toggleAutoSync({ dispatch, commit, getters }, time) {
      const newValue = !getters.getAutoSync;
      commit("setAutoSync", newValue);
      if (!Utilities.isTrue(newValue)) {
        commit("setAutoSyncTimer", null);
      } else {
        dispatch("resetAutoSync");
      }
    },
    async resetAutoSync({ dispatch, commit, getters }, time) {
      let timer = null;
      if (Utilities.isTrue(getters.getAutoSync)) {
        timer = setTimeout(() => {
          dispatch("synchronizeAllData");
        }, time || 60000);
      }
      console.log("setAutoSyncTimer", time);
      commit("setAutoSyncTimer", timer);
    },
    async toggleBulkUpdate({ commit, getters }) {
      const newValue = !getters.getBulkUpdate;
      commit("setBulkUpdate", newValue);
    },
    /**
     * This action will synchronize all Data. Normally 2 possibilities supported. Full and delta.
     * Still to be implemented.
     * Fields must be synchronised before the objects.
     * @param {*} param0
     */
    async synchronizeAllData({ dispatch, commit, getters }, syncType) {
      //TODO. Get the following Synchronisations based on the next synchronisation.
      console.log("SynchorniseAllData");

      dispatch("resetAutoSync");
      if (getters.getIsUpdating) {
        console.log("Is already synchronising");
        return;
      }
      commit("setSynchronisationState", SYNC_STATE_STARTING);
      commit("setIsUpdating", true);
      if (Utilities.isTrue(getters.getBulkUpdate)) {
        await dispatch("sendAllOperations");
      } else {
        await dispatch("sendAllOperationsSingle");
      }

      commit("setIsUpdating", false);

      var parent = getters.getNewSynchronisationObject({
        objectType: SYNC_GROUP_DEFAULT,
        parent: null,
      });
      //First we Sync the SyncGroup
      var synchronisationGroup = getters.getSyncGroups.find(function (o) {
        return o.syncGroup === Constants.SYNC_GROUP_CORE_SYNC_GROUP;
      });
      synchronisationGroup.await = true;
      await dispatch("_synchronizeGroup", { parent, synchronisationGroup });
      await dispatch("_afterSynchronizeGroup", {
        parent,
        synchronisationGroup,
      });

      var synchronisationGroups = getters.getSyncGroups.filter(function (o) {
        return o.syncGroup !== Constants.SYNC_GROUP_CORE_SYNC_GROUP;
      });

      // set daily full delta sync
      var needsClearing = false;
      const lastClearingDate = getters.getlastClearingDate;
      if (
        Utilities.isEmpty(lastClearingDate) ||
        moment.unix(lastClearingDate).isBefore(moment(), "d")
      ) {
        // has not been full delta synced today
        needsClearing = true;
        for (let index = 0; index < synchronisationGroups.length; index++) {
          const group = synchronisationGroups[index];
          group.lastSync = 0;
        }
      }

      for (var i = 0; i < synchronisationGroups.length; i++) {
        var synchronisationGroup = synchronisationGroups[i];
        if (mustSyncSyncGroup(getters, synchronisationGroup)) {
          await dispatch("_synchronizeGroup", { parent, synchronisationGroup });
          await dispatch("_afterSynchronizeGroup", {
            parent,
            synchronisationGroup,
          });
        }
      }
      commit("setSynchronisationState", SYNC_STATE_COMPLETED);
      if (needsClearing) {
        dispatch("clearUnnecessaryItems");
      }
    },

    async _synchronizeGroup({ dispatch, commit, getters }, payload) {
      var synchronisationGroup = payload.synchronisationGroup;
      var parent = payload.parent;
      var configsOfGroup = getters.getObjectConfigs.filter(function (o) {
        return o.objectConfig.syncGroup === synchronisationGroup.syncGroup;
      });
      var payloadCore = {
        configs: configsOfGroup,
        parent: parent,
        synchronisationGroup: payload.synchronisationGroup,
      };
      await dispatch("_synchronizeOperationsAndData", payloadCore);
    },

    async _afterSynchronizeGroup({ dispatch, commit, getters }, payload) {
      var synchronisationGroup = payload.synchronisationGroup;

      if (
        synchronisationGroup.syncGroup === Constants.SYNC_GROUP_CORE_OBJECTS
      ) {
        await dispatch(Constants.CREATE_DATA_TABLES);

        if (!Utilities.isTrue(payload.isInitial)) {
          var objectConfig = getters.getObjectConfigForType(
            Constants.OBJECT_CONFIG,
          );
          var payloadCheckTables = {
            action: "checkDataBaseCorrect",
            objectConfig: objectConfig,
            object: null,
            viewConfig: null,
            context: { dispatch, commit, getters },
          };
          // TODO remove maybe if not needed
          await dispatch("launchActionForObjectAndConfig", payloadCheckTables);
        }
      }

      if (!Utilities.isTrue(payload.isInitial)) {
        Vue.set(synchronisationGroup, "lastSync", payload.parent.createTime);
        await dispatch("saveObjectForObjectType", {
          objectType: SYNC_GROUP,
          object: synchronisationGroup,
          saveType: SAVE_TYPE_LOCAL,
        });
      }
    },

    async clearSyncDateForSyncGroup({ dispatch, commit, getters }, syncGroup) {
      var synchronisationGroup = getters.getSyncGroups.find(function (o) {
        return o.syncGroup === syncGroup;
      });
      if (!Utilities.isEmpty(synchronisationGroup)) {
        Vue.set(synchronisationGroup, "lastSync", "");
        await dispatch("saveObjectForObjectType", {
          objectType: SYNC_GROUP,
          object: synchronisationGroup,
          saveType: SAVE_TYPE_LOCAL,
        });
      }
    },

    /**
     * Send Operations and Data
     * @param {*} param0
     * @param {*} payload
     */
    async _synchronizeOperationsAndData(
      { dispatch, commit, getters },
      payload,
    ) {
      // not needed when mass update
      // if (payload.synchronisationGroup.sendOperations === true) {
      //   await dispatch("sendOperations");
      // }
      await dispatch("synchronizeDataForConfigs", payload);
      // if (payload.synchronisationGroup.await === true) {
      //   await dispatch("synchronizeDataForConfigs", payload);
      // } else {
      //   dispatch("synchronizeDataForConfigs", payload);
      // }
    },

    /**
     * Send the data for the Config
     * @param {} param0
     * @param {*} payload
     */
    async synchronizeDataForConfigs({ dispatch, commit, getters }, payload) {
      var configs = payload.configs;
      var timing = 100;
      commit(Constants.SET_SYNC_PROGRESS, 0);
      commit("setNBSaving", 0);
      var delta = 100;
      var parent = payload.parent;

      var synchronisations = {};
      var isFull = true;
      for (var i = 0, l = configs.length; i < l; i++) {
        var objectConfig = configs[i];
        if (objectConfig.fieldsConfig.length < 1) {
          continue;
        }
        var payloadNewSynchro = {
          objectType: objectConfig.objectType,
          parent: parent,
        };

        // Create Synchronisation.
        var synchronisation =
          getters.getNewSynchronisationObject(payloadNewSynchro);
        dispatch(_UPDATE_SYNCHRONISATION_TIMING, {
          synchronisation: synchronisation,
          timingType: CREATE_TIME,
        });
        if (synchronisation) {
          // synchronisation.lastSync = getters.getLastSynchronisationForObjectType(
          //   objectType.objectType
          // );

          const latestSync = getters.getLastSynchronisationForObjectType(
            objectConfig.objectType,
          );
          console.log("latestSync", objectConfig.objectType, latestSync);

          // TO DO put back this commented code for bulk
          if (
            Utilities.isTrue(getters.getBulkUpdate) &&
            !Utilities.isEmpty(latestSync) &&
            latestSync.createTime &&
            latestSync.createTime > 0
          ) {
            isFull = false;
          }

          synchronisations[objectConfig.objectType] = {
            objectConfig,
            synchronisation,
            latestSync,
          };
          // }
        }
      }
      if (isFull) {
        if (payload.synchronisationGroup.await === true) {
          const objectTypes = Object.keys(synchronisations);
          for (let index = 0, l = objectTypes.length; index < l; index++) {
            const item = objectTypes[index];
            await dispatch("fetchObjects", {
              synchronisationGroup: payload.synchronisationGroup,
              synchronisations: {
                [item]: synchronisations[item],
              },
            });
            const progress = getters.getSyncProgress + 100 / l;
            commit(Constants.SET_SYNC_PROGRESS, progress);
          }
        } else {
          const promises = [];

          // Loop over each item in the array
          Object.keys(synchronisations).forEach((item) => {
            // Create a new Promise for each API call
            const promise = new Promise(async (resolve, reject) => {
              // Perform the API call asynchronously
              // Replace this code with your own API call logic
              await dispatch("fetchObjects", {
                synchronisationGroup: payload.synchronisationGroup,
                synchronisations: {
                  [item]: synchronisations[item],
                },
              });
              const progress = getters.getSyncProgress + 100 / l;
              commit(Constants.SET_SYNC_PROGRESS, progress);
              delete synchronisations[item];
              console.log("synchronisations", synchronisations);
              resolve(); // Random delay for simulation purposes
            });

            // Add the promise to the promises array
            promises.push(promise);
          });

          await Promise.all(promises);
          console.log("allPromises done");
        }
      } else {
        await dispatch("fetchObjects", {
          synchronisationGroup: payload.synchronisationGroup,
          synchronisations,
        });
        commit(Constants.SET_SYNC_PROGRESS, 100);
      }

      // var newValue = getters.getSyncProgress + payloadFetch.delta;
    },

    /**
     * Synchronisation Object
     * @param {} param0
     * @param {*} synchronisation
     */
    _upsertSynchronisation({ dispatch, commit, getters }, synchronisation) {
      var payload = {
        objectConfig: getters.getObjectConfigForType(Constants.SYNCHRONISATION),
        object: synchronisation,
      };
      commit(Constants.SET_OBJECT, { payload, getters });
    },

    /**
     * Update the synchronisation timing.
     * @param {*} param0
     * @param {*} payload
     */
    _updateSynchronisationTiming({ dispatch, commit, getters }, payload) {
      var synchronisation = payload.synchronisation;
      var timingType = payload.timingType;
      synchronisation.status = timingType;
      if (timingType === CREATE_TIME) {
        synchronisation.status = CREATE_TIME;
        synchronisation.createTime = Date.now();
      }
      if (timingType === SEND_TIME) {
        synchronisation.status = SEND_TIME;
        synchronisation.sendTime = Date.now() - synchronisation.createTime;
      }
      if (timingType === RESPONSE_TIME) {
        synchronisation.status = RESPONSE_TIME;
        synchronisation.responseTime =
          Date.now() - synchronisation.createTime - synchronisation.sendTime;
      }
      if (timingType === PARSING_TIME) {
        synchronisation.status = PARSING_TIME;
        synchronisation.parsingTime =
          Date.now() -
          synchronisation.createTime -
          synchronisation.sendTime -
          synchronisation.responseTime;
      }
      if (timingType === COMPLETION_TIME || timingType === FAILED_TIME) {
        synchronisation.status = timingType;
        synchronisation.savingTime =
          Date.now() -
          synchronisation.createTime -
          synchronisation.sendTime -
          synchronisation.responseTime -
          synchronisation.parsingTime;
        synchronisation.completionTime =
          synchronisation.sendTime +
          synchronisation.responseTime +
          synchronisation.savingTime +
          synchronisation.parsingTime;
        var payload = {
          objectConfig: getters.getObjectConfigForType(
            Constants.SYNCHRONISATION,
          ),
          object: synchronisation,
        };
        console.log(
          "UPDATE_SYNCHRONISATION_TIMING",
          synchronisation.objectConfig
            ? synchronisation.objectConfig.objectType
            : synchronisation,
          synchronisation,
        );
        dispatch(Constants.SAVE_OBJECT, payload);
      }
      dispatch(_UPSERT_SYNCHRONISATION, synchronisation);
    },

    // restConfig
    /**
     * Fetches the synchronisation: could be set in an api.
     * @param {} param0
     */
    async fetchObjects({ dispatch, commit, getters }, payload) {
      return new Promise(async (resolve, reject) => {
        var { synchronisationGroup, synchronisations } = payload;
        const syncGroup =
          synchronisationGroup.name || synchronisationGroup.syncGroup;
        var restApi = syncApi.buildRestGet(syncGroup, synchronisations);
        var bearer = await getAccessToken(getters.getCurrentUser);

        if (Utilities.isEmpty(bearer)) {
          console.log("problem with bearer.");
          commit("showAlert", {
            type: Constants.ALERT_TYPE_ERROR,
            message:
              "Bearer token missing. Try logging out and in again and retry",
          });
          reject("problem with bearer");
        }

        restApi.config.headers.Authorization = "Bearer ".concat(bearer);

        const objectTypes = Object.keys(synchronisations);
        for (let index = 0, l = objectTypes.length; index < l; index++) {
          const objectType = objectTypes[index];
          await dispatch(_UPDATE_SYNCHRONISATION_TIMING, {
            synchronisation: synchronisations[objectType].synchronisation,
            timingType: SEND_TIME,
          });
        }

        axios[restApi.method](restApi.url, restApi.config)
          .then(async (response) => {
            console.log("response", response);
            console.log("Saving objects: response", response.data);
            const data = response.data;
            var store = { dispatch, commit, getters };
            if (!Utilities.isEmptyArray(data)) {
              var objectsToSave = data.length;
              for (let index = 0, l = data.length; index < l; index++) {
                const element = data[index];
                var synchronisationWrap = synchronisations[element.objectType];
                var synchronisation = synchronisationWrap.synchronisation;
                const objectConfig = synchronisationWrap.objectConfig;
                var objects = syncApi.parseJSON(
                  element.data,
                  objectConfig,
                  store,
                  syncGroup,
                );
                var payload = {
                  objectConfig: objectConfig,
                  objects: objects,
                };
                synchronisation.numberOfObjects = objects.length;
                console.log("Saving objects", synchronisation.numberOfObjects);
                await dispatch(Constants.UPDATE_DATA_STORE, payload);
                await dispatch(_UPDATE_SYNCHRONISATION_TIMING, {
                  synchronisation: synchronisation,
                  timingType: PARSING_TIME,
                });
                var newValue = getters.getAppLoginStatus + 0.5;
                await commit("setAppLoginStatus", newValue);
                objectConfig
                  .saveAll(getters.getDatabase, objects, synchronisation)
                  .then(
                    (sync) => {
                      dispatch(_UPDATE_SYNCHRONISATION_TIMING, {
                        synchronisation: sync,
                        timingType: COMPLETION_TIME,
                      });

                      var newValue = getters.getAppLoginStatus + 0.5;
                      commit("setAppLoginStatus", newValue);
                      objectsToSave--;

                      if (objectsToSave <= 0) {
                        resolve();
                      }
                    },
                    (error) => {
                      // If error we should save the synchronisation state.
                      // dispatch(_UPDATE_SYNCHRONISATION_TIMING, {
                      //   synchronisation: synchronisation,
                      //   timingType: RESPONSE_TIME
                      // });
                      // dispatch(_UPDATE_SYNCHRONISATION_TIMING, {
                      //   synchronisation: synchronisation,
                      //   timingType: PARSING_TIME
                      // });
                      dispatch(_UPDATE_SYNCHRONISATION_TIMING, {
                        synchronisation: synchronisation,
                        timingType: FAILED_TIME,
                      });
                      console.log("Error saving objects during Sync: ", error);
                      Utilities.findSavingError(
                        objects,
                        objectConfig,
                        getters.getDatabase,
                      );
                      objectsToSave--;
                      if (objectsToSave <= 0) {
                        resolve();
                      }
                    },
                  );
              }
            } else {
              resolve();
            }
          })
          .catch(function (error) {
            for (let index = 0, l = objectTypes.length; index < l; index++) {
              const objectType = objectTypes[index];
              dispatch(_UPDATE_SYNCHRONISATION_TIMING, {
                synchronisation: synchronisations[objectType].synchronisation,
                timingType: RESPONSE_TIME,
              });
              dispatch(_UPDATE_SYNCHRONISATION_TIMING, {
                synchronisation: synchronisations[objectType].synchronisation,
                timingType: PARSING_TIME,
              });
              dispatch(_UPDATE_SYNCHRONISATION_TIMING, {
                synchronisation: synchronisations[objectType].synchronisation,
                timingType: FAILED_TIME,
              });
            }

            // handle error
            console.log(error);
            reject();
          });
      });
    },
    // restConfig
    /**
     * Fetches the synchronisation: could be set in an api.
     * @param {} param0
     */
    fetchObjectsForFilter({ dispatch, commit, getters }, payload) {
      return new Promise(async (resolve, reject) => {
        var { objectType, filterGuid, relatedGuid, relatedType, force } =
          payload;
        var config = {
          headers: {},
        };
        var bearer = await getAccessToken(getters.getCurrentUser);

        if (Utilities.isEmpty(bearer)) {
          var error = "problem with bearer During Operation.";
          commit("showAlert", {
            type: Constants.ALERT_TYPE_ERROR,
            message: error,
          });
          return;
        }
        config.headers.Authorization = "Bearer ".concat(bearer);
        console.log("URL", `${API_BASE_URL}crm`);
        const url = new URL(`${API_BASE_URL}crm`);
        url.searchParams.set("objectType", objectType);
        url.searchParams.set("filterGuid", filterGuid);
        url.searchParams.set("relatedGuid", relatedGuid);
        url.searchParams.set("relatedType", relatedType);
        if (Utilities.isTrue(force)) {
          url.searchParams.set("force", force);
        }

        axios.get(url, config).then(
          (response) => {
            resolve(response.data);
          },
          (error) => {
            this.loading = false;
            // commit("showAlert", {
            //   type: Constants.ALERT_TYPE_ERROR,
            //   message: error,
            // });
            reject(error);
          },
        );
      });
    },
    /**
     * Fetches the synchronisation: could be set in an api.
     * @param {} param0
     */
    async _fetchObjects({ dispatch, commit, getters }, payload) {
      var synchronisation = payload.synchronisation;
      var objectConfig = payload.objectConfig;

      if (
        !Utilities.isEmpty(payload.latestSync) &&
        !Utilities.isEmpty(payload.latestSync.createTime) &&
        payload.latestSync.mode !== "reset"
      ) {
        synchronisation.mode = "delta";
      } else {
        synchronisation.mode = "full";
      }
      console.log(
        "Get last sync",
        payload.objectConfig.objectType,
        "and mode ",
        synchronisation.mode,
      );
      var restConfig = syncApi.buildRestGet(objectConfig, payload.latestSync);

      var config = {
        headers: restConfig.headers,
      };

      var bearer = await getAccessToken(getters.getCurrentUser);
      // console.log("access token", bearer);

      if (Utilities.isEmpty(bearer)) {
        console.log("problem with bearer.");
        await dispatch(_UPDATE_SYNCHRONISATION_TIMING, {
          synchronisation: synchronisation,
          timingType: RESPONSE_TIME,
        });
        await dispatch(_UPDATE_SYNCHRONISATION_TIMING, {
          synchronisation: synchronisation,
          timingType: PARSING_TIME,
        });
        await dispatch(_UPDATE_SYNCHRONISATION_TIMING, {
          synchronisation: synchronisation,
          timingType: FAILED_TIME,
        });
      }

      config.headers.Authorization = "Bearer ".concat(bearer);

      await dispatch(_UPDATE_SYNCHRONISATION_TIMING, {
        synchronisation: synchronisation,
        timingType: SEND_TIME,
      });

      //Beter om dit in een api te zetten.
      await axios
        .get(restConfig.url, config)
        .then(async function (response) {
          // flattening the response
          await dispatch(_UPDATE_SYNCHRONISATION_TIMING, {
            synchronisation: synchronisation,
            timingType: RESPONSE_TIME,
          });
          console.log("Parsing objects");
          var store = { dispatch, commit, getters };
          var objects = syncApi.parseJSON(response.data, objectConfig, store);
          var payload = {
            objectConfig: objectConfig,
            objects: objects,
          };
          synchronisation.numberOfObjects = objects.length;
          console.log("Saving objects", synchronisation.numberOfObjects);
          await dispatch(Constants.UPDATE_DATA_STORE, payload);
          await dispatch(_UPDATE_SYNCHRONISATION_TIMING, {
            synchronisation: synchronisation,
            timingType: PARSING_TIME,
          });
          // Update the objects in the application.
          var newValue = getters.getAppLoginStatus + 1;
          commit("setAppLoginStatus", newValue);
          if (response && response.data && response.data.warning) {
            commit("showAlert", {
              type: "warning",
              message: response.data.message,
            });
          }
          await objectConfig.saveAll(getters.getDatabase, objects).then(
            (result) => {
              dispatch(_UPDATE_SYNCHRONISATION_TIMING, {
                synchronisation: synchronisation,
                timingType: COMPLETION_TIME,
              });
              var newValue = getters.getAppLoginStatus + 1;
              commit("setAppLoginStatus", newValue);
            },
            (error) => {
              // If error we should save the synchronisation state.
              dispatch(_UPDATE_SYNCHRONISATION_TIMING, {
                synchronisation: synchronisation,
                timingType: RESPONSE_TIME,
              });
              dispatch(_UPDATE_SYNCHRONISATION_TIMING, {
                synchronisation: synchronisation,
                timingType: PARSING_TIME,
              });
              dispatch(_UPDATE_SYNCHRONISATION_TIMING, {
                synchronisation: synchronisation,
                timingType: FAILED_TIME,
              });
              console.log("Error saving objects during Sync: ", error);
              Utilities.findSavingError(
                objects,
                objectConfig,
                getters.getDatabase,
              );
            },
          );
        })
        .catch(function (error) {
          dispatch(_UPDATE_SYNCHRONISATION_TIMING, {
            synchronisation: synchronisation,
            timingType: RESPONSE_TIME,
          });
          dispatch(_UPDATE_SYNCHRONISATION_TIMING, {
            synchronisation: synchronisation,
            timingType: PARSING_TIME,
          });
          dispatch(_UPDATE_SYNCHRONISATION_TIMING, {
            synchronisation: synchronisation,
            timingType: FAILED_TIME,
          });
          // handle error
          console.log(error);
        })
        .then(function () {
          // always executed
        }); // you can also make a chain.
    },
  },
};
