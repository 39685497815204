<template>
  <v-container
    fill-height
    fluid
    class="card"
  >
    <!-- <div class="splash-background px-2 px-md-10">
      <img
        :src="splash"
        width="100%"
        height="100%"
      >
    </div> -->
    <v-row
      style="height: 100%;"
      class="flex-nowrap"
      justify="center"
      align="center"
    >
      <v-col
        cols="12"
        md="4"
        style="height: 100%; "
      >
        <v-container
          class="card py-0"
          fill-height
          fluid
        >
          <v-row
            class="flex-column px-6 flex-nowrap"
            justify="center"
            align="center"
            style="height: 100%; "
          >



            <v-container class="d-flex align-center py-0 justify-center flex1">
              <v-row
                align="center"
                justify="center"
                class="flex-nowrap flex-column flex1"
              >
                <v-card
                  @click="openSelector"
                  elevation="0"
                  class="pa-3 px-4 rounded-lg card border1"
                >

                  <v-row
                    no-gutters
                    align="center"
                    justify="center"
                  >
                    <img
                      :src="getTenantIcon"
                      crossorigin="anonymous"
                      height="40px"
                      width="40px"
                      cover
                      class="rounded-lg"
                    />
                    <div class="pl-3 text-body-1 font-weight-mediumBold text-capitalize">{{ getTenant.name }}</div>


                  </v-row>

                </v-card>

                <v-bottom-sheet
                  v-model="sheet"
                  inset
                  content-class="rounded-t-xl pa-2"
                >

                  <v-list class="rounded-t-xl ">
                    <v-subheader class="pl-6">Switch tenant</v-subheader>
                    <v-list-item
                      v-for="item in tenants"
                      :key="item.Guid"
                      class="pl-6"
                      @click="selectTenant(item)"
                    >

                      <v-list-item-title>{{ item.name }}</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-bottom-sheet>

              </v-row>
            </v-container>

            <login
              v-if="view === 'login'"
              :on-reset="showReset"
            />
            <resetPassword
              v-if="view === 'reset'"
              :on-back="showLogin"
            />
            <SetPassword
              v-if="view === 'setPass'"
              :on-back="showLogin"
            />

          </v-row>



        </v-container>
      </v-col>
      <!-- <v-col
        cols="0"
        md="8"
        class="pr-8 "
      >

        <v-container
          fill-height
          fluid
          class="tileBackgroundColor rounded-xl"
          style="position: relative; overflow: hidden;"
        >
          <div class="splash-background px-2 px-md-10">
            <img
              :src="splash"
              width="100%"
              height="100%"
            >
          </div>
          <v-row
            align="center"
            justify="center"
            style="position: relative;"
          >
            <div class="started-img-wrap">
              <div class="started-img-text d-flex align-center">
                <v-card
                  class=" elevation-2 d-flex align-center justify-center mr-2"
                  :style="{ 'height': size, 'width': size, 'border-radius': '25%' }"
                  @click="openSelector"
                >
                  <img
                    v-if="!isEmpty(getTenant.logo)"
                    :style="{ 'height': size, 'width': size, 'border-radius': '25%' }"
                    :src="getTenant.logo"
                    crossorigin="anonymous"
                    aspect-ratio="1"
                  />
                  <div
                    v-else
                    style="{'font-size': size}"
                  >{{ getInitial }}</div>

                </v-card>
                <div class="black--text started-workspace">{{ isEmpty(getTenant.name) ? 'Workspace'
                  :
                  getTenant.name }}</div>
              </div>
              <img
                :src="startedImg"
                height="100%"
                width="auto"
                cover
                class="started-img rounded-xl v-sheet elevation-2"
              />
            </div>
          </v-row>
        </v-container>
      </v-col> -->
    </v-row>

  </v-container>
</template>

<script>
import { NODE_ENV, PRODUCTION, STAGING, TENANT } from '../../api/constants.js';
import { mapGetters, mapMutations } from "vuex";
import loadingView from "./LoadingView";
import loginBackground from "../mainComponent/LoginBackground";
require("vue2-animate/dist/vue2-animate.min.css");
import LogComponent from "./LogComponent.vue";
import login from "./Login.vue"
import resetPassword from './ResetPassword.vue'
import { isEmpty, isEmptyArray } from '../../api/utilities.js';
import splash from '../../assets/splash-background.svg'
import startedImg from "../../assets/swiftio-started.png"
import SetPassword from './SetPassword.vue';



export default {
  components: { loadingView, loginBackground, LogComponent, login, resetPassword, SetPassword },
  data() {
    return {
      splash,
      view: 'login',
      tenants: [],
      sheet: false,
      env: NODE_ENV,
      prodEnv: PRODUCTION,
      startedImg,
      size: '3vh',

    };
  },
  mounted() {

    if (this.$route.path === '/reset-password') {
      this.view = 'setPass'
    }
  },
  created() {
    this.fetchTenants()

  },
  computed: {
    ...mapGetters(['getTenant', 'getTenantIcon', 'isDev',]),
    getColorEnv() {
      if (this.env === STAGING) return 'warning'
      return 'success'
    },
  },
  methods: {
    ...mapMutations(['setTenant', 'switchTenant']),
    isEmpty,
    showReset() {
      this.view = 'reset'
    },
    showLogin() {
      this.view = 'login'
    },
    async fetchTenants() {
      this.tenants = await this.$store.dispatch('fetchTenants')
      if (!isEmptyArray(this.tenants)) {
        const activeTenant = this.tenants.find(item => item.handle === TENANT)
        if (activeTenant) {
          this.setTenant(activeTenant)
        }
      } else if (!isEmpty(this.tenants)) {
        this.setTenant(this.tenants)
      }
    },
    selectTenant(tenant) {
      this.switchTenant(tenant)
      this.sheet = false
    },
    openSelector() {
      if (this.isDev) {
        this.sheet = true
      }
    }
  }
};
</script>


<style scoped>
.splash-background {
  /* background-image: url('~@/assets/solar-bkg.jpg'); */
  left: 0;

  background-size: cover;
  position: absolute;
  width: 100%;
  height: 100%;
}

.started-img-wrap {
  height: 70vh;
  position: absolute;
  left: 20%;
}

.started-img-text {
  position: absolute;
  left: 0;
  top: 0;
  padding: 1.5vh;
}

.started-workspace {
  max-width: 9vh;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 1.5vh;

}

.started-img {
  max-height: 100%;
}</style>