<template>
    <v-dialog
        max-width="600"
        v-model="getComposeMailDialog"
        content-class="rounded-lg"
        persistent
    >
        <v-card
            class="pa-4"
            rounded="lg"
        >

            <v-card-title class="text-h5 d-flex justify-space-between align-center px-0">
                <div>
                    Compose mail
                </div>
                <v-btn
                    class="iconButton mx-1"
                    icon
                    @click.stop="close"
                >
                    <v-icon class="configLight">hi-x-mark-outline</v-icon>
                </v-btn>

            </v-card-title>
            <draft-mail
                v-if="getComposeMailDialog"
                :viewconfig="getComposeMailViewconfig"
                :object="draft"
                :parent="getComposeMailRelated"
            />


        </v-card>

    </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import { getGuid } from '../../api/utilities';
import DraftMail from '../DraftMail.vue';

export default {
    components: { DraftMail },
    data() {
        return {
            email: "",
            password: "",
            isImap: false,
            host: "",
            port: 993,
            hostOut: "",
            portOut: 465,
            showPassword: false,
            rules: {
                required: value => !!value || "Required.",
                min: v => v.length >= 8 || "Min 8 characters"
            },
            replyInbox: this.getMyInboxes && this.getMyInboxes[0] ? this.getMyInboxes[0] : {},
            draft: {
                Guid: getGuid(),
                ToEmail: '',
                Account: null,
                FromEmail: this.replyInbox ? this.replyInbox.Email : '',
                Subject: '',
                BodyHtml: `<div style="font-family: 'ui-sans-serif', 'system-ui', '-apple-system',
    'Roboto', sans-serif; font-size: 14px;"><div>`,
                Snippet: '',
                Source: "Swiftio",
                Inbox: this.replyInbox ? this.replyInbox.Guid : '',
                BodyText: '',
                Unread: false,
                Status: 'S001',
                Refs: ''
            }
        }
    },
    computed: {
        ...mapGetters(["getComposeMailDialog", "getMyInboxes", "getComposeMailRelated", "getComposeMailViewconfig"]),

    },

    methods: {

        close() {
            this.$store.commit('hideComposeMailDialog')
        }
    }
};
</script>

<style
    lang="css"
    scoped
></style>