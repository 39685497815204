/**
 * Attachment Context
 */

import ILovePDFApi from "@ilovepdf/ilovepdf-js";
import ILovePDFFile from "../extensions/ILovePdfFile";
import Axios from "axios";
import {
  getStorage,
  ref,
  getDownloadURL,
  uploadBytesResumable,
} from "firebase/storage";
import {
  compressImage,
  getGuid,
  isEmpty,
  isEmptyArray,
} from "../api/utilities";
import { newObjectWithGuid } from "../api/objectApi";
import { NODE_ENV, TENANT } from "../api/constants";

import { ATTACHMENT } from "./constants";
import { firebaseApp } from "../integrations/google/firebase";

const initState = {
  message: "",
  loading: false,
  attachments: [
    // {
    //   name: "screenshot3fdasdafdsf21309831.png",
    //   file: null,
    //   progress: 100,
    //   attachmentType: "image/png"
    // }
  ],
};

export default {
  state: initState,
  getters: {
    showAttachmentSnackbar: (state, getters) => {
      return state.attachments && state.attachments.length > 0;
    },
    getAttachments: (state, getters) => {
      return state.attachments;
    },
  },
  mutations: {
    addAttachment(state, attachment) {
      state.attachments.push(attachment);
    },
    removeAttachment(state, Guid) {
      var attachmentIndex = state.attachments
        .map((item) => item.Guid)
        .indexOf(Guid);
      if (attachmentIndex > -1) {
        state.attachments.splice(attachmentIndex, 1);
      }
    },
    updateProgress(state, payload) {
      var { Guid, progress } = payload;
      var attachmentIndex = state.attachments
        .map((item) => item.Guid)
        .indexOf(Guid);
      if (attachmentIndex > -1) {
        state.attachments[attachmentIndex].progress = progress;
      }
    },
    updateState(state, payload) {
      var { Guid, status } = payload;
      var attachmentIndex = state.attachments
        .map((item) => item.Guid)
        .indexOf(Guid);
      if (attachmentIndex > -1) {
        state.attachments[attachmentIndex].status = status;
      }
    },
  },
  actions: {
    uploadFiles(store, payload) {
      const { files, object, objectConfig, viewConfig, options } = payload;

      if (!isEmptyArray(files)) {
        // console.log("files", files);

        for (let index = 0, l = files.length; index < l; index++) {
          const file = files[index];
          var attachment = newObjectWithGuid();
          var relatedObject = {
            object,
            viewConfig,
          };
          attachment = objectConfig.afterCreate(
            attachment,
            relatedObject,
            store,
          );
          attachment.name = modifyFileName(file.name);
          attachment.attachmentType = file.type;
          if (options) {
            if (isEmpty(attachment.parent) && !isEmpty(options.parent)) {
              attachment.parent = options.parent;
            }
            if (
              isEmpty(attachment.parentType) &&
              !isEmpty(options.parentType)
            ) {
              attachment.parentType = options.parentType;
            }
            if (isEmpty(attachment.account) && !isEmpty(options.account)) {
              attachment.account = options.account;
            }
          }

          store.dispatch("upload", { attachment, file });
        }
      }
    },
    async upload({ commit, dispatch }, payload) {
      const { attachment, file } = payload;
      const { Guid, account, name, attachmentType } = attachment;

      let uploadAttachment = {
        Guid,
        name,
        file,
        attachmentType,
        thumbnail: null,
        progress: 0,
        status: "Processing",
      };
      commit("addAttachment", uploadAttachment);
      const gs =
        NODE_ENV === "production"
          ? `gs://${TENANT}.swiftio.io`
          : "gs://swiftio-io.appspot.com";
      const storage = getStorage(firebaseApp, gs);
      const storageRef = ref(storage, `${account}/${name}`);

      try {
        const uploadFile = await dispatch("getFile", { Guid, file, name });
        commit("updateState", { Guid, status: "Uploading" });
        // console.log("file", uploadFile);
        const metadata = {
          contentType: attachmentType,
        };
        const uploadTask = uploadBytesResumable(
          storageRef,
          uploadFile,
          metadata,
        );

        uploadTask.on(
          "state_changed",
          (snapshot) => {
            // Observe state change events such as progress, pause, and resume
            // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
            const progress =
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            commit("updateProgress", { Guid, progress });
          },
          (error) => {
            // Handle unsuccessful uploads
          },
          () => {
            // Handle successful uploads on complete
            // For instance, get the download URL: https://firebasestorage.googleapis.com/...

            commit("updateState", { Guid, status: "Saving" });
            commit("updateProgress", { Guid, progress: 100 });
            getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
              attachment.url = downloadURL;
              var payload = {
                objectType: ATTACHMENT,
                object: structuredClone(attachment),
              };
              console.log("payload", payload);
              dispatch("saveObjectForObjectType", payload);
              setTimeout(() => {
                commit("removeAttachment", Guid);
              }, 300);
            });
          },
        );
      } catch (error) {
        console.log(error);
        return null;
      }
    },
    async uploadSingle({ commit, dispatch }, payload) {
      const { file, Guid, folder } = payload;
      const fileName = modifyFileName(file.name);
      let uploadAttachment = {
        Guid,
        name: fileName,
        file,
        attachmentType: file.type,
        thumbnail: null,
        progress: 0,
        status: "Processing",
      };
      commit("addAttachment", uploadAttachment);
      const gs =
        NODE_ENV === "production"
          ? `gs://${TENANT}.swiftio.io`
          : "gs://swiftio-io.appspot.com";
      const storage = getStorage(firebaseApp, gs);
      const storageRef = ref(storage, `${folder}/${fileName}`);
      return new Promise(async (resolve, reject) => {
        try {
          const uploadFile = await dispatch("getFile", {
            Guid,
            file,
            fileName,
          });
          commit("updateState", { Guid, status: "Uploading" });
          // console.log("file", uploadFile);
          const metadata = {
            contentType: file.type,
          };
          const uploadTask = uploadBytesResumable(
            storageRef,
            uploadFile,
            metadata,
          );

          uploadTask.on(
            "state_changed",
            (snapshot) => {
              // Observe state change events such as progress, pause, and resume
              // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
              const progress =
                (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
              commit("updateProgress", { Guid, progress });
            },
            (error) => {
              // Handle unsuccessful uploads
            },
            () => {
              // Handle successful uploads on complete
              // For instance, get the download URL: https://firebasestorage.googleapis.com/...

              commit("updateState", { Guid, status: "Saving" });
              commit("updateProgress", { Guid, progress: 100 });
              getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                resolve(downloadURL);
                setTimeout(() => {
                  commit("removeAttachment", Guid);
                }, 300);
              });
            },
          );
        } catch (error) {
          console.log(error);
          reject();
        }
      });
    },
    async getFile({ commit }, { Guid, file, name }) {
      if (file) {
        if (file.type.includes("image")) {
          commit("updateState", { Guid, status: "Compressing" });
          const compressed = await compressImage(file);
          if (compressed) {
            return compressed;
          }
        } else if (file.type === "application/pdf") {
          if (file.size > 8000000) {
            commit("updateState", { Guid, status: "Compressing large pdf.." });
            var compressed = await compressPdf(file);
            if (compressed) {
              const compressedFile = new File([compressed], name);
              return compressedFile;
            }
          }
        }
      }
      return file;
    },
  },
};

const modifyFileName = (filename) => {
  // Extract the file extension
  const lastDotIndex = filename.lastIndexOf(".");
  const hasExtension = lastDotIndex !== -1;

  let namePart, extensionPart;
  if (hasExtension) {
    namePart = filename.substring(0, lastDotIndex);
    extensionPart = filename.substring(lastDotIndex);
  } else {
    namePart = filename;
    extensionPart = "";
  }

  // Generate 5 random characters
  const randomChars = Array.from({ length: 5 }, () =>
    String.fromCharCode(97 + Math.floor(Math.random() * 26)),
  ).join("");

  // Construct the new filename
  const modifiedFilename = namePart + "_" + randomChars + extensionPart;

  return modifiedFilename;
};

const compressPdf = async (file) => {
  try {
    const instance = new ILovePDFApi(
      "project_public_2d742737ac29f6ee4234abd705a1c1bf_7Stox03047a9826e4d3a14a6d4b6c30eff8dd",
    );
    let pdfTask = instance.newTask("compress");
    await pdfTask.start();
    new ILovePDFFile(file, pdfTask.id);
    var formData = new FormData();
    formData.append("task", pdfTask.id);
    formData.append("file", file);
    const config = {
      headers: {
        Authorization: "Bearer ".concat(pdfTask.auth.token),
        "Content-Type": "multipart/form-data",
      },
    };
    const url = `https://${pdfTask.server}/v1/upload`;
    const response = await Axios.post(url, formData, config);
    if (response && response.data) {
      pdfTask.files.push({
        serverFilename: response.data.server_filename,
        filename: file.name,
        params: {},
      });
      await pdfTask.process();
      const data = await pdfTask.download();
      return data;
    }
  } catch (error) {
    this.$store.commit("showAlert", {
      type: ALERT_TYPE_ERROR,
      message:
        "Failed to compress pdf. Please make sure you have internet connection. Contact Swiftio if this persists.",
    });
    return null;
  }
};
