<template>
    <v-container fluid>
        <v-row
            align="center"
            class="py-4"
        >
            <v-form
                ref="form"
                class="kingSize"
                v-model="valid"
            >
                <div
                    class="text-h4 font-weight-medium pb-6"
                    v-text="'Create account'"
                />
                <v-text-field
                    v-model="user.email"
                    :rules="[rules.required, rules.email]"
                    prepend-inner-icon="hi-envelope-outline"
                    name="email"
                    label="Enter work email"
                    class="text-field-transparent"
                    filled
                />
                <v-text-field
                    v-model="user.password"
                    :append-icon="showPassword ? 'hi-eye-slash' : 'hi-eye'"
                    :rules="[rules.required, rules.min]"
                    :type="showPassword ? 'text' : 'password'"
                    prepend-inner-icon="hi-lock-closed-outline"
                    name="password"
                    label="Password"
                    hint="At least 8 characters"
                    class="text-field-transparent"
                    filled
                    @click:append="showPassword = !showPassword"
                />
                <v-btn
                    :loading="loading"
                    :disabled="!valid"
                    color="primary"
                    large
                    depressed
                    block
                    @click.native="createUser"
                >Create account</v-btn>

            </v-form>
            <div class="text-caption gray--text pt-8">By creating an account you confirm you agree to Swiftio's <a
                    href="https//swiftio.io/privacy-policy"
                >Privacy Policy</a> and <a href="https//swiftio.io/terms">Terms & Conditions</a>.</div>
        </v-row>
    </v-container>
</template>
  
<script>
import logo from '../../assets/logo-swiftio.svg';

import { isEmpty } from '../../api/utilities';
import { BASE_URL } from '../../api/constants';
import axios from 'axios';


export default {

    props: {
        isAuthenticated: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            logo,
            valid: false,
            showPassword: false,
            rules: {
                required: value => !!value || "Required.",
                min: v => v.length >= 3 || "Min 3 characters",
                email: v => /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || 'E-mail must be valid'
            },
            user: {
                email: '',
                password: '',
            },
            loading: false,
        };
    },
    computed: {


    },
    methods: {
        isEmpty,
        createUser() {
            this.loading = true
            let url = new URL(`${BASE_URL}/user/create`);
            axios.put(url, this.user).then(response => {
                console.log(response)
                this.$store.dispatch('getAccessTokenToken', { username: this.user.email, password: this.user.password, init: true }).then(success => {

                    this.loading = false;
                }, error => {
                    this.loading = false;
                });
            })

        }
    }
};
</script>
  
  