<template>
  <v-container
    class="flat pa-0 ma-0"
    flat
    fluid
  >
    <Calendar
      ref="calendar"
      :view="view"
      :events="getEvents"
      :theme="getCalendarTheme"
      :week="options && options.week"
      :style="`height:${height}`"
      isReadOnly
    />
  </v-container>
</template>

<script>
import Calendar from '@toast-ui/vue-calendar';
import '@toast-ui/calendar/dist/toastui-calendar.min.css';
import { getCurrentTheme } from '../../theme/themeDefault';
import moment from 'moment';
export default {
  components: { Calendar },
  props: {
    viewconfig: { type: String, default: "" },
    object: { type: Object, default: null },
    height: { type: String, default: "" },
    view: { type: String, default: "week" },
  },
  data() {
    return {
      options: {
        defaultView: 'week',
        week: {
          startDayOfWeek: 1,
          taskView: false,
          eventView: ['time']
        }
      },

    };
  },
  computed: {
    currentTheme() {
      return getCurrentTheme(this.$vuetify.theme.isDark)
    },
    getCalendarTheme() {
      return {
        common: {
          // border: this.currentTheme.lineColor,
          backgroundColor: this.currentTheme.card,
          holiday: { color: this.currentTheme.gray },
          saturday: { color: this.currentTheme.gray },
          // dayName: { color: '#333' },
          // today: { color: '#009688' },
          // gridSelection: {
          //   backgroundColor: 'rgba(19, 93, 230, 0.1)',
          //   border: '1px solid #135de6',
          // },
        }
      }
    },
    getViewConfig() {
      return this.$store.getters.getViewConfigForID(this.viewconfig);
    },
    getObjectType() {
      return this.getViewConfig.objectType;
    },
    getObjects() {
      return this.$store.getters.getDataObjectsForObjectType(
        this.getObjectType
      );
    },
    getOptions() {
      if (this.getViewConfig && this.getViewConfig.options) {
        console.log('options', this.getViewConfig.options)
        try {
          const options = JSON.parse(this.getViewConfig.options)
          return options
        } catch (error) {
          return null
        }
      }
      return null
    },
    getFields() {
      var fields = [];
      if (this.getViewConfig.subViewConfig != undefined) {
        var payload = {
          object: this.currentObject,
          objectConfig: this.getObjectConfig,
          viewConfig: this.viewConfig,
          fieldList: this.getViewConfig.subViewConfig,
          size: 1,
          list: "display" //this.getMode
        };
        fields = this.$store.getters.getFieldsForViewFieldListAndSize(payload);
      }
      fields.sort(function (field1, field2) {
        if (field2.position > field1.position) {
          return -1;
        }
        if (field1.position > field2.position) {
          return 1;
        }
        return 0;
      });
      return fields;
    },
    getFilteredObjects() {
      var objects = this.getObjects;
      var payload = {
        objects: objects,
        viewConfig: this.getViewConfig,
        object: this.object
      };
      var filteredObject = this.$store.getters.getFilteredObjectsForViewConfig(
        payload
      );
      //Todo filter
      console.log('filteredObject', filteredObject)
      return filteredObject;
    },
    getEvents() {
      const events = this.getFilteredObjects.reduce((acc, item) => {
        let event = structuredClone(item)
        try {
          var startDateTime = null
          var endDateTime = null
          if (this.options && this.options.startDateTime && this.options.endDateTime) {
            startDateTime = moment(item[this.options.startDateTime])
            endDateTime = moment(item[this.options.endDateTime])
          } else {
            const startDate = this.options && this.options.startDate ? item[this.options.startDate] : item.StartDate
            var startTime = this.options && this.options.startTime ? item[this.options.startTime] : item.StartTime
            const endDate = this.options && this.options.endDate ? item[this.options.endDate] : item.EndDate
            var endTime = this.options && this.options.endTime ? item[this.options.endTime] : item.EndTime
            if (startDate) {
              const sDateTime = new Date(`${startDate} ${startTime || '12:00'}:00`)
              startDateTime = moment(sDateTime)
            }
            if (endDate) {
              const eDateTime = new Date(`${startDate} ${endTime || startTime || '12:15'}:00`)
              endDateTime = moment(eDateTime)
            }
          }
          if (startDateTime) {
            event.start = startDateTime.toISOString()
            event.end = (endDateTime && endDateTime.toISOString()) || startDateTime.add(30, 'min').toISOString()
            event.title = item[this.options.subject] || item.Subject || item.subject || item.Title
            acc.push(event)
          }
          return acc
        } catch (error) {
          return acc
        }
      }, [])
      console.log('events', events)
      return events
    },
    calendarInstance() {
      return this.$refs.calendar.getInstance();
    },
    updateWeek(value) {
      if (this.$refs && this.$refs.calendar && this.$refs.calendar.updateWeek) {
        this.$refs.calendar.updateWeek()
      }
    }
  },
  mounted: function () {

  },
  watch: {
    selectedView(newView) {
      this.calendarInstance.changeView(newView);
      this.setDateRangeText();
    },
  },
  methods: {
    onClickTodayButton() {
      this.calendarInstance.today();
      // this.setDateRangeText();
    },
    onClickMoveButton(offset) {
      this.calendarInstance.move(offset);
      // this.setDateRangeText();
    },
    selectedView(newView) {
      this.calendarInstance.changeView(newView);
      // this.setDateRangeText();
    },
  }
};
</script>
