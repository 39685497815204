<template>
  <v-card
    v-if="newObject"
    outlined
    elevation="2"
    class="rounded-lg mb-2 mt-2 card border1"
  >

    <v-card-title>
      <v-container>
        <v-row
          align="center"
          justify="start"
          class="flex-nowrap"
        >
          <div class="d-flex flex-column align-center justify-center gap">
            <v-avatar
              size="42"
              class="tileBackgroundColor darken-1 "
            >
              <img
                crossorigin="anonymous"
                :src="getTenantIcon"
                :lazy-src="getTenantIcon"
                max-height="100%"
                max-width="100%"
                cover
                class="rounded-xl elevation-2"
              />
            </v-avatar>
            <v-chip
              class="my-0 ml-2 mt-1 mb-0 px-2 py-1"
              x-small
              :color="getColor"
              style="height: 16px;"
            >
              {{ getLabel }}
            </v-chip>
          </div>
          <v-row class="flex-column align-start ma-0 ">
            <v-row
              class="pa-0 kingSize pl-2"
              no-gutters
              align="center"
            >
              <!-- <v-icon
                                class="ml-2"
                                small
                            >hi-arrow-uturn-left</v-icon> -->
              <div class="d-flex flex-1 kingSize flex-column gap">
                <v-select
                  ref="inputRef"
                  v-model="newObject.FromEmail"
                  :items="getMyMailboxEmails"
                  prepend-inner-icon="hi-user-outline"
                  label="From"
                  class="text-field-transparent p-1"
                  dense
                  filled
                  hide-details
                />
                <v-text-field
                  ref="inputRef"
                  v-model="newObject.ToEmail"
                  prepend-inner-icon="hi-arrow-uturn-left"
                  label="To"
                  class="text-field-transparent p-1"
                  dense
                  filled
                  hide-details
                />

              </div>



              <!-- <v-select
                                v-model="newObject.FromEmail"
                                :items="getActiveInboxEmails"
                                class="my-0"
                                dense
                                solo
                                hide-details=""
                            /> -->
            </v-row>
          </v-row>
        </v-row>
      </v-container>
    </v-card-title>
    <v-card-text class="px-1">
      <div class="d-flex align-center px-2">
        <v-text-field
          ref="inputRef"
          v-model="newObject.Subject"
          label="Subject"
          class="text-field-transparent p-1"
          dense
          filled
          hide-details
        />
        <v-menu
          offset-y
          left
          rounded="b-lg"
        >
          <template v-slot:activator="{ props, on }">
            <v-btn
              icon
              v-bind="props"
              class="iconButton mx-1"
              v-on="on"
            >
              <v-icon>hi-document-text-outline</v-icon>
            </v-btn>
          </template>

          <v-list>
            <v-subheader>Insert template</v-subheader>

            <v-list-item
              v-for="template in templates"
              :key="template.TemplateId"
              @click="() => insertTemplate(template)"
            >

              <v-list-item-title>{{ template.Subject }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
      <div class="rounded-lg pa-2 editMode">

        <!-- <div
          class="kingSize border1 rounded-lg "
          style="min-height: 150px;"
        >
          <div
            v-text="newObject.BodyHtml"
            class="textarea kingSize"
            style="min-height: 150px;outline: none;"
            role="textbox"
            contenteditable
          ></div>
        </div> -->
        <!-- <textarea
          v-model="newObject.BodyText"
          ref="htmlText"
          style="outline: none;resize: none;"
          class="mailbody kingSize border1 rounded-lg pa-4 primaryText--text"
          v-on:input="updateTextHeight"
        /> -->
        <div v-if="newObject.BodyHtml">
          <email-editor
            ref="mailEditor"
            v-model="newObject.BodyHtml"
          />
        </div>
        <!-- <vue-editor
          class="cardEditor"
          :editor-toolbar="toolbar"
          v-model="newObject.BodyHtml"
          :disabled="isSending"
        /> -->
      </div>
      <vue-dropzone
        id="dropzone"
        ref="myVueDropzone"
        :options="dropzoneOptions"
        :use-custom-slot="true"
        class="pa-0 no-border transparent"
        @vdropzone-sending-multiple="sendingMultiple"
        @vdropzone-drop="drop"
        @vdropzone-processing="process"
        @vdropzone-error="error"
      >
        <div class="dropzone-custom-content">
          <v-container class="px-0">
            <div class="text-caption text-left px-2">
              <v-icon small>hi-paper-clip-outline</v-icon> Attachments
            </div>
            <v-row
              no-gutters
              class="py-4 px-1"
            >
              <attachment
                v-for="item in getAttachments"
                class="mx-1"
                :key="item.name"
                :object="item"
                style="max-width:54px; min-width:54px;"
              />
              <v-btn
                :id="viewconfig"
                class="iconButton ml-2 mr-2"
                icon
                x-large
              >
                <v-icon dark>
                  hi-plus-outline
                </v-icon>
              </v-btn>
            </v-row>
          </v-container>

          <div class="on-dropzone-overlay">
            <v-icon
              x-large
              color="primary"
            >
              hi-document-plus
            </v-icon>
          </div>
        </div>
        <v-container
          v-if="attachmentLoading"
          class="fill-height ma-0 py-4"
          style="position: absolute; top:0"
        >
          <v-row
            class="fill-height ma-0"
            align="center"
            justify="center"
          >
            <v-progress-circular
              indeterminate
              color="primary"
            />
          </v-row>
        </v-container>
      </vue-dropzone>
    </v-card-text>

    <v-card-actions
      class="px-3 pt-0 pb-3"
      v-if="!isSending"
    >
      <div v-if="isReply">
        <v-btn
          class="px-6"
          elevation="0"
          color="primary"
          :disabled="needSave"
          left
          style=" border-top-right-radius: 0px;  border-bottom-right-radius: 0px; margin-right: 1px;"
          @click="save"
        >
          <v-icon
            small
            class="mr-2"
          >hi-inbox-arrow-down-outline</v-icon>Save
        </v-btn>

        <v-menu
          offset-y
          left
          rounded="b-lg"
        >
          <template v-slot:activator="{ props, on }">
            <v-btn
              v-bind="props"
              class="px-1"
              color="primary"
              style="min-width: 36px; border-top-left-radius: 0px;  border-bottom-left-radius: 0px;"
              elevation="0"
              v-on="on"
            >
              <v-icon>hi-chevron-down-outline</v-icon>
            </v-btn>
          </template>

          <v-list>
            <v-list-item
              v-if="showValidate"
              @click="validate"
            >
              <v-list-item-icon><v-icon>hi-check-circle-outline</v-icon></v-list-item-icon>
              <v-list-item-title>Set to validate</v-list-item-title>
            </v-list-item>
            <v-list-item @click="showDialog">
              <v-list-item-icon><v-icon>hi-paper-airplane-outline</v-icon></v-list-item-icon>
              <v-list-item-title>Send</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
      <v-btn
        v-else
        color="primary"
        elevation="0"
        class="gap-1"
        :disabled="!canSend"
        @click="showDialog"
      >
        <v-icon
          small
          class="mr-1"
        >hi-paper-airplane-outline</v-icon>
        Send
      </v-btn>
      <v-spacer />
      <v-btn
        v-if="getAIanswers && getAIanswers.length > 0"
        class="px-6 quinary lighten-4 quinary--text rounded-xl"
        elevation="0"
        left
        @click="aiWrite"
      >
        <v-icon
          class="mr-1"
          small
        >hi-sparkles-outline</v-icon>AI Anwser
      </v-btn>
      <!-- <v-btn
        class="px-6"
        elevation="0"
        @click="rewriteBetter"
      >
        Rewrite
      </v-btn> -->
    </v-card-actions>
    <v-dialog
      v-model="sendDialog"
      max-width="400"
      content-class="rounded-lg"
    >
      <v-card class="pb-2">
        <v-card-title class="text-h5">
          Are you sure you want to send this message?
        </v-card-title>
        <v-card-text v-if="isEmpty(newObject.BodyHtml)">
          You are about to send an empty message
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            text
            @click="sendDialog = false"
          >
            Cancel
          </v-btn>
          <v-btn
            :color="isEmpty(newObject.BodyHtml) ? 'error' : 'primary'"
            @click="send"
            right
            class="px-6"
          >
            Send
            <v-icon
              small
              class="ml-1"
            >hi-paper-airplane-outline</v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import moment from "moment";
import {
  convertClassesToInline,
  copyObject,
  extractEmail,
  formatHtmlData,
  isEmail,
  isEmpty,
  isEmptyArray
} from "../api/utilities";
import {
  ACCOUNT_MAIN,
  ATTACHMENT,
  CHAT_GPT_REQUEST,
  INBOX_ITEM,
  INBOX_MAIN,
  TEMPLATE
} from "../store/constants";
import { mapGetters } from "vuex";
import { VueEditor } from "vue2-editor";
import {
  DRAFT,
  SENT,
  TO_SEND,
  TO_VALIDATE
} from "../components/components/MailContainer.vue";
import vue2Dropzone, { data } from "vue2-dropzone";
import "vue2-dropzone/dist/vue2Dropzone.min.css";
import imageCompression from "browser-image-compression";
import Attachment from "./components/Attachment.vue";
import EmailEditor from './emailEditor.vue';

const INBOX_ITEM_STATUS_CODE = "InboxItemStatus";
const CHAT_GPT_TYPE_MAIL_RESPONSE = "T003"

export default {
  components: { VueEditor, vueDropzone: vue2Dropzone, Attachment, EmailEditor },
  props: {
    viewconfig: {
      type: String,
      default: ""
    },
    height: {
      type: String,
      default: null
    },
    object: {
      type: Object,
      default: null
    },
    parent: {
      type: Object,
      default: null
    },
    isReply: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      attachmentLoading: false,
      newObject: {},
      oldObject: {},
      sendDialog: false,
      attachments: [],
      mailbody: null,
      mailCss: '',
      editor: null,
      dropzoneOptions: {
        url: "https://swiftio.io/",
        maxFilesize: 5.0,
        addRemoveLinks: true,
        clickable: `#${this.viewconfig}`,
        previewsContainer: false,
        duplicateCheck: true,
        // autoProcessQueue: false,
        // acceptedFiles: 'image/*,application/pdf',
        maxFiles: 5,
        uploadMultiple: true,
        parallelUploads: 10
        // acceptedFiles: 'image/*,application/pdf'
      },
      toolbar:
        [
          // [{ 'font': [] }],
          [{ 'header': [false, 1, 2, 3, 4, 5, 6,] }],
          // [{ 'size': ['small', false, 'large', 'huge'] }],
          ['bold', 'italic', 'underline', 'strike'],
          [{ 'align': '' }, { 'align': 'center' }, { 'align': 'right' }, { 'align': 'justify' }],
          // [{ 'header': 1 }, { 'header': 2 }],
          ['blockquote', 'code-block'],
          [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'list': 'check' }],
          [{ 'script': 'sub' }, { 'script': 'super' }],
          [{ 'indent': '-1' }, { 'indent': '+1' }],
          [{ 'color': [] }, { 'background': [] }],
          ['link'],
          // [{ 'direction': 'rtl' }],
          // ['clean'],
        ]
    };
  },
  computed: {
    ...mapGetters(["getActiveInboxEmails", "getInboxEmails", "getTenantIcon"]),
    needSave() {
      // console.log(JSON.stringify(this.newObject));
      // console.log(JSON.stringify(this.oldObject));
      return JSON.stringify(this.newObject) === JSON.stringify(this.oldObject);
    },
    getMyMailboxEmails() {
      return this.$store.getters.getMyMailboxEmails(this.$store.getters.getCurrentEmployeeGuid)
    },
    canSend() {
      return isEmail(this.newObject.ToEmail) && isEmail(this.newObject.FromEmail) && !isEmpty(this.newObject.Subject)
    },
    getAttachments() {
      if (this.newObject) {
        var Guid = this.newObject.Guid;
        var filter = attachment => attachment.parent === Guid;
        var attachments = this.$store.getters.getObjectsForFilter(
          ATTACHMENT,
          filter
        );
        return attachments;
      }
      return [];
    },
    showValidate() {
      return this.newObject.Status === DRAFT;
    },
    templates() {
      console.log('this.getObjectType', this.getObjectType)
      var filter = (item) => item.ObjectType === this.getObjectType;
      var templates = this.$store.getters.getObjectsForFilter(
        TEMPLATE,
        filter
      );
      return templates
    },
    isSending() {
      return this.newObject.Status === TO_SEND;
    },
    getLabel() {
      if (this.newObject && this.newObject.Status) {
        switch (this.newObject.Status) {
          case DRAFT:
            return "Draft";
          case TO_VALIDATE:
            return "To be validated";
          case TO_SEND:
            return "Sending...";

          default:
            return "Draft";
        }
      }
      return "";
    },
    getColor() {
      if (this.getStatusList && this.newObject) {
        var listItem = this.getStatusList.find(
          item => item.value === this.newObject.Status
        );
        if (listItem && listItem.color) return listItem.color;
      }
      return "primary";
    },
    getStatusList() {
      return this.$store.getters.getValueListForId(INBOX_ITEM_STATUS_CODE);
    },
    initials() {
      console.log("initials");
      if (this.getAccount) {
        console.log("initials account", this.getAccount);
        return this.getInitialsFromString(this.getAccount.FullName);
      } else if (this.newObject && this.newObject.FromEmail) {
        return this.getInitialsFromEmail(this.newObject.FromEmail);
      }
    },
    getAccount() {
      if (this.newObject && this.newObject.Account) {
        var accountId = this.newObject.Account;
        var filter = account => account.Guid === accountId;
        var accounts = this.$store.getters.getObjectsForFilter(
          ACCOUNT_MAIN,
          filter
        );
        return isEmptyArray(accounts) ? null : accounts[0];
      }
      return null;
    },
    getBodyHtml() {
      if (this.newObject && this.newObject.BodyHtml) {
        var data = this.newObject.BodyHtml;
        const html = atob(data.replace(/_/g, "/").replace(/-/g, "+"));
        return html;
      }
      return null;
    },
    isInternal() {
      const email = extractEmail(this.newObject.FromEmail);
      return this.getInboxEmails && this.getInboxEmails.includes(email);
    },
    isDraft() {
      return this.newObject.Status === DRAFT;
    },
    getViewConfig() {
      return this.$store.getters.getViewConfigForID(this.viewconfig);
    },
    getObjectType() {
      return this.getViewConfig.objectType;
    },
    getParentObjectType() {
      return this.$store.getters.getCurrentSplitCardObjectType
    },
    getChatGPTRequests() {

      const filter = (item) => {

        return item.ParentGuid === this.parent.Guid && item.Parent === INBOX_MAIN
      }
      return this.$store.getters.getObjectsForFilter(CHAT_GPT_REQUEST, filter)
    },
    getAIanswers() {
      if (this.getChatGPTRequests && this.getChatGPTRequests.length > 0) {
        return this.getChatGPTRequests.filter(item => item.Type === CHAT_GPT_TYPE_MAIL_RESPONSE)
      }
      return []
    }
  },
  mounted: function () {
    this.oldObject = structuredClone(this.object);
    this.newObject = structuredClone(this.object);
    this.mailbody = this.newObject.BodyHtml

    if (isEmpty(this.newObject.ToEmail)) {
      const toEmail = this.parent ? this.parent.Email || this.parent.EmailAddress : null
      if (toEmail) {
        this.newObject.ToEmail = toEmail
      }
    }
    if (isEmpty(this.newObject.Account) && this.parent && !isEmpty(this.parent.Account)) {
      this.newObject.Account = this.parent.Account
    }


    console.log('parent', this.parent)
    console.log('object', this.object)
    console.log('viewconfig', this.getViewConfig)

    if (this.newObject && this.newObject.Attachments) {
      this.attachments = JSON.parse(this.newObject.Attachments);
    }

  },
  watch: {
  },
  methods: {
    isEmpty,
    async insertTemplate(template) {
      console.log('template', template)
      const templateData = await this.getTemplateContent(template)
      console.log('templateData', templateData)
      this.$refs.mailEditor.editor.setContent(formatHtmlData(template.Body, templateData));
      this.newObject.Subject = template.Subject
    },
    async getTemplateContent(template) {
      const object = this.parent
      const parentObjectType = this.getParentObjectType
      if (!isEmpty(template) && !isEmpty(parentObjectType)) {
        var objectConfig = this.$store.getters.getObjectConfigForType(
          parentObjectType
        );
        var payload = {
          action: "getTemplateData",
          objectConfig: objectConfig,
          object,
          viewConfig: this.getViewConfig,
          options: {
            templateId: template.TemplateId
          }
        };
        var result = await this.$store.dispatch(
          "launchActionForObjectAndConfig",
          payload
        );
        return result || object
      }
      return object
    },
    updateTextHeight() {
      setTimeout(() => {
        if (this.$refs.htmlText) {
          this.$refs.htmlText.style.height =
            this.$refs.htmlText.scrollHeight + 'px'
        }
      }, 10)
    },
    htmlToText(html) {
      //remove code brakes and tabs
      // html = html.replace(/\n/g, "");
      // html = html.replace(/\t/g, "");

      //keep html brakes and tabs
      html = html.replace(/<\/td>/g, "\t");
      html = html.replace(/<\/table>/g, "\n");
      html = html.replace(/<\/tr>/g, "\n");
      html = html.replace(/<\/p>/g, "\n");
      html = html.replace(/<\/div>/g, "\n");
      html = html.replace(/<\/h>/g, "\n");
      html = html.replace(/<br>/g, "\n");
      html = html.replace(/<br( )*\/>/g, "\n");

      //parse html into text
      var dom = (new DOMParser()).parseFromString('<!doctype html><body>' + html, 'text/html');
      return dom.body.textContent;
    },

    drop(event) {
      // console.log('drop-event', event)
      var dropzone = this.$refs.myVueDropzone;
      var pdfData = event.dataTransfer.getData("PDF");
      if (!isEmpty(pdfData)) {
        const dataObj = JSON.parse(pdfData);
        const { fileName, data } = dataObj;
        const pdfFile = this.dataURLtoFile(data, fileName);
        dropzone.addFile(pdfFile);
        return;
      }

      var imageUrl = event.dataTransfer.getData("URL");
      if (isEmpty(imageUrl)) {
        return;
      }
      var fileName = imageUrl.split("/").pop();
      console.log("drop-event", imageUrl);
      // set the effectAllowed for the drag item
      event.dataTransfer.effectAllowed = "copy";

      function getDataUri(url, callback) {
        var image = new Image();

        image.onload = function () {
          var canvas = document.createElement("canvas");
          canvas.width = this.naturalWidth; // or 'width' if you want a special/scaled size
          canvas.height = this.naturalHeight; // or 'height' if you want a special/scaled size

          canvas.getContext("2d").drawImage(this, 0, 0);

          // Get raw image data
          // callback(canvas.toDataURL('image/png').replace(/^data:image\/(png|jpg);base64,/, ''));

          // ... or get as Data URI
          callback(canvas.toDataURL("image/jpeg"));
        };

        image.setAttribute("crossOrigin", "anonymous");
        image.src = url;
      }

      function dataURItoBlob(dataURI) {
        var byteString, mimestring;

        if (dataURI.split(",")[0].indexOf("base64") !== -1) {
          byteString = atob(dataURI.split(",")[1]);
        } else {
          byteString = decodeURI(dataURI.split(",")[1]);
        }

        mimestring = dataURI
          .split(",")[0]
          .split(":")[1]
          .split(";")[0];

        var content = new Array();
        for (var i = 0; i < byteString.length; i++) {
          content[i] = byteString.charCodeAt(i);
        }

        return new Blob([new Uint8Array(content)], {
          type: mimestring
        });
      }

      getDataUri(imageUrl, function (dataUri) {
        var blob = dataURItoBlob(dataUri);
        blob.name = fileName;
        console.log(blob);
        dropzone.addFile(blob);
      });
      // console.log('drop-event-target', event.target)
    },
    process(file) {
      console.log("process-event", file);
      // console.log('drop-event-target', event.target)
    },
    error(file, message, xhr) {
      console.log("error", file, message, xhr);
    },

    showDialog() {
      this.sendDialog = true;
    },
    sendingMultiple(files) {
      if (files) {
        var objectType = "Attachment";
        var objectConfig = this.$store.getters.getObjectConfigForType(objectType);
        var options = {
          parent: this.newObject.Guid,
          parentType: INBOX_ITEM,
          account: this.parent.Account
        }
        const payload = {
          files,
          object: this.newObject,
          objectConfig,
          viewConfig: this.getViewConfig,
          options
        }
        this.$store.dispatch('uploadFiles', payload)
      }
    },
    getInitialsFromString(string) {
      if (isEmpty(string)) {
        return "";
      }
      let rgx = new RegExp(/(\p{L}{1})\p{L}+/, "gu");
      let initials = [...string.matchAll(rgx)] || [];
      initials = (
        (initials.shift()?.[1] || "") + (initials.pop()?.[1] || "")
      ).toUpperCase();
      console.log("initials string", initials);
      return initials;
    },
    getInitialsFromEmail(email) {
      if (isEmpty(email)) {
        return "";
      }
      let rgx = new RegExp(/[a-zA-Z]/, "gu");
      let initials = email.match(rgx);
      console.log("initials email", initials);
      return initials;
    },
    formatDate(date) {
      const mailDate = moment.unix(date / 1000);
      if (mailDate.isSame(new Date(), "day")) {
        return mailDate.format("HH:MM");
      } else {
        return mailDate.format("DD MMM YYYY HH:MM");
      }
    },
    formatEmail(mail) {
      if (mail) {
        const re = /(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))/;
        return re.exec(mail)[0];
      }
      return null;
    },
    setBody() {

      // if (this.newObject.Status === TO_SEND) {
      //   // const style = '<style>p{margin-block-start: 0;margin-block-end: 0;}</style>'
      //   this.newObject.BodyHtml = this.newObject.BodyHtml.replaceAll('<p><br></p>', '<br><br>')
      //   this.newObject.BodyHtml = this.newObject.BodyHtml.replaceAll('<p', '<span')
      //   this.newObject.BodyHtml = this.newObject.BodyHtml.replaceAll('</p>', '</span>')
      //   // this.newObject.BodyHtml = this.newObject.BodyHtml.replaceAll('<p', '<p style="margin-block-start: 0;margin-block-end: 0;"')

      // }
      // console.log('this.newObject.BodyHtml', this.newObject.BodyHtml)

      this.newObject.BodyHtml = this.$refs.mailEditor.editor.getContent();
      const bodyText = this.extractContent(this.newObject.BodyHtml, true);
      console.log('content', this.$refs.mailEditor.editor.getContent())
      this.newObject.BodyText = bodyText;
      this.newObject.Snippet = this.newObject.BodyText && this.newObject.BodyText.substring(0, 150);
    },
    send() {
      this.newObject.Status = TO_SEND;
      this.setBody();
      this.saveObject();
      this.sendDialog = false;
      this.$store.commit('hideComposeMailDialog')
    },
    save() {
      this.setBody();
      this.saveObject();
    },
    validate() {
      this.setBody();
      this.newObject.Status = TO_VALIDATE;
      this.saveObject();
    },
    saveObject() {
      this.$store.dispatch("saveObjectForObjectType", {
        object: this.newObject,
        objectType: INBOX_ITEM,
        oldObject: this.oldObject
      });
      setTimeout(() => {
        this.oldObject = structuredClone(this.newObject);
      }, 1000);

      // this.oldObject = copyObject(this.newObject)
    },
    async aiWrite() {
      console.log(this.getChatGPTRequests)
      console.log(this.getAIanswers)
      if (this.getAIanswers && this.getAIanswers.length > 0) {
        const answer = this.getAIanswers[this.getAIanswers.length - 1]
        if (answer) {
          this.newObject.BodyText = this.htmlToText(answer.Response)
          this.updateTextHeight()
        }

      }
    },
    async rewriteBetter() {
      var method = "rewriteDraft";
      var draft = this.extractContent(this.newObject.BodyHtml, true);
      var object = this.newObject; //inboxMain
      var objectConfig = this.$store.getters.getObjectConfigForType(INBOX_MAIN);
      var payload = {
        action: method,
        objectConfig: objectConfig,
        object: object,
        viewConfig: this.getViewConfig,
        context: this.$store,
        options: { draft: draft }
      };
      var result = await this.$store.dispatch(
        "launchActionForObjectAndConfig",
        payload
      );
      console.log("Rewrite better");
    },
    extractContent(s, space) {
      var span = document.createElement("span");
      span.innerHTML = s;
      if (space) {
        var children = span.querySelectorAll("*");
        for (var i = 0; i < children.length; i++) {
          if (children[i].textContent) children[i].textContent += " ";
          else children[i].innerText += " ";
        }
      }
      return [span.textContent || span.innerText]
        .toString()
        .replace(/ +/g, " ");
    }
  }
};
</script>

<style scoped>
.vue-dropzone {
  min-height: 100px;
  position: relative;
}

.cardEditor .ql-editor {
  color: black;
}
</style>


<style>
.cardEditor .ql-editor {
  color: black;
}

.ql-snow .ql-picker-options {
  background-color: var(--v-card-base) !important;
  border-radius: 4px;
}
</style>
