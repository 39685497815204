<template>
  <v-container
    ref="outerListContainerCard"
    id="outerListContainerCard"
    class="pa-0 ma-0"
    fluid
    style="height:100%"
  >
    <resize-observer @notify="onResizeComponent" />
    <v-row
      class="pr-0 pb-0"
      style="height:100%"
      no-gutters
    >
      <v-col
        cols="12"
        md="6"
        class="pl-0 pr-2"
      >
        <div class="pa-1 pt-0">
          <FilterView
            :viewconfig="viewconfig"
            :is-compact-view="true"
            :object="object"
            remove-search
            read-only
          />
        </div>
        <v-row
          align="center"
          class="py-2 pl-9 pr-6"
        >
          <span>
            <b>{{ getDayFormat }},</b> {{ selectedDay.format('D MMM') }}
          </span>
          <v-spacer />
          <v-btn
            icon
            small
            @click="prevWeek"
          >
            <v-icon>hi-chevron-left</v-icon>
          </v-btn>
          <v-btn
            icon
            small
            @click="nextWeek()"
          >
            <v-icon>hi-chevron-right</v-icon>
          </v-btn>
        </v-row>
        <v-row
          align="center"
          justify="space-between"
          class="pa-4"
        >
          <v-col
            v-for="day in getDays"
            :key="day.unix()"
            class="ma-0 pa-1"
          >
            <v-card
              elevation="0"
              class="pa-0 transparent"
              flat
              @click="selectDay(day)"
            >
              <v-container
                fluid
                class="pa-0"
              >
                <v-row
                  align="center"
                  justify="center"
                  class="pa-0"
                  no-gutters
                >

                  <v-avatar
                    :class="isSameDay(day) ? 'primary white--text' : day.isSame($moment(), 'day') ? 'primary lighten-5' : 'tileBackgroundColor'"
                    size="100%"
                    style="aspect-ratio:1; max-width: 48px; min-width:auto;"
                  >
                    <div class="text-subtitle-2">{{ day.format('D') }}</div>
                  </v-avatar>

                </v-row>
                <v-row
                  style="height:8px;"
                  class="mt-1"
                  align="center"
                  justify="center"
                  no-gutters
                >


                  <v-avatar
                    v-for="(item, index) in getItemsOfDay(day)"
                    :key="item.Guid"
                    :class="index === 0 ? 'primary' : 'chartBad'"
                    style="margin: 0 1px;"
                    size="4px"
                  />


                </v-row>
                <v-row
                  class="mt-1"
                  align="center"
                  justify="center"
                  no-gutters
                >

                  <div class="text-caption text-center">{{ day.format('ddd') }}</div>

                </v-row>
              </v-container>
            </v-card>
          </v-col>
        </v-row>
        <v-row
          align="center"
          justify="center"
          class="border-top py-2 pl-5 pr-4 pb-4"
        >
          <v-list
            v-if="getItemsOfDay(selectedDay).length > 0"
            class="transparent"
            dense
            min-width="100%"
          >


            <div
              v-for="(groupItem, index) in groupItems(getItemsOfDay(selectedDay))"
              :key="groupItem.time"
            >
              <v-subheader>
                <v-avatar
                  :class="index === 0 ? 'primary' : 'chartBad'"
                  class="mr-1"
                  size="4px"
                />{{ groupItem.time }}
              </v-subheader>
              <!-- <v-list-item class="rounded-lg tileBackgroundColor mb-2"> -->
              <v-btn
                v-for="item in groupItem.items"
                :key="item.Guid"
                block
                elevation="0"
                class="tileBackgroundColor text-left"
                @click="clickField(getField('Subject'), item)"
              >
                <!-- <v-list-item-content> -->
                <v-list-item-title class="font-weight-mediumBold">{{ item.Subject }}</v-list-item-title>
                <v-spacer />
                <GenericInputSimple
                  :object="getItem(item)"
                  :header="getField('Type')"
                  :viewconfig="viewconfig"
                  :showlabel="false"
                  :readonly="true"
                  solo
                  dense
                  navigateidlink
                />


                <!-- </v-list-item-content> -->
              </v-btn>
              <!-- </v-list-item> -->

            </div>
          </v-list>
          <v-row
            v-if="getItemsOfDay(selectedDay).length === 0"
            align="center"
            justify="center"
            class="pb-4 pt-6"
          >

            <v-icon large>hi-calendar-days</v-icon>

            <v-col cols="12">
              <div class="text-body-2 text-center">No appointments</div>
            </v-col>
          </v-row>

        </v-row>
      </v-col>
      <v-col
        cols="12"
        md="6"
        class="pa-2"
        :style="`height: 100%; max-height: ${containerHeight ? containerHeight : 400}px`"
      >

        <MapView
          :viewconfig="viewconfig"
          :object="object"
          :list="getItemsOfDay(selectedDay)"
          disable-default-ui
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import * as Constants from "../../../store/constants.js";
import FilterView from "../../splitCard/FilterView.vue";
import * as UTIL from "../../../api/utilities.js";
import MapView from "../MapView.vue";


export default {
  components: { FilterView, MapView },
  props: {
    viewconfig: {
      type: String,
      default: ""
    },
    height: {
      type: String,
      default: null
    },
    object: {
      type: Object,
      default: null
    },
  },
  data() {
    return {
      selectedWeek: 0,
      dateFormat: 'DD MMM',
      selectedDay: this.$moment(),
      containerHeight: 400
    }
  },
  computed: {
    getHeight() {
      return this.$refs && this.$refs.outerListContainerCard && this.$refs.outerListContainerCard.clientHeight
    },
    getFields() {
      var fields = [];
      if (this.getViewConfig.subViewConfig != undefined) {
        var payload = {
          object: this.currentObject,
          objectConfig: this.getObjectConfig,
          viewConfig: this.viewConfig,
          fieldList: this.getViewConfig.subViewConfig,
          size: 1,
          list: "display" //this.getMode
        };
        fields = this.$store.getters.getFieldsForViewFieldListAndSize(payload);
      }
      fields.sort(function (field1, field2) {
        if (field2.position > field1.position) {
          return -1;
        }
        if (field1.position > field2.position) {
          return 1;
        }
        return 0;
      });
      return fields;
    },
    getSubviewConfig() {
      if (this.getViewConfig === undefined) {
        return "";
      }
      return this.getViewConfig.subViewConfig;
    },
    getViewConfig() {
      return this.$store.getters.getViewConfigForID(this.viewconfig);
    },
    getWidth() {
      if (this.myListWidth <= Constants.SMALL_SIZE_THRESHOLD) {
        return Constants.SMALL_SIZE;
      }
      if (
        Constants.SMALL_SIZE_THRESHOLD <= this.myListWidth &&
        this.myListWidth < Constants.MEDIUM_SIZE_THRESHOLD
      ) {
        return Constants.MEDIUM_SIZE;
      }
      if (
        Constants.MEDIUM_SIZE_THRESHOLD <= this.myListWidth &&
        this.myListWidth < Constants.BIG_SIZE_THRESHOLD
      ) {
        return Constants.BIG_SIZE;
      }
      return Constants.KING_SIZE;
    },
    isCompactView() {
      return this.getWidth === Constants.SMALL_SIZE;
    },
    getDays() {
      var date = this.$moment().isoWeek(this.getSelectedWeek || 1).startOf("isoWeek"), weeklength = 7, result = [];
      while (weeklength--) {
        result.push(date);
        date = this.$moment(date).add(1, 'day')
      }
      return result;
    },
    getDayFormat() {
      if (this.isSameDay(this.$moment())) {
        return 'Today'
      } else return this.selectedDay.format('dddd')
    },
    getSelectedWeek() {
      var abs = Math.abs(this.selectedWeek)
      if (this.selectedWeek === 0) return this.$moment().isoWeek()
      if (this.selectedWeek < 0) {
        return this.$moment().subtract(abs, 'week').isoWeek()
      } else {
        return this.$moment().add(abs, 'week').isoWeek()
      }
    },
    getObjects() {
      if (!UTIL.isEmpty(this.list)) {
        return this.list;
      }
      var items = this.$store.getters.getDataObjectsForObjectType(
        this.getObjectType
      );
      return items;
    },
    getFilteredObjects() {
      var objects = this.getObjects;
      var payload = {
        objects: objects,
        viewConfig: this.getViewConfig,
        object: this.getCurrentObject
      };
      var filteredObject = this.$store.getters.getFilteredObjectsForViewConfig(
        payload
      );
      //Todo filter
      return filteredObject;
    },
    getObjectType() {
      return this.getViewConfig.objectType;
    },
    getObjectConfig() {
      return this.$store.getters.getObjectConfigForType(this.getObjectType);
    },
  },
  mounted() {
    this.containerHeight = this.$refs && this.$refs.outerListContainerCard && this.$refs.outerListContainerCard.clientHeight
  },
  methods: {
    getField(value) {
      return this.getFields.find(item => item.value === value)
    },
    onResizeComponent() {
      this.containerHeight = this.$refs && this.$refs.outerListContainerCard && this.$refs.outerListContainerCard.clientHeight
    },
    getField(field) {
      var fields = this.getFields;
      var index = this.getFields.map(item => item.value).indexOf(field);
      if (index > -1) {
        return this.getFields[index]
      }
      return {}
    },
    getItemsOfDay(day) {
      var items = this.getFilteredObjects.filter(item => {
        return this.$moment(item.StartDate).isSame(day, 'day')

      })
      return items
    },
    groupItems(items) {
      var groupedItems = {}
      if (items) {
        items.forEach(item => {
          var startTime = item.StartTime || 'All Day'
          if (groupedItems[startTime]) {
            groupedItems[startTime].push(item)
          } else {
            groupedItems[startTime] = [item]
          }
        })
      }

      var arrayItems = [];
      var sortedKeys = Object.keys(groupedItems).sort((a, b) => {
        if (this.$moment(a, 'h:mm').isBefore(this.$moment(b, 'h:mm'))) {
          return -1;
        }
        if (this.$moment(a, 'h:mm').isAfter(this.$moment(b, 'h:mm'))) {
          return 1;
        }
        // a must be equal to b
        return 0;
      })
      sortedKeys.forEach(item =>
        arrayItems.push({
          'time': item,
          'items': groupedItems[item]
        })
      )

      return arrayItems
    },
    isSameDay(date) {
      if (date)
        return date.isSame(this.selectedDay, 'day')
      else return false
    },
    selectDay(day) {
      this.selectedDay = day;
    },
    nextWeek() {
      this.selectedWeek += 1;
    },
    prevWeek() {
      this.selectedWeek -= 1;
    },
    isLink(field) {
      if (!UTIL.isEmpty(field.navigationConfigId) && this.readonly === true) {
        return true;
      }
      if (field.type === "link") {
        return true;
      }
      if (field.type === "idLink") {
        return true;
      }
      return false;
    },
    clickField: function (field, object) {
      if (!this.isLink(field)) {
        return;
      }

      if (
        this.selectionfunction !== null &&
        this.selectionfunction !== undefined
      ) {
        this.selectionfunction(object);
        return;
      }
      var navigationObject = object;
      if (
        field.relatedObjectType !== undefined &&
        field.navigationId !== null
      ) {
        var navigationId = object[field.value];
        if (field.navigationId !== undefined && field.navigationId !== null) {
          navigationId = object[field.navigationId];
        }
        var payload = {
          objectType: field.relatedObjectType,
          objectId: navigationId
        };
        var navigationObject = this.$store.getters.getDataObjectForIdAndObjectType(
          payload
        );
      }
      var payload = {
        splitCardId: field.navigationConfigId,
        object: navigationObject
      };
      this.$store.dispatch(Constants.SELECT_OBJECT, payload);
    },
  }

};
</script>

<style scoped>
.border-top {
  border-top: solid 2px rgba(0, 0, 0, 0.02) !important;
}
</style>
