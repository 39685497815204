import Vue from "vue";
import Vuex from "vuex";

//import userContext from "./userContext";
import objectContext from "./objectContext";
import dbContext from "./dbContext";
import appContext from "./appContext";
import uiContext from "./uiContext";
import initCoreContext from "./initCoreContext";
import splitCardContext from "./splitCardContext";
import dynamicCardContext from "./dynamicCardContext";
import viewConfigContext from "./viewConfigContext";
import tabsContext from "./tabsContext";
import customActions from "./customActions";
import operationContext from "./operationContext";
import dbDataContext from "./dbDataContext";
import synchronisationContext from "./synchronisationContext";
//import initCoreContext from "./initCoreContext_depricated";
import filterContext from "./filterContext";
import chartContext from "./chartContext";
import formContext from "./formContext";
import calendarContext from "./calendarContext";
import navigationContext from "./navigationContext";
import employeeContext from "./employeeContext";
import logContext from "./loggingContext";
import configurationContext from "./configurationContext";
import authorisationContext from "./authorizationContext";
import massUpdateContext from "./massUpdateContext";
import authorizationObjectContext from "./authorizationObjectContext";
import alertContext from "./alertContext";
import signatureContext from "./signatureContext";
import notificationContext from "./notificationContext";
import googleContext from "./googleContext";
import pinContext from "./pinContext";
import inboxContext from "./inboxContext";
import clearingContext from "./clearingContext";
import uiHashingContext from "./uiHashingContext";
import actionDialogContext from "./actionDialogContext";
import attachmentContext from "./attachmentContext";
import itemSelectionContext from "./itemSelectionContext";
import loadingDialogContext from "./loadingDialogContext";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    objectContext,
    dbContext,
    appContext,
    uiContext,
    initCoreContext,
    splitCardContext,
    dynamicCardContext,
    viewConfigContext,
    tabsContext,
    customActions,
    dbDataContext,
    operationContext,
    synchronisationContext,
    filterContext,
    chartContext,
    formContext,
    calendarContext,
    navigationContext,
    employeeContext,
    logContext,
    configurationContext,
    authorisationContext,
    massUpdateContext,
    authorizationObjectContext,
    alertContext,
    signatureContext,
    notificationContext,
    googleContext,
    pinContext,
    inboxContext,
    clearingContext,
    uiHashingContext,
    actionDialogContext,
    attachmentContext,
    itemSelectionContext,
    loadingDialogContext,
  },
});
