import * as Api from "./objectApi";
import * as Constants from "../store/constants";
import * as syncCE7 from "./syncApi/syncCE7";
import * as syncCore from "./syncApi/syncCore";
import * as syncCRM from "./syncApi/syncCRM";
import { getGuid, isEmpty, isEmptyArray } from "./utilities";
import { API_BASE_URL } from "./constants";

/************************************************* 
  Parser
**************************************************/

/**
 * Parse the json response with config and options.
 * options is currently not used.
 * @param {} xml
 * @param {*} config
 * @param {*} options
 */

export const parseJSON = (xml, config, store, syncGroup, options) => {
  var object;
  var objects = [];
  var newObject;
  var responseObjects = xml;
  if (syncGroup === Constants.SYNC_GROUP_CE7) {
    responseObjects = responseObjects.d.results;
  }
  for (var i = 0; i < responseObjects.length; i++) {
    object = responseObjects[i];
    newObject = parseObjectJson(object, config, options);
    var canSave = config.beforeSave(newObject, store, { fromSync: true });
    objects.push(newObject);
  }
  return objects;
};
const parseObjectJson = (object, config, options) => {
  var newObject = Api.newObject();
  var properties = config.fieldsConfig;
  var property;
  for (var j = 0; j < properties.length; j++) {
    property = properties[j];
    var newValue = parseField(object, property);
    if (newValue !== undefined) {
      newObject[property.field] = newValue;
    }
  }
  return newObject;
};
/*
    This method will convert the received file to string.
    jsonDateString
        BackendDate: /Date(1238540400000)
        Field: String
  */
const parseField = (object, property) => {
  var returnValue;
  if (property.convertor === "jsonDateString") {
    var date = new Date(
      parseInt(object[property.backendField].replace("/Date(", "")),
    );
    returnValue = date.valueOf();
  }
  if (returnValue === undefined) {
    returnValue = object[property.backendField];
  }
  return returnValue;
};

/*
Getters
*/
export const buildRestGet = (syncGroup, synchronisations) => {
  if (syncGroup.includes("_CORE_")) {
    return syncCore.buildRestGetCore(synchronisations);
  } else if (syncGroup.includes("_CE7")) {
    return syncCE7.buildRestGetCE7(syncGroup, synchronisations);
  } else {
    return syncCRM.buildRestGetCore(syncGroup, synchronisations);
  }
};

export const buildRestGetCoreFromUrl = (url, synchronisations) => {
  const objectTypes = Object.keys(synchronisations);
  const params = {};
  if (!isEmpty(synchronisations)) {
    for (let index = 0, l = objectTypes.length; index < l; index++) {
      const objectType = objectTypes[index];
      params[objectType] = synchronisations[objectType].latestSync
        ? synchronisations[objectType].latestSync.createTime
        : 0;
    }
  }
  var restConfig = {
    url: url + "All",
    method: "get",
    delta: "",
    config: {
      params,
      headers: { "Access-Control-Allow-Origin": "*" },
    },
  };
  return restConfig;
};

/*
Update
*/

export const buildRestUpdate = (operationsToSend) => {
  const method = "post";
  const url = API_BASE_URL + "update";
  const body = buildRestUpdateBody(operationsToSend);
  return {
    body,
    method,
    url,
  };
};

export const buildRestUpdateBody = (operationsToSend) => {
  if (isEmptyArray(operationsToSend)) {
    console.warn("operationsToSend is empty");
    return;
  }
  const operations = operationsToSend.map((operationContainer) => {
    const objectConfig = operationContainer.objectConfig;
    const object = operationContainer.newObject;
    const oldObject = operationContainer.oldObject;
    var newObject = {};
    var delta = {};

    if (!isEmpty(object)) {
      objectConfig.fieldsConfig.forEach((field) => {
        if (
          !isEmpty(field.backendField) &&
          object[field.field] !== undefined &&
          object[field.field] !== null &&
          field.edit !== false
        ) {
          newObject[field.backendField] = object[field.field];
          if (
            oldObject &&
            JSON.stringify(object[field.field]) !==
              JSON.stringify(oldObject[field.field])
          ) {
            delta[field.backendField] = object[field.field];
          }
        }
      });
    }

    operationContainer.operation.oldObject = JSON.stringify(oldObject);
    operationContainer.operation.newObject = JSON.stringify(newObject);
    operationContainer.operation.delta = JSON.stringify(delta);
    return {
      objectConfig: objectConfig.objectConfig,
      operation: operationContainer.operation,
    };
  });

  return operations;
};

export const updateSyncObjectAndHeadersForGet = (
  syncObject,
  config,
  objectConfig,
) => {
  if (objectConfig.objectConfig.syncGroup.includes("_CORE_")) {
    return syncCore.updateSyncObjectAndHeadersForGetCore(
      syncObject,
      config,
      objectConfig,
    );
  }
  if (objectConfig.objectConfig.syncGroup.includes("_CE7")) {
    return syncCE7.updateSyncObjectAndHeadersForGetCE7(
      syncObject,
      config,
      objectConfig,
    );
  } else {
    return synCRM.updateSyncObjectAndHeadersForGetCore(
      syncObject,
      config,
      objectConfig,
    );
  }
};

export const updateSyncObjectAndHeaders = (
  syncObject,
  headers,
  objectConfig,
  response,
) => {
  if (objectConfig.objectConfig.syncGroup.includes("_CORE_")) {
    return syncCore.updateSyncObjectAndHeadersCore(
      syncObject,
      headers,
      objectConfig,
      response,
    );
  }
  if (objectConfig.objectConfig.syncGroup.includes("_CE7")) {
    return syncCE7.updateSyncObjectAndHeadersCE7(
      syncObject,
      headers,
      objectConfig,
      response,
    );
  } else {
    return syncCRM.updateSyncObjectAndHeadersCore(
      syncObject,
      headers,
      objectConfig,
      response,
    );
  }
};
