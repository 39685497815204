import { render, staticRenderFns } from "./DraftMail.vue?vue&type=template&id=3505271f&scoped=true"
import script from "./DraftMail.vue?vue&type=script&lang=js"
export * from "./DraftMail.vue?vue&type=script&lang=js"
import style0 from "./DraftMail.vue?vue&type=style&index=0&id=3505271f&prod&scoped=true&lang=css"
import style1 from "./DraftMail.vue?vue&type=style&index=1&id=3505271f&prod&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3505271f",
  null
  
)

export default component.exports