<template>
  <div>
    <v-snackbar
      v-model="showAttachmentSnackbar"
      :timeout="null"
      elevation="12"
      rounded="lg"
      color="primary"
      class="attachment-snackbar"
      :class="isMobile && 'mobile'"
      bottom
      :right="!isMobile"
      :dark="isDark"
      absolute
    >
      <!-- <div class="text-body-2 primaryText--text px-4 pt-4 font-weight-medium">Uploading Files</div> -->
      <v-list
        class="rounded-lg"
        color="transparent"
        :dark="isDark"
      >
        <v-subheader class="white--text">Uploading Files</v-subheader>
        <v-list-item
          v-for="attachment in getAttachments"
          :dark="isDark"
          :key="attachment.guid"
        >
          <v-list-item-avatar
            size="40"
            class="primary lighten-2"
          >
            <div v-if="getThumbnail(attachment)"></div>
            <v-img
              v-if="attachment.thumbnail"
              :src="attachment.thumbnail"
              max-width="40"
              max-height="40"
            ></v-img>
            <img
              v-else
              :src="getIcon(attachment.attachmentType)"
              class="attachmentIcon"
              width="24"
              height="24"
              aspect-ratio="1"
            >
          </v-list-item-avatar>

          <v-list-item-content>
            <v-list-item-title
              class="caption white--text"
              v-html="attachment.name"
            ></v-list-item-title>
            <v-list-item-subtitle class="caption white--text opaque-7">
              {{ getSize(attachment) }}{{ attachment.status }}
            </v-list-item-subtitle>

          </v-list-item-content>
          <v-list-item-action style="max-width: 100px; flex: 1;">
            <v-row
              align="center"
              class="flex-nowrap"
            >
              <v-progress-linear
                style="width: 60px;"
                color="white"
                buffer-value="0"
                :value="attachment.progress"
                rounded
                stream
              ></v-progress-linear>
              <div
                style="width: 40px; text-align: end;"
                class="text-capton pl-2"
              >{{ attachment.progress.toFixed(0) }}%</div>
            </v-row>
          </v-list-item-action>
        </v-list-item>
      </v-list>
    </v-snackbar>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import pdfIcon from "../../assets/Icons/types/pdf.svg"
import JpgIcon from "../../assets/Icons/types/jpg.svg"
import zipIcon from "../../assets/Icons/types/zip.svg"
import excelIcon from "../../assets/Icons/types/excel.svg"
import xlsIcon from "../../assets/Icons/types/xls.svg"
import wordIcon from "../../assets/Icons/types/word.svg"
import txtIcon from "../../assets/Icons/types/text.svg"
import { isEmpty } from '../../api/utilities';

export default {
  data() {
    return {
      pdfIcon,
      JpgIcon,
      zipIcon,
      excelIcon,
      wordIcon,
      txtIcon,
      xlsIcon,
    };
  },
  computed: {
    ...mapGetters(['getAttachments', 'showAttachmentSnackbar', 'isDark', 'getDeviceSize']),
    isMobile() {
      return this.getDeviceSize === 1
    },
  },
  methods: {
    getIcon(type) {
      console.log(type)
      if (type.includes('pdf')) {
        return pdfIcon
      }
      if (type === 'text/csv') {
        return excelIcon
      }
      if (type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
        return xlsIcon
      }
      if (type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') {
        return wordIcon
      }
      if (type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') {
        return wordIcon
      }
      if (type === 'text/plain') {
        return txtIcon
      }
      if (type.includes('image')) {
        return JpgIcon
      }
      return JpgIcon
    },
    getSize(attachment) {
      if (attachment && attachment.file && attachment.file.size) {
        return `${(attachment.file.size / (1024 * 1024)).toFixed(2)}Mb · `;
      }
      return ''
    },
    getThumbnail(attachment) {
      if (!attachment.attachmentType.includes('image') || isEmpty(attachment.file)) {
        return
      }
      var fr = new FileReader();
      fr.onload = (e) => {
        const thumb = e.target.result;
        this.$set(attachment, 'thumbnail', thumb)

      }
      fr.readAsDataURL(attachment.file);

    }
  }
};
</script>

<style>
.attachment-snackbar .v-snack__wrapper,
.attachment-snackbar .v-snack__content {
  padding: 0;
  /* background-color: var(--v-primary-lighten5); */
}

.attachment-snackbar.mobile {
  margin-bottom: 80px;
}

img.attachmentIcon {
  width: 24px;
  height: 24px;
  border-radius: 0;
}
</style>

