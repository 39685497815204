<template>
  <v-card
    class="mx-0 pa-0 kingSize transparent"
    flat
    :class="container && 'ma-4'"
  >
    <label v-show="showHeader"> {{ header.text }}</label>
    <v-card
      class="transparent readOnly"
      v-show="isReadOnly"
      flat
    >
      <vue-editor
        v-model="object[header.value]"
        :editor-toolbar="emptyToolBar"
        :disabled="true"
        :style="getStyle"
      />
    </v-card>
    <v-card
      class="transparent editMode"
      v-show="!isReadOnly"
      flat
    >
      <vue-editor
        v-model="object[header.value]"
        :editor-toolbar="toolbar"
        :placeholder="label"
        :disabled="false"
        :style="getStyle"
      />
    </v-card>
  </v-card>
</template>

<script>
import { VueEditor } from "vue2-editor";

export default {
  components: {
    VueEditor
  },
  props: {
    viewconfig: {
      type: String,
      default: ""
    },
    header: {
      type: Object,
      default: () => ({})
    },
    object: {
      type: Object,
      default: () => ({})
    },
    height: {
      type: String,
      default: "300px"
    },
    readonly: {
      type: Boolean,
      default: true
    },
    container: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      default: ""
    },
  },
  data() {
    return {
      content: "<h1>Some initial content</h1>",
      toolbar:
        [
          // [{ 'font': [] }],
          [{ 'header': [false, 1, 2, 3, 4, 5, 6,] }],
          // [{ 'size': ['small', false, 'large', 'huge'] }],
          ['bold', 'italic', 'underline', 'strike'],
          [{ 'align': '' }, { 'align': 'center' }, { 'align': 'right' }, { 'align': 'justify' }],
          // [{ 'header': 1 }, { 'header': 2 }],
          ['blockquote', 'code-block'],
          [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'list': 'check' }],
          [{ 'script': 'sub' }, { 'script': 'super' }],
          [{ 'indent': '-1' }, { 'indent': '+1' }],
          // [{ 'color': [] }, { 'background': [] }],
          ['link'],
          // [{ 'direction': 'rtl' }],
          // ['clean'],
        ]
    };
  },
  computed: {

    emptyToolBar() {
      return [[], [], []];
    },
    isReadOnly() {
      return this.readonly;
    },
    showHeader() {
      return (
        this.header.text === null ||
        this.header.text === undefined ||
        this.header.text === ""
      );
    },
    getHeight() {
      if (this.height !== null && this.height !== undefined) {
        var height = parseInt(this.height, 10);
        return height - 20;
      }
      return 300;
    },
    getStyle() {
      return "height: ".concat(this.getHeight.toString().concat("px; "));
    },
    getId() {
      var id = "editor" + this.getHeight;
      return id;
    }
  }
};
</script>

<style>
.ql-snow {
  padding-top: 4px !important;
}

.ql-container {
  height: 95% !important;
  min-height: 150px;
}

.greenColorBack {
  background-color: green !important;
}
</style>
