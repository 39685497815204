<template>
  <v-app
    id="app"
    toolbar
    color="topBarBackgroundColor"
    :dark="isDark"
  >
    <ActionDialog />
    <top-bar :is-mobile="isMobile" />

    <LeftMenu v-if="!isMobile" />
    <right-menu :is-mobile="isMobile" />
    <!--<rightConfigurationMenu />-->
    <v-main
      class="pb-0 pt-0"
      style="height: 100%;"
    >

      <v-container
        class="ma-0 pa-0"
        fluid
      >
        <router-view v-if="!getShowPinnedDialog" />

      </v-container>

    </v-main>

    <bottom-bar v-if="!isMobile" />
    <bottom-bar-mobile v-if="isMobile" />
    <div
      v-if="!isMobile"
      @mouseover="hover"
      @mouseleave="unhover"
      class="sideHover"
      open-delay="500"
    />
  </v-app>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

import BottomBar from './BottomBar.vue';
import BottomBarMobile from './BottomBarMobile.vue';
import LeftMenu from './LeftMenu.vue';
import RightMenu from './RightMenu.vue';
import TopBar from './TopBar.vue';
import ActionDialog from './ActionDialog.vue'
import { isEmpty, isEmptyArray } from '../../api/utilities';
import { TENANT } from '../../api/constants';

export default {
  components: {
    TopBar,
    LeftMenu,
    RightMenu,
    BottomBar,
    BottomBarMobile,
    LeftMenu,
    ActionDialog,
    LeftMenu
  },
  data() {
    return {
      dialog: false,
      show: false,
      hovering: false,
    };
  },
  computed: {
    ...mapGetters(['isDark', 'getTenant', 'getShowPinnedDialog']),
    getShowConfigDialog() {
      return this.$store.getters.getShowConfigDialog;
    },
    isMobile() {
      return this.$store.getters.getDeviceSize === 1
    },

  },
  created() {
    // this.$vuetify.theme.dark = this.isDark;

    this.$vuetify.theme.dark = this.isDark;

  },
  methods: {
    ...mapActions(["toggleRightDrawer"]),
    hover() {
      if (this.hovering === false) {
        this.hovering = true
        this.show = setTimeout(() => {
          this.toggleRightDrawer()
        }, 400);
      }
    },
    unhover() {
      clearTimeout(this.show)
      this.hovering = false
    },

  }
};
</script>

<style>
/* .main {
    padding-top: 0px !important;
  } */
.sideHover {
  position: absolute;
  width: 2px;
  height: 100vh;
  top: 0;
  right: 0;
}
</style>
