/**
 * App State
 */

import Vue from "vue";
import { API_BASE_URL, AUTH_URL, TENANT } from "../api/constants.js";
import jwt_decode from "jwt-decode";
import { APP_STATE_LOGOUT, SET_APP_STATE } from "./appContext.js";

import {
  setPersistence,
  signInWithEmailAndPassword,
  getIdToken,
  signOut,
  indexedDBLocalPersistence,
  signInWithPopup,
  GoogleAuthProvider,
  sendPasswordResetEmail,
  confirmPasswordReset,
  verifyPasswordResetCode,
} from "firebase/auth";
import { firebaseAuth, provider } from "../integrations/google/firebase.js";
import Axios from "axios";

export const ACCESS_TOKEN = "accessToken";
export const REFRESH_TOKEN = "refreshToken";
export const USER_LOCAL_STORAGE = "swi_u";
export const TOKEN_LOCAL_STORAGE = "swi_t";
export const INVALID_EMAIL = "auth/wrong-email";
export const INVALID_PASSWORD = "auth/wrong-password";
export const USER_NOT_FOUND = "auth/user-not-found";

export default {
  state: {
    auth: firebaseAuth,
  },
  mutations: {
    _logout(state) {
      window.localStorage.removeItem(USER_LOCAL_STORAGE);
      window.localStorage.removeItem(TOKEN_LOCAL_STORAGE);
      Vue.set(state, "tokens", null);
    },
  },
  getters: {
    getAuth(state) {
      return state.auth;
    },
    getCurrentUser(state) {
      return state.auth.currentUser;
    },
    isAuthenticated: (state, getters) => {
      return state.auth.currentUser !== null;
    },
    getTenantToken: async (state, getters) => {
      return getters.getCurrentUser && getters.getCurrentUser.tenantId;
    },
  },
  actions: {
    setFirebaseTenant({ getters }) {
      firebaseAuth.tenantId = getters.getTenant && getters.getTenant.firebaseId;
    },
    async checkTenantAuth({ commit, getters }) {
      const tenantToken = await getters.getTenantToken;
      const tenant = getters.getTenant;
      console.log("tenantToken", tenantToken, "tenant", tenant);
      if (tenantToken && tenant && !tenantToken.includes(tenant)) {
        commit("setTenant", null);
      }
    },
    initAuth({ commit }) {
      if (window) {
        try {
          const tokens = window.localStorage.getItem(TOKEN_LOCAL_STORAGE);
          if (tokens) {
            const parsedTokens = JSON.parse(tokens);
            commit("_setUser", parsedTokens);
          }
        } catch (error) {
          console.log(error);
        }
      }
    },
    async getAccessTokenToken({ dispatch, commit, getters }, payload) {
      if (payload.init === true) {
        firebaseAuth.tenantId = null;
      } else {
        dispatch("setFirebaseTenant");
      }

      return new Promise((resolve, reject) => {
        setPersistence(firebaseAuth, indexedDBLocalPersistence)
          .then(async () => {
            // Existing and future Auth states are now persisted in the current
            // session only. Closing the window would clear any existing state even
            // if a user forgets to sign out.
            // ...
            // New sign-in will be persisted with session persistence.
            signInWithEmailAndPassword(
              firebaseAuth,
              payload.username,
              payload.password,
            )
              .then((userCredential) => {
                // Signed in

                const user = userCredential.user;
                resolve(user);
              })
              .catch((error) => {
                console.log(error);
                let errorMessage = `Something went wrong: ${error.code}`;
                if (
                  error.code === INVALID_EMAIL ||
                  error.code === INVALID_PASSWORD
                ) {
                  errorMessage = "Wrong credentials. Please try again.";
                }
                commit("showAlert", { type: "error", message: errorMessage });
                reject(error);
              });
          })
          .catch((error) => {
            // Handle Errors here.
            const errorCode = error.code;
            const errorMessage = error.message;
          });
      });
    },
    async loginWithGoogle({ commit, getters }, payload) {
      dispatch("setFirebaseTenant");
      setPersistence(firebaseAuth, indexedDBLocalPersistence).then(async () => {
        signInWithPopup(firebaseAuth, provider)
          .then((result) => {
            // This gives you a Google Access Token. You can use it to access the Google API.
            const credential = GoogleAuthProvider.credentialFromResult(result);
            const token = credential.accessToken;
            // The signed-in user info.
            const user = result.user;
            // ...
          })
          .catch((error) => {
            // Handle Errors here.
            const errorCode = error.code;
            const errorMessage = error.message;
            // The email of the user's account used.
            const email = error.customData.email;
            // The AuthCredential type that was used.
            const credential = GoogleAuthProvider.credentialFromError(error);
            // ...
          });
      });
    },
    async resetPasswordFromEmail({ dispatch }, payload) {
      const url = `${API_BASE_URL}reset-password`;
      const body = {
        tenant: TENANT,
        email: payload.email,
      };
      return Axios.post(url, body, {});
    },
    async verifyCode({ dispatch }, payload) {
      dispatch("setFirebaseTenant");
      return verifyPasswordResetCode(firebaseAuth, payload.code);
    },
    async setPassword({ dispatch }, payload) {
      dispatch("setFirebaseTenant");
      return confirmPasswordReset(firebaseAuth, payload.code, payload.password);
    },
    async logout({ dispatch, commit, getters }, payload) {
      dispatch("setFirebaseTenant");
      signOut(getters.getAuth)
        .then(() => {
          // Sign-out successful.
        })
        .catch((error) => {
          // An error happened.
        });
      commit("_logout");
      commit("setAppLoginStatus", 0);
      commit(SET_APP_STATE, APP_STATE_LOGOUT);
    },
  },
};

export const getAccessToken = (user) => {
  return getIdToken(user);
};

export const isTokenValid = (token) => {
  if (token) {
    try {
      const access_token = jwt_decode(token);

      return Date.now() < access_token.exp * 1000;
    } catch (error) {
      console.log(error);
      return false;
    }
  }
  return false;
};
