<template>
  <v-container style="width: auto;">
    <v-row
      align="center"
      class="justify-end flex-nowrap"
    >
      <v-btn
        v-if="showEditButton"
        class="iconButton mx-1"
        icon
        @click.stop="goToEditMode"
      >
        <v-icon class="configLight">hi-pencil-square-outline</v-icon>
      </v-btn>

      <slot v-if="!isEditMode" />
      <v-btn
        v-if="isEditMode"
        class="iconButton mx-1"
        icon
        @click.stop="cancel"
      >
        <v-icon class="configLight">hi-x-mark-outline</v-icon>
      </v-btn>
      <v-btn
        v-if="isEditMode"
        class="primary mx-1"
        icon
        @click.stop="save"
      >
        <v-icon
          light
          class="configLight white--text"
        >hi-check-outline</v-icon>
      </v-btn>

      <v-menu
        v-if="!isEditMode && hideMenu"
        :close-on-click="true"
        :rounded="'lg'"
        content-class="menu-elevation"
        transition="slide-y-transition"
        offset-y
        bottom
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-bind="attrs"
            class="iconButton mx-1"
            icon
            v-on="on"
          >
            <v-icon class="configLight">hi-ellipsis-vertical-outline</v-icon>
          </v-btn>
        </template>
        <v-list dense>
          <v-list-item
            v-for="(item, index) in getActions"
            :key="index"
            :ref="item.reference"
            @click="clickMenu(item)"
          >
            <v-list-item-action>
              <v-icon>
                {{ item.icon }}
              </v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item-content>
            <v-list-item-action v-if="item.isToggle === true">
              <v-switch
                :input-value="item.value"
                value
                small
                inset
              />
            </v-list-item-action>
          </v-list-item>
          <v-list-item
            v-for="(item, index) in getOptionsActions"
            :key="index"
            :ref="item.reference"
            @click="clickAction(item)"
          >
            <v-list-item-action>
              <v-icon>
                {{ item.icon }}
              </v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>{{ item.name }}</v-list-item-title>
            </v-list-item-content>

          </v-list-item>
        </v-list>
      </v-menu>
    </v-row>
    <v-dialog
      :max-width="dialogWidth"
      v-model="dialog.show"
      content-class="rounded-lg"
      persistent
    >
      <v-card
        :max-width="dialogWidth"
        class="pa-3 pa-3 no-scroll rounded-lg"
      >

        <viewConfigView
          :viewconfig="viewConfigOfSelection"
          :object="selectedObject"
          :operationid="operationId"
          :editmode="true"
          :dialog="dialog"
          :key="componentKey"
        />

        <!--<v-card-actions>
          <v-spacer/>
          <v-btn 
            color="primary" 
            @click="saveObject()">save</v-btn>
          <v-btn 
            color="primary" 
            @click="cancelSelection()">Cancel</v-btn>
        </v-card-actions>-->
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
import * as Constants from "../../store/constants.js";
import * as UTIL from "../../api/utilities.js";
import * as Theme from "../../theme/themeDefault.js";
import jsPDF from "jspdf";
import {
  CAN_CREATE,
  CAN_EDIT
} from "../../store/authorizationObjectContext.js";

export default {
  props: {
    viewconfig: {
      type: String,
      default: null
    },
    object: {
      type: Object,
      default: () => ({})
    },
    filterItems: {
      type: Array,
      default: null
    },
    issmall: {
      type: Boolean,
      default: false
    },
    readOnly: {
      type: Boolean,
      default: false
    },
    allowCreate: {
      type: Boolean,
      default: false
    },
    dropzoneElement: {
      type: String,
      default: null
    },
    pdfElement: {
      type: HTMLDivElement,
      default: null
    }
  },
  data() {
    return {
      dialogTitle: "Title",
      dialog: {
        show: null
      },
      dialogWidth: 800,
      hasSelectionView: false,
      selectedObject: null,
      viewConfigOfSelection: "",
      operationId: null,
      color: Theme.ICON_COLOR_LIGHT,
      componentKey: 0,
      hasCsvUpload: false
    };
  },
  computed: {
    canEdit() {
      if (this.getViewConfig.type === "Board") {

        return false
      }
      return !this.readOnly && !this.getOptionsReadOnly && this.canEditFromAuthorisations(CAN_EDIT);
    },
    canCreate() {
      return !this.readOnly && this.getOptionsCanCreate && this.canEditFromAuthorisations(CAN_CREATE);
    },
    isEditMode() {
      return this.$store.getters.getEditMode(this.viewconfig);
    },
    showEditButton() {
      return !this.isEditMode & this.canEdit & !this.issmall;
    },
    getOptions() {
      if (this.getViewConfig && this.getViewConfig.options) {
        console.log('options', this.getViewConfig.options)
        try {
          const options = JSON.parse(this.getViewConfig.options)
          return options
        } catch (error) {
          console.error(error)
          return null
        }
      }
      return null
    },
    getOptionsReadOnly() {
      if (this.getOptions && !UTIL.isEmpty(this.getOptions.readOnly)) {
        return this.getOptions.readOnly
      }
      return false
    },
    getOptionsCanCreate() {
      if (this.getOptions && !UTIL.isEmpty(this.getOptions.canCreate)) {
        return this.getOptions.canCreate
      }
      return true
    },
    getOptionsActions() {
      if (this.getOptions) {
        return this.getOptions.customActions
      }
      return []
    },
    getActions() {
      var actions = [];
      var action = {
        title: "Show all fields",
        value: this.getToggleExpandState,
        isToggle: true,
        icon: "hi-arrows-pointing-out-outline",
        viewConfig: "viewConfig",
        filter: "",
        method: "toggleFieldList"
      };
      actions.push(action);

      if (this.canCreate) {
        var action = {
          Guid: "ADD_ACTION",
          title: "Add",
          icon: "hi-plus-circle-outline",
          viewConfig: "viewConfig",
          filter: "",
          method: "addObject"
        };
        action.reference = this.getReference(action.Guid)
        actions.push(action);
      }

      if (this.canEdit) {
        if (this.issmall) {
          var action = {
            Guid: "EDIT_ACTION",
            title: "Edit",
            icon: "hi-pencil-square-outline",
            filter: "",
            method: "goToEditMode"
          };
          action.reference = this.getReference(action.Guid)
          actions.push(action);
        }

        var action = {
          Guid: "MASS_UPDATe_ACTION",
          title: "Mass update",
          icon: "hi-pencil-outline",
          filter: "",
          method: "openMassUpdate"
        };
        action.reference = this.getReference(action.Guid)
        actions.push(action);

        if (this.hasCsvUpload) {
          var action = {
            Guid: "CSV_ACTION",
            title: "Upload CSV",
            icon: "hi-document-arrow-up-outline",
            filter: "",
            method: "upload"
          };
          action.reference = this.getReference(action.Guid)
          actions.push(action);
        }
      }

      if (this.pdfElement) {
        var action = {
          Guid: "EXPORTPDF_ACTION",
          title: "Export to Pdf",
          icon: "hi-document-arrow-down-outline",
          filter: "",
          method: "exportToPdf"
        };
        action.reference = this.getReference(action.Guid)
        actions.push(action);
      }

      return actions;
    },
    hideMenu() {
      // don't show menu if following settings
      return (
        this.dropzoneElement === null || this.dropzoneElement === undefined
      );
    },
    getViewConfig() {
      return this.$store.getters.getViewConfigForID(this.viewconfig);
    },
    getCurrentOperationContainer() {
      return this.$store.getters.getCurrentOperationContainer;
    },
    getObjects() {
      if (!UTIL.isEmpty(this.list)) {
        return this.list;
      }
      var items = this.$store.getters.getDataObjectsForObjectType(
        this.getObjectType
      );
      return items;
    },
    getCurrentObject() {
      if (this.object !== null && this.object !== undefined) {
        return this.object;
      }
      return this.$store.getters.getCurrentObject;
    },
    getFilteredObjects() {
      if (this.filterItems) {
        return this.filterItems;
      }
      var objects = this.getObjects;
      var payload = {
        objects: objects,
        viewConfig: this.getViewConfig,
        object: this.getCurrentObject,
        isEdit: this.isEditMode
      };
      var filteredObject = this.$store.getters.getFilteredObjectsForViewConfig(
        payload
      );
      //Todo filter
      return filteredObject;
    },
    getObjectType() {
      return this.getViewConfig.objectType;
    },
    getObjectConfig() {
      return this.$store.getters.getObjectConfigForType(
        this.getViewConfig.objectType
      );
    },
    getToggleExpandState() {
      var toggle = this.$store.getters.getCustomSize(this.viewconfig);
      return !UTIL.isEmpty(toggle) && toggle.size > 4;
    }
  },
  mounted: function () {
    this.checkHasCsvUpload();
    this.$nextTick(() => { });
  },
  methods: {
    clickAction(action) {
      if (this[action.method]) {
        this[action.method](action.options)
      }
    },
    getReference(action) {
      var reference = this.viewconfig + action
      return reference
    },
    async checkHasCsvUpload() {
      var csvUploaderConfiguration = await this.launchAction(
        "getCSVUploaderConfiguration"
      );
      if (!UTIL.isEmpty(csvUploaderConfiguration)) {
        this.hasCsvUpload = true;
      } else {
        this.hasCsvUpload = false;
      }
    },
    async upload() {
      var csvUploaderConfiguration = await this.launchAction(
        "getCSVUploaderConfiguration"
      );
      if (!UTIL.isEmpty(csvUploaderConfiguration)) {
        this.viewConfigOfSelection = csvUploaderConfiguration.csvViewconfig;
        this.dialogWidth = 1800;
        this.dialogTitle = "Upload ".concat(this.getViewConfig.objectType);
        this.hasSelectionView = true;
        this.dialog.show = true;
        this.componentKey += 1;
      } else {
        this.viewConfigOfSelection = "";
      }
    },
    canEditFromAuthorisations(action) {
      var payload = {
        objectType: this.getViewConfig.objectType,
        action: action,
        viewConfig: this.viewconfig,
        object: this.getCurrentObject
      };
      return this.$store.getters.userHasAuthorisation(payload);
    },

    clickMenu(item) {
      this[item.method](item);
    },
    /**
     * Expand the list
     */
    toggleFieldList() {
      this.$store.commit("toggleCustomSize", this.viewconfig);
    },




    /**
     * Add the option.
     */
    addObject(item) {
      var config = this.getObjectConfig;
      // Get the viewConfig
      this.viewConfigOfSelection = this.getViewConfig.objectType.concat(
        "ViewDetailsconfig"
      );

      // Create the operation object
      this.operationId = UTIL.getGuid();
      var payload = {
        object: null,
        config: config,
        id: this.operationId
      };
      this.$store.dispatch(Constants.CREATE_OPERATION_CONTAINER, payload);

      // var retrieve the object from the operation and do the modifications.
      var newObject = this.$store.getters.getOperationContainerForId(
        this.operationId
      ).newObject;

      var relatedObject = {
        object: this.object,
        viewConfig: this.getViewConfig
      };

      var payload = {
        viewconfig: this.viewConfigOfSelection,
        operationId: this.operationId
      };
      this.$store.commit("changeEditMode", payload);
      newObject = config.afterCreate(newObject, relatedObject, this.$store);

      /*
        New
      */

      var button = this.$refs[item.reference];

      if (!UTIL.isEmpty(button)) {
        const width = 850
        var rect = button[0].$el.getBoundingClientRect()
        var btnX = rect.left + (rect.width / 2)
        var btnY = rect.top + (rect.height / 2)
        var centerX = screen.width > 850 ? (screen.width / 2) - (width / 2) : 24
        var centerY = screen.height > 850 ? (screen.height / 2) - (width / 2) : 24
        var distX = (centerX - btnX) / 2
        var distY = (centerY - btnY) / 2
        var x = btnX + distX
        var y = btnY + distY
        var origin = { x, y }

      }

      var _this = this

      this.$store.dispatch("showActionDialog", {
        viewConfig: _this.viewConfigOfSelection,
        object: newObject,
        objectType: _this.getViewConfig.objectType,
        origin: origin,
        operationId: this.operationId
      });

      /*
      this.dialogWidth = 800;
      this.hasSelectionView = true;
      this.dialog.show = true;
      this.componentKey += 1;
      */
    },

    goToEditMode() {
      //Set edit mode for the view.
      //Go to create operationContainers.
      // Retrieve the objects
      this.operationId = this.$store.getters.generateOperationId;

      var payload = {
        viewconfig: this.viewconfig,
        operationId: this.operationId
      };
      this.$store.commit("changeEditMode", payload);

      var objects = this.getFilteredObjects;

      var objectPayload = {
        objects: objects,
        operationId: this.operationId,
        config: this.getObjectConfig,
        viewconfig: this.viewconfig
      };
      this.$store.dispatch(
        "createOperationContainersForObjects",
        objectPayload
      );
    },
    goToDisplayMode() {
      var payload = {
        viewconfig: this.viewconfig,
        edit: null
      };
      this.$store.commit("changeEditMode", payload);
    },
    openMassUpdate() {
      var payload = {
        massUpdateViewConfig: this.viewconfig,
        massUpdate: true
      };
      this.$store.dispatch("goToMassUpdate", payload);
    },
    cancel() {
      this.goToDisplayMode();
    },
    save() {
      this.saveOperations();
      this.goToDisplayMode();
    },
    saveOperations() {
      var objectPayload = {
        operationId: this.operationId,
        viewconfig: this.viewconfig
      };
      this.$store.dispatch("saveOperationsforOperationListId", objectPayload);
    },
    exportToPdf() {
      var doc = new jsPDF("p", "pt", [793.706, 1122.52]);
      // doc.setFont("arial", "bold");
      // doc.setFontSize(14);
      const margins = {
        top: 40,
        bottom: 60,
        left: 20
      };

      doc.html(this.pdfElement.innerHTML, {
        bottom: 60,
        callback: function (doc) {
          doc.save();
        }
      });
    },

    closeDialog() {
      this.dialog.show = false;
    },
    addInbox(options) {
      this.$store.commit('showAddInboxDialog')
    },
    getActionsForConfig() { },
    async launchAction(method) {
      var object = this.object;
      var object1 = this.currentObject;
      var objectConfig = this.$store.getters.getObjectConfigForType(
        this.getViewConfig.objectType
      );
      var payload = {
        action: method,
        objectConfig: objectConfig,
        object: this.currentObject,
        viewConfig: this.getViewConfig,
      };
      var result = await this.$store.dispatch(
        "launchActionForObjectAndConfig",
        payload
      );
      return result;
    }
  }
};
</script>
