import { isEmpty } from "./utilities";

export const ACTIVE_TENANT = "sw_at";

export const DEVELEPMENT = "dev";
export const STAGING = "stg";
export const PRODUCTION = "production";

const SWIFTIO = "swiftio";

const savedTenant =
  window &&
  window.localStorage.getItem(ACTIVE_TENANT) &&
  JSON.parse(window.localStorage.getItem(ACTIVE_TENANT));
const devTenant =
  savedTenant && savedTenant.handle ? savedTenant.handle : "swiftio";
// constants/envrionment.js

let realm = SWIFTIO;
let env = DEVELEPMENT;
const url = window.location.host;
// const url = "app.swiftio.io";
const regexDomain = /^([^./]+)\./;
const match = url.match(regexDomain);

if (!isEmpty(match) && !isEmpty(match[1])) {
  realm = match[1];
  const regex = /-staging/;
  const result = realm.match(regex);
  if (result) {
    env = STAGING;
    realm = realm.replace("-staging", "");
  } else {
    env = PRODUCTION;
  }
}
if (realm === "app" || realm === "192") realm = SWIFTIO;
const NODE_ENV = env;
console.log("realm", realm, env);
// realm = realm.replace("-dev", "");

const TENANT = NODE_ENV === DEVELEPMENT ? devTenant : realm;

const BASE_URL =
  env === STAGING ? process.env.BASE_URL_STAGING : process.env.BASE_URL;
const API_BASE_URL = `${BASE_URL}/api/`;

// const API_BASE_URL = `https://api-staging.swiftio.io/api/`;

const FACUURSTUREN_BASE_URL = `${BASE_URL}/factuurSturen/`;

const AUTH_URL = `${BASE_URL}/token`;
const GOOGLE_URL = `${BASE_URL}/google`;

export {
  BASE_URL,
  API_BASE_URL,
  TENANT,
  NODE_ENV,
  AUTH_URL,
  FACUURSTUREN_BASE_URL,
  GOOGLE_URL,
};
