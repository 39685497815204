<template>
  <v-app
    id="app1"
    :class="notAndroid ? 'androidSafe' : 'isAndroid'"
    :dark="isDark"
  >

    <v-main
      v-if="hideLayout"
      class="pb-0 pt-0"
      style="height: 100vh;"
    >


      <router-view />



    </v-main>

    <!-- <create-tenant
      :isAuthenticated="isAuthenticated"
      v-if="isFirebaseInit && isCreateTenant"
    /> -->


    <v-container
      v-else-if="isFirebaseInit"
      ref="appContainer"
      :style="styleObject"
      height="1000px"
      fill-width
      class="pa-0 ma0 transparent"
      fluid
    >
      <resize-observer @notify="onResizeComponent" />

      <transition
        name="fade"
        duration="200"
      >
        <splash v-if="!isAuthenticated" />

      </transition>
      <transition
        name="fade"
        duration="200"
      >
        <mainScreen v-if="isAuthenticated && isAppStateEntered" />
      </transition>
      <transition
        name="fade"
        duration="200"
      >
        <loadingView
          :loading="isAppStateStarting"
          v-if="isAppStateStarting"
        />
      </transition>

    </v-container>
    <Alert />
    <SignatureDialog />
    <attachment-loader />
    <ItemSelectionDialog />
    <AddInboxDialog />
    <ComposeMailDialog />
    <LoadingDialog />
  </v-app>
</template>

<script>
/*import backgroundImage_ from "./assets/bg_image.jpg";
import backgroundImage__ from "./assets/tablet.jpg";
*/
//import backgroundImage from "./assets/BgImage/business2.jpg";
import * as Actions from "./store/constants.js";
import mainScreen from "./components/mainComponent/Main.vue";
import login from "./components/components/Login.vue";
import loadingView from "./components/components/LoadingView";
require("vue2-animate/dist/vue2-animate.min.css");
import { APP_STATE_ENTERED, APP_STATE_STARTING, DEVICE_TYPE_ANDROID, DEVICE_TYPE_BROWSER, DEVICE_TYPE_IOS } from "./store/appContext"
import AttachmentLoader from './components/components/AttachmentLoader.vue';

import { mapGetters, mapMutations } from "vuex";
import Alert from "./components/components/Alert.vue";
import SignatureDialog from "./components/components/SignatureDialog.vue";
import { isEmpty, isTrue } from './api/utilities.js';
import splash from "./components/components/Splash.vue";
import CreateTenant from './router/tenant/createTenant.vue';
import ItemSelectionDialog from './components/mainComponent/ItemSelectionDialog.vue'
import AddInboxDialog from "./components/mainComponent/AddInboxDialog.vue";
import LoadingDialog from "./components/components/LoadingDialog.vue";
import ComposeMailDialog from "./components/mainComponent/ComposeMailDialog.vue";
export default {
  components: { mainScreen, login, loadingView, Alert, SignatureDialog, splash, CreateTenant, AttachmentLoader, ItemSelectionDialog, AddInboxDialog, LoadingDialog, ComposeMailDialog },
  data() {
    return {
      show: true,
      deviceType: DEVICE_TYPE_BROWSER,
      current: 0,
      current1: 1,
      use3d: true,
      myContainerWidth: 0,
      messages: "",
      styleObject: {
        //background: "url(" + backgroundImage + ")" + "no-repeat right top",
        height: "100vh",
        width: "100%"
      }
    };
  },

  computed: {
    ...mapGetters(["isAuthenticated", "getDeviceType", "getAuth", 'isDark', 'getAppLoginStatus', 'getLeftMenu', 'getTenant']),
    // isAuthenticated() {
    //   if (this.$store.getters.tokens && this.$store.getters.tokens.acce) {
    //     return isTokenValid(this.$store.getters.token)
    //   } return false
    // },
    isCreateTenant() {
      console.log('this.$router', this.$route.name)
      return this.$route.name === 'getStarted'
    },
    hideLayout() {
      return this.$route.meta && this.$route.meta.hideLayout === true
    },
    isFirebaseInit() {
      return this.getAuth && this.getAuth._isInitialized
    },
    notAndroid() {
      return this.deviceType !== DEVICE_TYPE_ANDROID
    },
    transitionName() {
      return (
        "sg-" + (this.use3d ? "3d-" : "") + (this.dir > 0 ? "right" : "left")
      );
    },
    getProgress() {
      return this.$store.getters.getAppLoginStatus;
    },
    isAppStateEntered() {
      return this.$store.getters.getAppState === APP_STATE_ENTERED || this.getAppLoginStatus > 100;
    },
    isAppStateStarting() {
      return this.$store.getters.getAppState === APP_STATE_STARTING && this.getAppLoginStatus <= 100;
    },
    getTitle() {
      var route = this.$store.getters["getCurrentRoute"]
      if (route) {
        var currentRoute = this.getLeftMenu.find(function (o) {
          return o.viewConfig === route && o.menuGroup !== "core" && isTrue(o.isActive);
        });
        if (currentRoute) {
          return currentRoute.title
        }
      }
      var path = /[^/]*$/.exec(window.location.pathname)[0];
      return path
    },
  },
  created() {

    if (localStorage.getItem('updating')) {
      localStorage.removeItem('updating')
      location.reload(true)
      return
    }
    setTimeout(() => {
      this.$store.dispatch('initGoogle')
    }, 3000)


    this.setTenantConfigs()


    // set the realm of the app
    console.log(navigator.userAgent)
    console.log(window.matchMedia('(display-mode: standalone)'))

    // if browser
    if (navigator.userAgent.match(/(iPhone|iPod|iPad)/)) {
      this.deviceType = DEVICE_TYPE_IOS
    } else if (navigator.userAgent.match(/(Android)/)) {
      this.deviceType = DEVICE_TYPE_ANDROID
    }

    this.getAuth._initializationPromise.then(value => {
      if (this.isCreateTenant) {
        return
      }
      this.initApp()

    })

    // }
    console.log('deviceType', this.deviceType)

  },
  mounted: function () {
    this.$nextTick(() => {
      this.onResizeComponent();
    });
    this.$router.afterEach((to, from) => {
      // Use next tick to handle router history correctly
      // see: https://github.com/vuejs/vue-router/issues/914#issuecomment-384477609
      this.$nextTick(() => {
        document.title = this.getTitle;
      });

    });
    if (isEmpty(this.getTenant)) {
      this.fetchTenants()
    }

  },
  methods: {
    ...mapMutations(["setDeviceType"]),
    initApp() {
      console.log('query', this.$route.query)
      if (this.$route.query.code && this.$route.query.scope) {
        this.$store.dispatch('googleAuthCode', this.$route.query)

      }
      this.$store.dispatch('checkTenantAuth')
      this.$store.commit(Actions.SET_APP_ROUTER, this.$router);
      if (!isEmpty(this.$store.getters.getCurrentUser)) {
        this.$store.dispatch("initApplication")
      }
      this.onResizeComponent();
      this.$store.dispatch("resetAutoSync");
    },
    async fetchTenants() {
      const tenant = await this.$store.dispatch('fetchTenants')
      if (!isEmptyArray(tenant)) {
        const activeTenant = tenant.find(item => item.handle === TENANT)
        if (activeTenant) {
          this.$store.commit('setTenant', activeTenant)
        }
      } else if (!isEmpty(tenant)) {
        this.$store.commit('setTenant', tenant)
      }
    },
    sgBeforeLeave(el) {
      var rect = el.getBoundingClientRect();
      el.style.width = rect.right - rect.left + "px";
      el.style.height = rect.bottom - rect.top + "px";
      el.style.position = "fixed";
      el.style.top = rect.top + "px";
      el.style.left = rect.left + "px";
    },
    onDeviceReady: function () {
      // Handle the device ready event.


      this.getAuth._initializationPromise.then(value => {
        if (this.isAuthenticated) {
          this.initApp()
        }
      })

    },
    onPause() {
      // Handle the pause lifecycle event.
      console.log("pause");
    },
    onResume() {
      // Handle the resume lifecycle event.
      // SetTimeout required for iOS.
      setTimeout(function () {
        console.log("resume");
      }, 0);
    },
    onBackKeyDown() {
      // Handle the back-button event on Android. By default it will exit the app.
      navigator.app.exitApp();
    },
    addMessage(message) {
      this.messages = this.messages + "\n" + message;
    },
    resetApplication() {
      this.$store.dispatch(Actions.DELETE_OBJECT_CONTEXT);
    },
    onResizeComponent() {
      var container = this.$refs.appContainer;
      var width = 0;
      var height = 0;
      if (container !== undefined && container !== null) {
        width = container.clientWidth;
        height = container.clientHeight;
      }
      if (
        this.myContainerWidth !== width &&
        width !== undefined &&
        width !== 0
      ) {
        //Write to store?
        this.myContainerWidth = width - 25;
        this.myContainerHeight = height;
        this.$store.commit("setDeviceSize", this.myContainerWidth);
        this.$store.commit("setMobile", this.myContainerWidth < Actions.SMALL_SIZE_THRESHOLD);
      }
    },
    setTenantConfigs() {
      this.$vuetify.theme.dark = this.isDark;
      const color = this.$vuetify.theme.themes[this.$vuetify.theme.dark ? 'dark' : 'light'].card
      document.querySelector('meta[name="theme-color"]').setAttribute('content', color);
      const tenant = this.getTenant.handle
      document.querySelector('#manifest').setAttribute('href', `/assets/tenants/${tenant}/manifest.json`);
      document.querySelector('#iphonexr_splash').setAttribute('href', `/assets/tenants/${tenant}/splashscreens/iphonexr_splash.png`);
      document.querySelector('#iphone5_splash').setAttribute('href', `/assets/tenants/${tenant}/splashscreens/iphone5_splash.png`);
      document.querySelector('#iphone6_splash').setAttribute('href', `/assets/tenants/${tenant}/splashscreens/iphone6_splash.png`);
      document.querySelector('#iphoneplus_splash').setAttribute('href', `/assets/tenants/${tenant}/splashscreens/iphoneplus_splash.png`);
      document.querySelector('#iphonex_splash').setAttribute('href', `/assets/tenants/${tenant}/splashscreens/iphonex_splash.png`);
      document.querySelector('#iphonexsmax_splash').setAttribute('href', `/assets/tenants/${tenant}/splashscreens/iphonexsmax_splash.png`);
      document.querySelector('#ipad_splash').setAttribute('href', `/assets/tenants/${tenant}/splashscreens/ipad_splash.png`);
      document.querySelector('#ipadpro1_splash').setAttribute('href', `/assets/tenants/${tenant}/splashscreens/ipadpro1_splash.png`);
      document.querySelector('#ipadpro2_splash').setAttribute('href', `/assets/tenants/${tenant}/splashscreens/ipadpro2_splash.png`);
      document.querySelector('#ipadpro3_splash').setAttribute('href', `/assets/tenants/${tenant}/splashscreens/ipadpro3_splash.png`);
      const link = document.querySelector("link[rel~='icon']")
      if (link) {
        link.href = `/assets/tenants/${tenant}/img/icons/favicon.ico`;
      }

    }
  }
};
</script>

<styles>

.stretch {
    width:100%;
    height:100%;
}

</styles>
