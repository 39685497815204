<template>
  <div
    class="d-flex align-items mx-2 pa-2"
    style="max-width: 50vw; overflow-x: auto;"
  >
    <v-btn
      v-for="item in getRecentItemsMain"
      :key="item.key"
      class="mx-1 sideBarColor "
      dark
      elevation="0"
      @click="navigateTo(item)"
    >
      <div
        style="max-width: 200px;"
        class="d-flex align-center gap"
      >
        <v-icon
          color="gray"
          small
        >
          hi-arrow-left-outline</v-icon>
        <v-icon
          color="primaryText"
          class="primaryText--text"
          small
        >
          {{ getImageIcon(item) }}</v-icon>
        <div
          class="flex-1"
          style="min-width: 0;"
        >
          <div class="text-ellipsis primaryText--text">{{ item.description }}</div>
        </div>
      </div>
    </v-btn>
    <v-menu
      v-if="getRecentItemsExtra"
      open-on-hover
      bottom
      offset-y
      nudge-bottom="2"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          class="sideBarColor px-4"
          dark
          v-bind="attrs"
          v-on="on"
          max-width="40px"
          min-width="0"
          elevation="0"
        >
          <v-icon
            small
            class="configLight primaryText--text"
          >
            hi-chevron-down-outline</v-icon>
        </v-btn>
      </template>

      <v-list class="px-2 card">
        <v-list-item
          v-for="item in getRecentItemsExtra"
          :key="item.key"
          tag="div"
          class="v-list-item--link rounded-lg px-3"
          style="overflow:hidden"
          @click.native.stop="navigateTo(item)"
        >

          <div v-if="getImage(item) === null">
            <v-list-item-avatar size="24px">
              <v-icon
                color="primaryText"
                class="material-icons-sharp"
                small
              >
                {{ getImageIcon(item) }}</v-icon>
            </v-list-item-avatar>
          </div>
          <v-list-item-content>
            <v-list-item-title :class="getLabelColorOfMenu(item)">
              <label :class="'primaryText--text'">
                {{ item.description }}
              </label>
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>

<script>
import * as Constants from "../../../store/constants.js";
import demo from "../../../demo/Images/demoImages.js";
import { isEmpty, isEmptyArray } from "../../../api/utilities";

export default {
  props: {
    object: {
      type: Object,
      default: null
    }
  },
  data: () => ({
    demo
  }),
  computed: {
    getRecentItems() {
      let items = structuredClone(this.$store.getters.getNavigations)
      if (this.object !== null && items) {
        return items.slice(1, items.length)
      }
      return items;
    },
    getRecentItemsMain() {
      const items = structuredClone(this.getRecentItems)

      if (!isEmptyArray(items) && items.length >= 2) {
        return items.slice(0, 1)
      }
      return items;
    },
    getRecentItemsExtra() {
      const items = structuredClone(this.getRecentItems)

      if (!isEmptyArray(items) && items.length >= 2) {
        return items.slice(1, items.length)
      }
      return null;
    }
  },

  methods: {
    /**
     * Navigate to the recent Item.
     */
    navigateTo(item) {
      var object = this.$store.getters.getDataObjectForIdAndObjectType({ objectType: item.objectConfig.objectType, objectId: item.object.Guid })
      if (isEmpty(object)) {
        context.commit("showAlert", { type: ALERT_TYPE_ERROR, message: "No navigation for Recent object found" })
        return
      }
      this.$store.dispatch(Constants.SELECT_OBJECT, { splitCardId: item.navigationConfigId, object: object });
    },
    /**
     * First try to find the object of the item. By default we name this Image.
     */
    getImage(item) {
      if (item.object !== undefined && item.object.Image !== null) {
        var image = this.demo[item.object.Image];
        if (image !== undefined && image !== null) {
          return image;
        }
      }
      return null;
    },
    /**
     * If the Image is not loaded we will load the Icon of the objectType
     */
    getImageIcon(item) {
      return item.objectConfig.icon;
    },
    getColorOfMenu(item) {
      return "menuItemColorRight";
    },
    getLabelColorOfMenu(item) {
      return " primary--text ";
    }
  }
};
</script>
