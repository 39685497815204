//PUBLIC Constants
//Public Actions & Commits

/*
Types
*/
export const BOOLEAN = "boolean";
export const STRING = "string";
export const DATE = "date";
export const TIME = "time";
export const INTEGER = "integer";
export const NUMBER = "number";
export const LONGSTRING = "longString";

/*
UI elements
*/
export const SWITCH = "switch";
export const LINK = "link";
export const INPUT = "input";
export const TEXT_EDIT = "textEdit";
export const VALUE_LIST = "valueList";
export const EMAIL = "email";
export const PHONE = "phone";
export const DATE_SELECT = "dateSelect";
export const RELATIVE_DATE = "relativeDate";
export const RELATIVE_DATE_CHIP = "relativeDateChip";

/*
  Splitcard Sizes
*/
export const MINI_SIZE_THRESHOLD = 120;
export const EXTRA_SMALL_SIZE_THRESHOLD = 240;
export const SMALL_SIZE_THRESHOLD = 540;
export const MEDIUM_SIZE_THRESHOLD = 700;
export const BIG_SIZE_THRESHOLD = 1000;
export const KING_SIZE_THRESHOLD = 2000;

export const SPLITCARD_THRESHOLD = 1134;

export const SMALL_SIZE = 1;
export const MEDIUM_SIZE = 2;
export const BIG_SIZE = 3;
export const KING_SIZE = 4;
export const ALL_SIZE = 1000;

/*
  Drawer State and Sizes
*/

export const DRAWER_SIZE_NORMAL = 380;
export const DRAWER_SIZE_BIG = 750;

export const DRAWER_STATE_SIZE_MINI = 1;
export const DRAWER_STATE_SIZE_NORMAL = 2;
export const DRAWER_STATE_SIZE_BIG = 3;

export const MENU_NOTIFICATION_SIZE = 440;

/**
 * Synchronisation Groups
 */

export const SYNC_GROUP_DEFAULT = "SYNC_GROUP_DEFAULT";
export const SYNC_GROUP_CORE_SYNC_GROUP = "SYNC_GROUP_CORE_SYNC_GROUP";
export const SYNC_GROUP_CORE_FIELDS = "SYNC_GROUP_CORE_FIELDS";
export const SYNC_GROUP_CORE_OBJECTS = "SYNC_GROUP_CORE_OBJECTS";
export const SYNC_GROUP_CORE_REST = "SYNC_GROUP_CORE_REST";
export const SYNC_GROUP_CE7 = "SYNC_GROUP_CE7";
export const SYNC_GROUP_CRM = "SYNC_GROUP_CRM";
export const SYNC_GROUP_SALES = "SYNC_GROUP_SALES";

/**
 * Auth
 */

export const AUTH_CONFIG_MANIPULATION = "ConfigManipulation";

/***********************************************************
 *  Object Types
 ***********************************************************/

export const SPLIT_CARD = "SplitCard";
export const VIEW_FIELD = "ViewField";
export const VIEW_CONFIG = "ViewConfig";
export const OBJECT_CONFIG = "ObjectConfig";
export const FIELD_CONFIG = "FieldConfig";
export const CARD_CONFIG = "CardConfig";
export const TAB_CONFIG = "TabConfig";
export const ACTION_CONFIG = "ActionConfig";
export const MENU_ITEM = "MenuItem";
export const MENU_SETTING = "MenuSetting";
export const OPERATION = "Operation";
export const SYNCHRONISATION = "Synchronisation";
export const FILTER_FIELD_CONFIG = "FilterFieldConfig";
export const SORTING_FIELD_CONFIG = "SortingFieldConfig";
export const FILTER_CONFIG = "FilterConfig";
export const CHART_FIELD_CONFIG = "ChartFieldConfig";
export const CHART_CONFIG = "ChartConfig";
export const VALUE_LIST_CONFIG = "ValueListConfig";
export const FIELD_LIST_CONFIG = "FieldListConfig";
export const USER_ROLE = "UserRole";
export const DOMAIN = "Domain";
export const ATTACHMENT = "Attachment";
export const TRANSPORT_MAIN = "TransportMain";
export const TRANSPORT_ITEM = "TransportItem";
export const TRANSPORT_DOMAIN_ITEM = "TransportDomainItem";
export const AUTHORISATION = "Authorisation";
export const AUTH_GROUP = "AuthGroup";
export const EXTERNAL_ID = "ExternalId";
export const CLIENT_ATTRIBUTES = "ClientAttribute";
export const INBOX_MAIN = "InboxMain";
export const INBOX_ITEM = "InboxItem";
export const INBOX_SETTING = "InboxSetting";
export const EMPLOYEE_MAIN = "EmployeeMain";
export const SUBSCRIPTION_PLAN = "SubscriptionPlan";
export const MESSAGE_MAIN = "MessageMain";
export const TEMPLATE = "Template";

/***********************************************************
 *  View Types
 ***********************************************************/

export const VIEW_TYPE_DYNAMIC = "Dynamic";
export const VIEW_TYPE_TABS = "Tabs";
export const VIEW_TYPE_DETAILS = "Details";
export const VIEW_TYPE_ACTION = "Action";
export const VIEW_TYPE_LIST = "List";
export const VIEW_TYPE_ATTACHEMENT_UPLOADER = "AttachmentUploaderList";
export const VIEW_COMMENT_TILE = "CommentTile";
export const VIEW_TYPE_CALENDAR_TILE = "CalendarTile";
export const VIEW_TYPE_TIMELINE = "Timeline";
export const VIEW_TYPE_STEPPER = "Stepper";

/***********************************************************
 *  CRM objecten
 ***********************************************************/

export const QUOTATION = "Quotation";
export const PRODUCT = "Product";
export const PRICE_ITEM = "PriceItem";
export const ACCOUNT_MAIN = "AccountMain";
export const LEAD_MAIN = "LeadMain";
export const TASK_MAIN = "TaskMain";
export const ORDER_MAIN = "OrderMain";
export const ORDER_ITEM = "OrderItem";
export const ADDRESS = "Address";
export const INSTALL_BASE_MAIN = "InstallBaseMain";
export const CHAT_GPT_REQUEST = "ChatGPTRequest";

/***********************************************************
 *  Icons
 ***********************************************************/

export const MENU_ITEM_ICON = "view_list";
export const SPLIT_CARD_ICON = "view_array";
export const VIEW_FIELD_ICON = "remove";
export const VIEW_CONFIG_ICON = "settings";
export const OBJECT_CONFIG_ICON = "crop_16_9";
export const FIELD_CONFIG_ICON = "remove"; //space_bar
export const CARD_CONFIG_ICON = "view_module";
export const TAB_CONFIG_ICON = "featured_play_list";
export const ACTION_CONFIG_ICON = "launch";
export const MENU_SETTING_ICON = "pause"; //pauze
export const OPERATION_ICON = "swap_horiz";
export const SYNCHRONISATION_ICON = "cached";

/***********************************************************
 *  uiContext
 ***********************************************************/
//Commits
export const TOGGLE_LEFT_DRAWER = "toggleLeftDrawer";
export const TOGGLE_RIGHT_DRAWER = "toggleRightDrawer";

export const MENU_DRAWER_LEFT = "menuDrawerLeft";
export const MENU_DRAWER_RIGHT = "menuDrawerRight";

//Actions
export const LOAD_UI = "loadUI";
export const LOAD_USER = "loadUser";
export const LOAD_PROFILE = "loadProfile";
export const LOAD_LEFT_MENU = "loadLeftMenu";
export const TOGGLE_DRAWER = "toggleDrawer";

/************************************************************
 *  AppContext
 ***********************************************************/
//Actions
export const INIT_APPLICATION = "initApplication";
export const SET_APP_ROUTER = "setAppRouter";
export const LOGIN_APPLICATION = "loginApplication";
export const LOGOUT_APPLICATION = "logoutApplication";

/************************************************************
 *  dbContext
 ***********************************************************/
//Actions
export const CREATE_TABLES = "createTables";
export const OPEN_DATABASE = "openDatabase";
export const CREATE_DATA_TABLES = "createDataTables";
export const CREATE_CORE_TABLES = "createCoreTables";
export const DELETE_DATA_TABLES = "deleteDataTables";
export const DELETE_CORE_TABLES = "deleteCoreTables";

/************************************************************
 * objectContext
 ***********************************************************/
// Mutators.
export const SET_OBJECT = "setObject";

// Actions
export const INIT_OBJECT_CONTEXT = "initObjectContext";
export const DELETE_OBJECT_CONTEXT = "deleteObjectContext";
export const initObjectConfigs = "initObjectConfigs";
export const CREATE_TEST_FIELDS = "createTestFields";
export const CREATE_TEST_OBJECTS = "createTestObjects";
export const FIND_ALL_OBJECTS = "findAllObjects";
export const FIND_ALL_FIELDS = "findAllFields";
export const FIND_ALL_DATA_OBJECTS = "findAllDataObjects";
export const UPDATE_DATA_STORE = "updateDataStore";

/************************************************************
 * dBObjectContext
 ***********************************************************/
//Actions
export const SAVE_OBJECTS = "saveObjects";
export const SAVE_OBJECT = "saveObject";
export const SAVE_OBJECTS_HISTORY = "saveObjectsHistory";
export const SAVE_OBJECT_HISTORY = "saveObjectHistory";

/************************************************************
 * OperationContext
 ***********************************************************/
//Actions
export const CREATE_OPERATION_CONTAINER = "createOperationContainer";
export const CREATE_AND_SAVE_OPERATION_CONTAINER =
  "createAndSaveOperationContainer";
export const SAVE_OPERATION_CONTAINER = "saveOperationContainer";
export const DELETE_OPERATION_CONTAINER = "deleteOperationContainer";
export const CANCEL_OPERATION_CONTAINER = "cancelOperationContainer";
export const SEND_OPERATION_CONTAINER = "sendOperationContainer";

/************************************************************
 * syncContext
 ***********************************************************/

export const INIT_CORE = "initCore";
export const SYNCHRONIZE = "synchronize";
export const SYNCHRONIZE_CONFIGS = "synchronizeConfigs";
export const SYNCHRONIZE_DATA = "synchronizeData";
export const CREATE_INIT_VIEWCONFIGS = "createInitViewConfig";
export const SET_SYNC_PROGRESS = "setSyncProgress";

/************************************************************
 * splitCardContext
 ***********************************************************/

export const GET_VIEW_CONFIG_FOR_ID = "getViewConfigForID";
export const SELECT_OBJECT = "selectObject";

/************************************************************
 * TabContext
 ***********************************************************/
export const GET_TABS_CONFIGS_FOR_VIEWCONFIG = "getTabsConfigsForViewConfig";

/************************************************************
 * FilterContext
 ***********************************************************/
export const SET_CURRENT_FILTER = "setCurrentFilter";

/************************************************************
 * ChartContext
 ***********************************************************/
export const CHART_X = "X";
export const CHART_Y = "Y";
export const CHART_C = "C";

/************************************************************
 * AuthorisationObjectContext
 ***********************************************************/
export const ACTION_FILTER = "Filter";
export const ACTION_WRITE = "Write";
export const ACTION_READ = "Read";

/************************************************************
 * AlertContext
 ***********************************************************/

export const ALERT_TYPE_SUCCESS = "success";
export const ALERT_TYPE_ERROR = "error";
export const ALERT_TYPE_WARNING = "warning";
export const ALERT_TYPE_INFO = "info";

/************************************************************
 * Categories
 ***********************************************************/

export const PRODUCT_CATEGORY_SOLAR = "C001";
export const PRODUCT_TYPE_QUOTATION = "TOO2";
export const TASK_NEW = "E0001";
export const TASK_IN_PROGRESS = "E0002";
export const TASK_COMPLETED = "E0003";
export const TASK_CANCELED = "E0004";
export const ATTACHMENT_CATEGORY_SIGNATURE = "C005";

/************************************************************
 * Date
 ***********************************************************/

export const DATE_FORMAT = "YYYY-MM-DD";
export const TO_DATE_FORMAT = "DD/MM/YYYY";
