/**
 * Alert Context
 */

import { getGuid } from "../api/utilities";
import { ALERT_TYPE_INFO, ALERT_TYPE_SUCCESS } from "./constants";

const initState = {
  messages: [],
};
// {
//   message: "",
//   showAlert: false,
//   type: ALERT_TYPE_INFO
// };

export default {
  state: initState,
  getters: {
    getMessages: (state, getters) => {
      return state.messages;
    },
  },
  mutations: {
    showAlert(state, payload) {
      var alert = payload;
      alert.id = getGuid();
      state.messages.push(alert);
      if (alert.type === ALERT_TYPE_INFO || alert.type === ALERT_TYPE_SUCCESS) {
        setTimeout(() => {
          this.commit("hideAlert", alert.id);
        }, 3000);
      } else {
        setTimeout(() => {
          this.commit("hideAlert", alert.id);
        }, 9000);
      }
    },
    hideAlert(state, id) {
      const index = state.messages.map((item) => item.id).indexOf(id);
      if (index > -1) {
        state.messages.splice(index, 1);
      }
    },
  },
};
