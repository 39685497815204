<template>
  <v-navigation-drawer
    :value="true"
    :mini-variant="isTrue(getLeftMini)"
    :clipped="false"
    :stateless="false"
    :dark="false"
    :disable-route-watcher="false"
    :mini-variant-width="64"
    :temporary="false"
    :width="250"
    :touchless="false"
    hide-overlay
    fixed
    permanent
    floating
    class="sideBarColor navigationDrawer"
    style="z-index: 2"
    app
  >
    <!--<v-card 
      class="mx-2 mr-4 my-3 sideBarColor"
      flat>
      <v-img 
        :aspect-ratio="698/187" 
        :src="logo"
        contain/>
    </v-card> -->
    <!-- <v-container> -->

    <v-container
      class="flex0 "
      :class="isTrue(getLeftMini) ? 'py-2 px-1' : 'py-2 px-3'"
    >

      <v-card
        elevation="0"
        class="kingSize  rounded-lg sideBarColor border1"
        :class="isTrue(getLeftMini) ? 'py-2' : 'py-2 px-4'"
      >

        <v-row
          no-gutters
          class="flex-nowrap"
          align="center"
          :justify="isTrue(getLeftMini) ? 'center' : 'start'"
        >
          <img
            :src="getTenantIcon"
            crossorigin="anonymous"
            height="32px"
            width="32px"
            cover
            class="rounded-lg"
          />
          <div
            :style="{ width: isTrue(getLeftMini) ? 0 : 'Auto', maxHeight: '20px', overflow: 'hidden', textOverflow: 'hidden' }"
            class="text-body-1 font-weight-mediumBold text-capitalize "
            :class="!isTrue(getLeftMini) && 'pl-3'"
          >{{ getTenant.name }}</div>


        </v-row>

      </v-card>


      <v-chip
        class="mx-2"
        x-small
        :color="getColorEnv"
        v-if="env !== prodEnv"
      >{{ env }}</v-chip>

    </v-container>

    <div
      class="flex1 scroll pt-4"
      style="height: 0;"
    >
      <v-list
        dense
        rounded
        expand
      >
        <div
          v-for="    group    in     getGroups    "
          :key="group.value"
        >
          <div v-if="group.value === 'D000'">
            <v-tooltip
              v-for="    item     in     getMenuItemsPerGroup(group.value)    "
              :key="item.viewConfig"
              :disabled="!isTrue(getLeftMini)"
              :nudge-right="8"
              :content-class="`rounded-xl px-4 outlined tooltip`"
              right
              :color="isSelected(item) ? 'primary' : 'menuColorHighlightBackground'"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-list-item
                  :class="{ 'opaque-7': !isSelected(item), 'menuColorHighlightBackground': isSelected(item) }"
                  :router="item.router"
                  v-bind="attrs"
                  v-on="on"
                  @click="() => { onClick(item) }"
                >

                  <v-list-item-action>
                    <v-icon
                      :color="getColorOfMenu(item)"
                      :size="!isTrue(getLeftMini) ? 20 : 24"
                      class="material-icons-sharp"
                    >{{ getIcon(item) }}
                    </v-icon>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title>
                      <label
                        :class="getColorOfMenu(item) + '--text' + (getColorOfMenu(item) === 'menuColorHighlight' ? ' font-weight-bold' : ' font-weight-mediumBold')"
                      >
                        {{ item.title }}
                      </label>
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </template>
              <span
                class="text-body-2 font-weight-medium"
                :class="isSelected(item) ? 'white--text' : 'primaryText--text'"
              >{{ item.title }}</span>
            </v-tooltip>
          </div>
          <v-list-group
            v-else
            v-model="savedState[group.value]"
            class="gray--text"
            @change="() => {
              // toggleGroup(group.value)
            }"
          >
            <template v-slot:activator>

              <v-divider v-if="savedState[group.value]"></v-divider>
              <v-list-item-title
                v-else
                class="text-capitalize text-caption"
              >
                {{ group.descr }}</v-list-item-title>
            </template>
            <v-tooltip
              v-for="    item     in     getMenuItemsPerGroup(group.value)    "
              :key="item.viewConfig"
              :disabled="!isTrue(getLeftMini)"
              :nudge-right="8"
              :content-class="`rounded-xl px-4 outlined tooltip`"
              right
              :color="isSelected(item) ? 'primary' : 'menuColorHighlightBackground'"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-list-item
                  :class="{ 'opaque-7': !isSelected(item), 'menuColorHighlightBackground': isSelected(item) }"
                  :router="item.router"
                  v-bind="attrs"
                  v-on="on"
                  @click="() => { onClick(item) }"
                >

                  <v-list-item-action class="mr-0">
                    <v-icon
                      :color="getColorOfMenu(item)"
                      :size="!isTrue(getLeftMini) ? 20 : 24"
                      class="material-icons-sharp"
                    >{{ getIcon(item) }}
                    </v-icon>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title>
                      <label
                        :class="getColorOfMenu(item) + '--text' + (getColorOfMenu(item) === 'menuColorHighlight' ? ' font-weight-bold' : ' font-weight-mediumBold')"
                      >
                        {{ item.title }}
                      </label>
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </template>
              <span
                class="text-body-2 font-weight-medium"
                :class="isSelected(item) ? 'white--text' : 'primaryText--text'"
              >{{ item.title }}</span>
            </v-tooltip>
          </v-list-group>
        </div>
      </v-list>
    </div>



    <v-row
      no-gutters
      flat
      class="pa-2 flex0 sideBarColor"
      justify="end"
    >

      <v-menu
        top
        offset-y
        nudge-top="8"
        content-class="rounded-lg"
      >
        <template v-slot:activator="{ on, attrs, value }">
          <v-btn
            v-if="isClientAdmin"
            icon
            class="ma-1 float-right"
            :class="value === true ? 'primary white--text' : 'iconButton'"
            v-bind="attrs"
            v-on="on"
          >
            <v-icon>hi-adjustments-horizontal-outline</v-icon>
          </v-btn>
        </template>

        <menuconfiguration group="D006" />

      </v-menu>
      <v-menu
        top
        offset-y
        nudge-top="8"
        content-class="rounded-lg"
      >
        <template v-slot:activator="{ on, attrs, value }">
          <v-btn
            v-if="isConfigAdmin"
            icon
            class="ma-1 float-right"
            :class="value === true ? 'primary' : 'iconButton'"
            v-bind="attrs"
            v-on="on"
          >
            <v-icon :class="value === true && 'white--text'">hi-cog-outline</v-icon>
          </v-btn>
        </template>

        <menuconfiguration group="core" />

      </v-menu>
      <v-btn
        icon
        class="iconButton ma-1 float-right"
        @click.native="toggleMini()"
      >
        <v-icon>{{ getMiniIcon }}</v-icon>
      </v-btn>



    </v-row>
  </v-navigation-drawer>
</template>

<script>
import { mapGetters } from "vuex";
import { NODE_ENV, PRODUCTION, STAGING } from '../../api/constants.js';
import * as Utilities from "../../api/utilities";
import router from '../../router/index.js';
import { VALUE_LIST_CONFIG } from '../../store/constants.js';
import menuconfiguration from "./MenuConfiguration";

const SAVED_LF_STATE = 'sw_lms'
export default {
  components: { menuconfiguration },
  data: () => ({
    drawerLeft: true,
    drawerRight: true,
    right: null,
    left: null,
    showConfig: false,
    showConfigAdmin: false,
    env: NODE_ENV,
    prodEnv: PRODUCTION,
    savedState: {}
  }),
  computed: {
    ...mapGetters(['getLeftMenu', 'getTenant', 'getTenantIcon', 'getLeftMini', 'getLeftFixed']),

    drawerState() {
      var leftDrawer = this.$store.getters.getLeftDrawer;
      return leftDrawer;
    },
    getColorEnv() {
      if (this.env === STAGING) return 'warning'
      return 'success'
    },
    getMenuItems() {
      var menuItems = this.getLeftMenu;
      var menuItemsFiltered = menuItems.filter(function (o) {
        return o.menuGroup !== "core" && o.menuGroup !== "D006" && o.menuGroup !== "D100" && Utilities.isTrue(o.isActive);
      });
      return menuItemsFiltered.sort(function (a, b) { return a.position - b.position });
    },
    getGroups() {
      const menuItems = this.getMenuItems.map(item => item.menuGroup)
      const mapped = new Map(menuItems.map((obj) => [obj, true]));
      const unique = {}
      const filter = (item) => {
        if (!Utilities.isEmpty(unique[item.value])) {
          return false
        }
        unique[item.value] = true
        return item.valueList === 'MenuGroupCode' && mapped.has(item.value)
      }
      const menuGroups = this.$store.getters.getObjectsForFilter(VALUE_LIST_CONFIG, filter)
      return menuGroups.sort(function (a, b) { return a.position - b.position });
    },
    isClientAdmin() {
      return this.$store.getters.isClientAdmin
    },
    isConfigAdmin() {
      return this.$store.getters.isConfigAdmin
    },
    getMiniIcon() {
      if (Utilities.isTrue(this.getLeftMini)) {
        return "hi-chevron-double-right";
      }
      return "hi-chevron-double-left";
    },
    getPinIcon() {
      if (Utilities.isTrue(this.getLeftFixed)) {
        return "hi-lock-closed";
      }
      return "hi-lock-open";
    }
  },
  created() {
    if (window && window.localStorage.getItem(SAVED_LF_STATE)) {
      const saved = JSON.parse(window.localStorage.getItem(SAVED_LF_STATE))
      this.savedState = saved || {}
    }
  },
  watch: {
    savedState: {
      handler: (value) => {
        window.localStorage.setItem(SAVED_LF_STATE, JSON.stringify(value))
      },
      deep: true
    }
  },
  methods: {
    getIcon(item) {
      // if (this.isSelected(item)) {
      //   return item.icon.replace('-outline', '')
      // }
      return item.icon
    },
    getMenuItemsPerGroup(group) {
      return this.getMenuItems.filter(function (o) {
        return o.menuGroup === group
      });
    },
    // toggleGroup(group) {
    //   var that = this
    //   setTimeout(() => {
    //     window.localStorage.setItem(SAVED_LF_STATE, JSON.stringify(that.savedState))
    //   }, 500)
    // },
    onClick(item) {
      console.log("ITEM", item)
      router.push(item.href)
      if (this.isSelected(item)) {
        this.$store.dispatch("changeViewState", 0);
      }
    },
    toggleMini() {
      this.$store.commit("toggleMini");
    },
    toggleSettings() {
      this.showConfig = !this.showConfig
    },
    toggleConfigManipulation() {
      this.showConfigAdmin = !this.showConfigAdmin
    },
    toggleFixed() {
      this.$store.commit("toggleFixed");
    },

    isTrue(value) {
      return Utilities.isTrue(value);
    },
    getColorOfMenu(item) {
      if (item === null) {
        return "gray";
      }
      var currentRoute = this.$store.getters["getCurrentRoute"];
      if (item.viewConfig === currentRoute) {
        return "menuColorHighlight";
      } else {
        return "gray";
      }
    },
    isSelected(item) {
      if (item === null) {
        return false;
      }
      var currentRoute = this.$store.getters["getCurrentRoute"];
      return item.viewConfig === currentRoute;
    }
  }
};
</script>

<style scoped>
.v-list__tile {
  padding: 8px;
}

.primary_text {
  color: "blue";
}

.top-bar {
  position: absolute;
  top: 0;
  z-index: 1;
  width: 100%;
  background-color: (var(--v-sideBarColor-base), 0.4);
}

.bottom-actions {
  position: absolute;
  bottom: 0;
  z-index: 1;
  width: 100%;

}
</style>
