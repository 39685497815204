<template>
    <div
        style="height: 100%;"
        class="card"
    >

        <v-container
            fluid
            fill-height
        >
            <v-row
                style="height: 100%;"
                class="flex-nowrap"
            >
                <v-col
                    v-show="step !== 'success'"
                    :cols="step === 'success' ? 0 : 12"
                    md="5"
                >
                    <v-container
                        fill-height
                        fluid
                    >
                        <v-row
                            align="center"
                            justify="center"
                        >
                            <v-card
                                class="rounded-lg transparent elevation-0 kingSize"
                                style="max-width: 400px;"
                            >

                                <v-container v-if="!isAuthenticated">
                                    <create-account />
                                </v-container>
                                <v-container
                                    v-else
                                    class="pt-6"
                                >
                                    <!-- <div
                                        class="text-body-2 gray--text"
                                        v-text="`${step}/${steps}`"
                                    /> -->

                                    <v-form
                                        ref="form"
                                        v-model="valid"
                                        v-if="step === 1"
                                    >

                                        <div
                                            class="text-h5 font-weight-medium "
                                            v-text="'Create your workspace'"
                                        />
                                        <v-container class="py-4">
                                            <v-row
                                                align="center"
                                                class="py-4 pb-8 flex-nowrap"
                                            >
                                                <v-card
                                                    class="rounded-lg elevation-2 d-flex align-center justify-center mr-2"
                                                    style="height: 80px; width: 80px;"
                                                >
                                                    <img
                                                        v-if="!isEmpty(workspace.logo)"
                                                        class="rounded-lg"
                                                        :src="workspace.logo"
                                                        crossorigin="anonymous"
                                                        aspect-ratio="1"
                                                        width="80px"
                                                        height="80px"
                                                    />
                                                    <div
                                                        v-else
                                                        class="text-h2 pa-4"
                                                    >{{ getInitial }}</div>
                                                </v-card>
                                                <div>
                                                    <div
                                                        class="text-body-2 font-weight-medium px-2 pt-2"
                                                        v-text="'Workspace logo'"
                                                    />
                                                    <v-row
                                                        no-gutters
                                                        class="pa-2"
                                                    >

                                                        <vue-dropzone
                                                            id="dropzone"
                                                            ref="myVueDropzone"
                                                            :options="dropzoneOptions"
                                                            :use-custom-slot="true"
                                                            style="min-height: 0;"
                                                            class="pa-0 no-border"
                                                            @vdropzone-sending="sendingMultiple"
                                                        >
                                                            <div class="dropzone-custom-content card">
                                                                <v-btn
                                                                    id="upload-avatar"
                                                                    color="gray"
                                                                    outlined
                                                                    class="primaryText--text rounded-lg mr-3"
                                                                >
                                                                    <v-icon
                                                                        class="mr-1">hi-arrow-up-tray-outline</v-icon>
                                                                    Upload
                                                                    image
                                                                </v-btn>
                                                            </div>
                                                        </vue-dropzone>
                                                        <v-btn
                                                            :disabled="isEmpty(workspace.logo)"
                                                            color="card"
                                                            class="rounded-lg elevation-0"
                                                            @click="deletePicture"
                                                        >
                                                            Delete
                                                        </v-btn>
                                                    </v-row>
                                                </div>
                                            </v-row>
                                        </v-container>

                                        <v-text-field
                                            v-model="workspace.name"
                                            :rules="[rules.required, rules.min, rules.forbidden]"
                                            name="Workspace name"
                                            label="Workspace name"
                                            class="text-field-transparent"
                                            filled
                                            @input="updateHandle"
                                        />
                                        <v-text-field
                                            v-model="workspace.handle"
                                            :rules="[rules.required, rules.min, rules.forbidden]"
                                            name="Handle"
                                            label="Handle"
                                            suffix=".swiftio.io"
                                            class="text-field-transparent"
                                            filled
                                        />
                                        <v-select
                                            v-model="workspace.templates"
                                            :items="templates"
                                            item-text="name"
                                            item-value="handle"
                                            name="Starting template"
                                            label="Starting template"
                                            class="text-field-transparent"
                                            filled
                                            chips
                                            multiple
                                        />
                                        <v-btn
                                            :loading="loading"
                                            :disabled="!valid"
                                            color="primary"
                                            large
                                            depressed
                                            block
                                            @click.native="createWorkspace"
                                        >Create</v-btn>

                                    </v-form>
                                </v-container>
                            </v-card>
                        </v-row>
                    </v-container>

                </v-col>
                <v-col
                    :cols="step === 'success' ? 12 : 0"
                    :md="step === 'success' ? 12 : 7"
                    :class="step !== 'success' && 'd-none d-sm-flex'"
                    class="px-8 "
                >

                    <v-container
                        :style="{ 'height': '100%', 'overflow': 'hidden' }"
                        class="pa-0 tileBackgroundColor rounded-xl"
                        :class="!isDark && 'darken-1'"
                    >
                        <v-container
                            v-if="!isAuthenticated"
                            fill-height
                            fluid
                        >
                            <v-row
                                align="center"
                                justify="center"
                            >
                                <v-card
                                    class="transparent elevation-0"
                                    max-width="500"
                                >
                                    <v-row class="pl-2 pb-4">

                                        <div style="height: 64px; max-width: 64px;">
                                            <img
                                                :src="logo"
                                                width="64"
                                                height="64"
                                                cover
                                                class="rounded-lg"
                                            />
                                        </div>

                                    </v-row>
                                    <v-card-title>
                                        Welcome to Swiftio.
                                    </v-card-title>
                                    <v-card-text class="mb-8">
                                        Swiftio is a radically new type of CRM. Built on an entirely new type of data
                                        architecture, you’ll have profiles and records of every interaction within your
                                        network
                                        in minutes, always updated in real-time.
                                        <br><br>
                                        You’ll be able to customize and create your CRM exactly as you want it.
                                        <br><br>
                                        Let’s begin.
                                    </v-card-text>
                                </v-card>
                            </v-row>
                        </v-container>
                        <v-container
                            v-else-if="step === 1"
                            fill-height
                            fluid
                        >
                            <v-row
                                align="center"
                                justify="center"
                                style="position: relative;"
                            >
                                <div class="started-img-wrap">
                                    <div class="started-img-text d-flex align-center">
                                        <v-card
                                            class=" elevation-2 d-flex align-center justify-center mr-2"
                                            :style="{ 'height': size, 'width': size, 'border-radius': '25%' }"
                                        >
                                            <img
                                                v-if="!isEmpty(workspace.logo)"
                                                :style="{ 'height': size, 'width': size, 'border-radius': '25%' }"
                                                :src="workspace.logo"
                                                crossorigin="anonymous"
                                                aspect-ratio="1"
                                            />
                                            <div
                                                v-else
                                                style="{'font-size': size}"
                                            >{{ getInitial }}</div>

                                        </v-card>
                                        <div class="black--text started-workspace">{{ isEmpty(workspace.name) ?
                        'Workspace'
                        :
                        workspace.name }}</div>
                                    </div>
                                    <img
                                        :src="startedImg"
                                        height="100%"
                                        width="auto"
                                        cover
                                        class="started-img rounded-xl v-sheet elevation-2"
                                    />
                                </div>
                            </v-row>
                        </v-container>

                        <v-container
                            v-if="step === 'success'"
                            fill-height
                            fluid
                        >
                            <v-row
                                align="center"
                                justify="center"
                            >
                                <v-card
                                    class="transparent elevation-0 px-4"
                                    max-width="500"
                                >
                                    <v-row
                                        class="pl-2 pb-4"
                                        align="center"
                                        justify="center"
                                    >

                                        <LottieAnimation
                                            :animation-data="successJSON"
                                            :auto-play="true"
                                            :loop="false"
                                            :speed="1"
                                            ref="anim"
                                        />

                                    </v-row>
                                    <v-card-title class="justify-center">
                                        All set up!
                                    </v-card-title>
                                    <v-card-text class="text-center">
                                        Just click login and enter your credentials and you are ready to get started
                                    </v-card-text>
                                    <v-card-actions class="mt-8">
                                        <v-btn
                                            color="primary"
                                            large
                                            depressed
                                            block
                                            @click.native="finish"
                                        >Let's go</v-btn>
                                    </v-card-actions>
                                </v-card>
                            </v-row>
                        </v-container>
                        <!-- <v-img
                        :src="getTenantSplash"
                        :lazy-src="getTenantSplash"
                        max-height="100%"
                        height="100%"
                        max-width="100%"
                        cover
                        class="rounded-lg elevation-2"
                    /> -->
                    </v-container>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script>
import logo from '../../assets/logo-swiftio.svg';
import vue2Dropzone from "vue2-dropzone";
import { getGuid, isEmpty } from '../../api/utilities';
import { BASE_URL } from '../../api/constants';
import axios from 'axios';
import CreateAccount from './createAccount.vue';
import { ALERT_TYPE_ERROR } from '../../store/constants';
import { getAccessToken } from '../../store/authorizationContext';
import { mapActions, mapGetters } from 'vuex';
import LottieAnimation from "lottie-web-vue"
import successJSON from "../../assets/animations/success.json"
import startedImg from "../../assets/swiftio-started.png"


const SAVED_TENANT = 'sw_savTen'

export default {
    components: {
        vueDropzone: vue2Dropzone,
        CreateAccount,
        LottieAnimation
    },

    data() {
        return {
            logo,
            successJSON,
            startedImg,
            size: '3vh',
            valid: false,
            rules: {
                required: value => !!value || "Required.",
                min: v => v.length >= 3 || "Min 3 characters",
                forbidden: v => /[a-z\d\-_]+$/giy.test(v) || 'Only letters and hyphens allowed'
            },
            step: 1,
            steps: 3,
            dropzoneOptions: {
                url: "https://httpbin.org/post",
                maxFilesize: 10.0,
                addRemoveLinks: true,
                clickable: `#upload-avatar`,
                previewsContainer: false,
                // autoProcessQueue: false,
                acceptedFiles: 'image/*',
                maxFiles: 1,
                uploadMultiple: true,
                parallelUploads: 1
                // acceptedFiles: 'image/*,application/pdf'
            },
            templates: [
                // {
                //     name: 'No template',
                //     handle: null
                // },
                {
                    name: 'Crm',
                    handle: 'swiftio'
                },
                {
                    name: 'Solar',
                    handle: 'belsol'
                },

            ],
            workspace: {
                name: '',
                handle: '',
                logo: '',
                templates: []
            },
            loading: false,
        };
    },
    computed: {
        ...mapGetters(['isDark', 'isAuthenticated']),
        getInitial() {
            if (isEmpty(this.workspace.handle)) {
                return 'A'
            }
            return this.workspace.handle.slice(0, 1).toUpperCase()
        },

    },
    created() {
        const saved = JSON.parse(window.localStorage.getItem(SAVED_TENANT))
        if (saved) {
            this.workspace = JSON.parse(window.localStorage.getItem(SAVED_TENANT))
        }

    },
    watch: {
        workspace: {
            handler(val) {
                // do stuff
                console.log('val', val)
                if (val) {
                    window.localStorage.setItem(SAVED_TENANT, JSON.stringify(val))
                }
                this.$refs.form.validate()

            },
            deep: true
        }
    },
    methods: {
        ...mapActions(["logout",]),
        isEmpty,
        updateHandle(value) {
            this.workspace.handle = value.toLowerCase()
        },
        async sendingMultiple(files) {
            if (files) {
                const payload = {
                    file: files,
                    folder: 'tenants',
                    guid: getGuid()
                }
                this.workspace.logo = await this.$store.dispatch('uploadSingle', payload)
            }
        },
        deletePicture() {
            this.workspace.logo = ''
        },
        finish() {
            window.localStorage.removeItem(SAVED_TENANT)
            this.$router.push('/')
            this.logout()
        },
        async createWorkspace() {
            this.loading = true
            let url = new URL(`${BASE_URL}/tenant/create`);
            var config = {
                headers: {},
                timeout: 600000
            };
            var bearer = await getAccessToken(this.$store.getters.getCurrentUser);

            if (isEmpty(bearer)) {
                var error = "problem with bearer During Operation.";
                this.$store.commit("showAlert", {
                    type: ALERT_TYPE_ERROR,
                    message: error
                });
                return;
            }
            config.headers.Authorization = "Bearer ".concat(bearer);
            const data = this.workspace
            axios.put(url, data, config).then(response => {
                this.step = 'success'
                this.loading = false
            }).catch(error => {
                this.$store.commit("showAlert", {
                    type: ALERT_TYPE_ERROR,
                    message: error
                });
            })

        }
    }
};
</script>

<style scoped>
.started-img-wrap {
    height: 70vh;
    position: absolute;
    left: 20%;
}

.started-img-text {
    position: absolute;
    left: 0;
    top: 0;
    padding: 1.5vh;
}

.started-workspace {
    max-width: 9vh;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 1.5vh;

}

.started-img {
    max-height: 100%;
}
</style>