<template>
  <v-card
    id="kanban-wrap"
    flat
    class="ma-0 pa-0 transparent"
  >
    <v-card
      v-show="false"
      class="ma-0 pa-0 transparent"
    >
      {{ getSelectedProperty }}
    </v-card>
    <v-container
      fluid
      class="rounded-lg"
    >
      <v-row
        ref="kanbanWrap"
        class="flex-nowrap stage-wrap"
      >
        <div
          v-for="stage in stages"
          class="stage-container px-2 pb-4"
          :key="stage[stageField]"
        >
          <v-container
            style="height: 100%;"
            class="rounded-lg iconButton pa-2"
          >
            <v-row
              no-gutters
              class="pb-3 pt-1 flex-nowrap"
              justify="space-between"
              align="center"
            >
              <h2
                :class="`text-body-2 ${stage.color ? stage.color : 'transparent'} ${stage.color ? 'white--text' : 'black--text'
                  } rounded-lg px-2 py-1 font-weight-normal`
                  "
                style="text-transform:initial;"
              >
                {{ stage.descr }}
              </h2>

              <h2
                :class="`text-caption px-1 py-1 font-weight-normal gray--text`
                  "
                style="text-transform:initial;"
              >
                {{ getStagePagination(stage) }}/{{ stage.list.length }}
              </h2>

            </v-row>
            <v-row
              no-gutters
              style="height: 100%;"
            >
              <draggable
                :value="stage.list"
                :options="{ group: 'Status', disabled: getIsMobile }"
                :sort="false"
                class="stage-overflow rounded-lg flex-grow-1"
                :style="{ height: getHeight && getHeight !== null ? getHeight + 'px' : '70vh' }"
                @change="(update) => {
                  updateBlock(update, stage)
                }"
              >
                <div
                  v-for="(item, index) in stage.list"
                  :key="item.Guid"
                >
                  <v-card
                    v-if="index < getLimitedSize"
                    :key="item.Guid"
                    class="mb-1 pa-3 card outlined"
                    elevation="0"
                    rounded="lg"
                  >

                    <KanbanTile
                      :viewconfig="getTileViewConfig"
                      :editmode="editmode"
                      :object="item"
                      :fields="getFields"
                      :dense="true"
                    />
                  </v-card>
                </div>
                <v-btn
                  v-if="limited && getLimitedSize < stage.list.length"
                  block
                  text
                  elevation="0"
                  class="mb-2"
                  @click="showAll"
                >
                  Show all
                </v-btn>

              </draggable>
            </v-row>
          </v-container>

        </div>
      </v-row>
    </v-container>
    <v-container
      v-if="isOverflowing"
      fluid
      class="pb-6"
    >
      <v-row>
        <v-spacer />
        <v-btn
          class="iconButton mx-1"
          icon
          @click.stop="prev"
        >
          <v-icon
            light
            class="configLight"
          >hi-chevron-left-outline</v-icon>
        </v-btn>
        <v-btn
          class="iconButton mx-1"
          icon
          @click.stop="next"
        >
          <v-icon
            light
            class="configLight"
          >hi-chevron-right-outline</v-icon>
        </v-btn>
      </v-row>
    </v-container>
  </v-card>
</template>
<script>
import * as Constants from "../../store/constants.js";
import * as UTIL from "../../api/utilities.js";
import TileView from "./TileView";
import KanbanTile from "../components/customTiles/KanbanTile.vue";
import { CAN_EDIT } from '../../store/authorizationObjectContext.js';
import draggable from 'vuedraggable'
import { mapGetters } from 'vuex';

export default {
  components: { TileView, KanbanTile, draggable },
  props: {
    viewconfig: {
      type: String,
      default: ""
    },
    selectionfunction: {
      type: Function,
      default: null
    },
    selectedproperty: {
      type: Object,
      default: null
    },
    search: {
      type: String,
      default: ""
    },
    height: {
      type: String,
      default: "80vh"
    },
    fieldsize: {
      type: String,
      default: "1"
    },
    limited: {
      type: Boolean,
      default: true
    },
  },
  data() {
    return {
      editmode: false,
      viewConfig: this.getObjectType + "Tile",
      stageField: "value",
      blockField: "status",
      tempFilteredObject: "",
      tempselectedproperty: "",
      blockID: "Guid",
      saveObject: null,
      stages: [],
      blocks: {},
      isOverflowing: false,
      config: {
        mirrorContainer: document.querySelector("#app1")
      }
    };
  },
  computed: {
    ...mapGetters(['getIsMobile']),
    getLimitedSize() {
      return this.limited ? 8 : 1000;
    },

    getHeight() {
      if (typeof this.height === 'string' || this.height instanceof String) {
        let height = this.height.replace(/px/g, '');
        const intHeight = Number(height)
        return intHeight > 100 ? intHeight - 100 : intHeight
      }
      else return this.height > 100 ? this.height - 100 : this.height
      this.height
    },
    isMobile() {
      return this.$store.getters.getDeviceSize === 1
    },
    canEdit() {
      var payload = {
        objectType: this.getViewConfig.objectType,
        action: CAN_EDIT,
        viewConfig: this.viewconfig
      }
      return this.$store.getters.userHasAuthorisation(payload)
    },
    getFieldSize() {
      var toggle = this.$store.getters.getCustomSize(this.viewconfig);
      return toggle && toggle.size && toggle.size >= 5 ? 5 : 4;
    },
    getFields() {
      this.fieldsize;
      var fields = [];
      if (this.getTileViewConfig != undefined) {
        var payload = {
          fieldList: this.getTileViewConfig,
          size: this.getFieldSize,
          list: 'display'
        };
        fields = this.$store.getters.getFieldsForViewFieldListAndSize(payload);
      }
      fields.sort(function (field1, field2) {
        if (field2.position > field1.position) {
          return -1;
        }
        if (field1.position > field2.position) {
          return 1;
        }
        return 0;
      });
      return fields;
    },
    getStages() {
      var valueList = this.selectedproperty.dropdown;
      var stages = this.getValueListItems(valueList);
      if (stages) {
        stages.sort((a, b) => a.position - b.position)
        for (let index = 0, l = stages.length; index < l; index++) {
          const stage = stages[index];
          stage.list = this.getBlocks(stage)
        }
      }
      return stages
    },
    getObjects() {
      var items = this.$store.getters.getDataObjectsForObjectType(
        this.getObjectType
      );
      return items;
    },
    getCurrentObject() {
      return this.$store.getters.getCurrentObject;
    },
    getSelectedProperty() {
      if (this.selectedproperty === null) {
        return "";
      }

      if (this.tempselectedproperty !== this.selectedproperty.field) {
        this.updateValues();
      }
      return this.selectedproperty.field;
    },
    getFilteredObjects() {
      var objects = this.getObjects;
      var payload = {
        objects: objects,
        viewConfig: this.getViewConfig,
        object: this.getCurrentObject
      };
      var filteredObject = this.$store.getters.getFilteredObjectsForViewConfig(
        payload
      );
      var sortField = ""
      if (this.getObjectType === "TaskMain") {
        sortField = "Due"
      }
      if (this.getObjectType === "OrderMain") {
        sortField = "StageDate"
      }
      if (!UTIL.isEmpty(sortField)) {
        filteredObject = filteredObject.sort((item1, item2) => {
          if (item1[sortField] < item2[sortField]) return -1;
          if (item1[sortField] > item2[sortField]) return 1;
          return 0;
        });
      }
      var _searchField = this.search.toLowerCase();
      if (_searchField !== null && _searchField.length > 1) {
        return filteredObject.filter(function (object) {
          return (
            (object.Subject !== undefined &&
              object.Subject !== null &&
              object.Subject.toLowerCase().indexOf(_searchField) !== -1) ||
            (object.AccountName !== undefined &&
              object.AccountName !== null &&
              object.AccountName.toLowerCase().indexOf(_searchField) !== -1)
          );
        });
      } else {
        return filteredObject;
      }
      //Todo add search
      return filteredObject;
    },
    getObjectType() {
      return this.getViewConfig.objectType;
    },
    getViewConfig() {
      return this.$store.getters.getViewConfigForID(this.viewconfig);
    },
    getFilterIsActive() {
      return this.$store.getters.getFilterActivatedForViewConfig(
        this.viewconfig
      );
    },
    getTileViewConfig() {
      return this.getViewConfig.subViewConfig;
    },
    getListConfig() {
      return {
        search: "",
        items: [],
        pagination: {
          sortBy: "title",
          page: 1,
          rowsPerPage: 7,
          descending: false,
          totalItems: 0
        },
        fields: []
      };
    },
    isConfigMode() {
      return this.$store.getters.isConfigMode(this.getViewConfig);
    }
  },
  mounted: function () {
    this.$watch("getFilteredObjects", function (value, mutation) {
      this.updateValues();
    });
    this.$nextTick(() => {
      this.updateValues();
    });
    this.isOverflowing = this.isOverflown(this.$refs.kanbanWrap);
  },
  methods: {
    showAll() {
      this.$emit('changeLimited', false)
    },
    next() {
      this.$refs.kanbanWrap.scrollLeft += 220;
    },
    prev() {
      this.$refs.kanbanWrap.scrollLeft -= 220;
    },
    getStagePagination(stage) {
      return this.limited && this.getLimitedSize < stage.list.length ? this.getLimitedSize : stage.list.length
    },
    added(added) {
    },
    isOverflown(element) {
      return element.scrollWidth > element.clientWidth;
    },
    getBlocks(stage) {
      return this.getFilteredObjects.filter(item => item[this.blockField] === stage.value)
    },
    updateBlock(update, stage) {

      if (update && update.added && update.added.element) {
        var object = this.getFilteredObjects.find(obj => obj.Guid === update.added.element.Guid)
        if (this.getSelectedProperty !== undefined && object) {
          const oldObject = UTIL.copyObject(object)
          this.$set(object, this.getSelectedProperty, stage.value);
          this.updateValues()
          this.save(object, oldObject, this.getObjectType);
          // setTimeout(this.save, 10);
        }
      }
      // else if (update && update.removed && update.removed.element) {
      //   var removed = update.removed.element
      //   var indexOf = this.blocks[stage.value].map(block => block.Guid).indexOf(removed.Guid)
      //   if (indexOf && indexOf > -1) {
      //     delete this.blocks[stage.value][indexOf]
      //   }

      // }
    },
    save(object, oldObject, objectType) {
      var payload = {
        object,
        oldObject,
        objectType
      };
      this.$store.dispatch('saveObjectForObjectType', payload);
    },
    saveOperation() {
      this.$store.dispatch(
        Constants.SAVE_OPERATION_CONTAINER,
        this.$store.getters.getCurrentOperationContainer
      );
      this.saveObject = null;
    },
    updateValues() {
      this.tempselectedproperty = this.selectedproperty.field;
      this.id = "Guid";
      this.stages = this.getStages;
      this.blocks = {}
      for (let index = 0, l = this.stages.length; index < l; index++) {
        const stage = this.stages[index];
        this.blocks[stage.value] = this.getBlocks(stage)
      }
      this.blockField = this.getSelectedProperty;
    },
    getValueListItems(listCode) {
      var list = this.$store.getters.getValueListForId(listCode);
      return list;
    }
  }
};
</script>

<style lang="scss">
@import "./../../css/kanban.scss";


.scroller {
  height: 100%;
}

.stage-container {
  flex: 1;
  min-width: 220px;
}

.stage-overflow {
  overflow-y: auto;
  height: 70vh;
  width: 100%;
}

.drag-container {

  max-width: 100%;
}

.drag-column-header {
  top: 0;
  position: sticky;
  background-color: var(--v-iconButton-base);
  border-radius: 12px;
  z-index: 10;
  width: 100%;
}

.drag-options {
  top: 0 !important;
}

li.drag-column {
  position: relative;
  background-color: var(--v-iconButton-base);
}

.overflow-y {
  overflow-y: auto;
}

.stage-wrap {
  overflow-x: auto;
  overflow-y: hidden;
}

.overflow {

  overflow-y: auto;
  height: 80vh;

}
</style>

